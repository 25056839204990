export class TankProduct {
    constructor(
        public tankProductID?: number,
        public productName?: string,
        public facilityID?: string,       
        public description?: string,
        public customerID?: number,
        public customerName?: string,
        public activeFlag?: boolean
    ) {

    }
}
