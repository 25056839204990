import { Component, OnInit } from '@angular/core';
import { VarianceService } from '../../../core-services/services/variance.service';
import { MatDialogRef } from '@angular/material';
import { LabResult } from '../../objects/lab-result';
import { DataModalService } from '../../../core-services/services/data-modal.service';
import { Variance } from '../../../../objects/variance';

@Component({
  selector: 'app-variance',
  templateUrl: './variance.component.html',
  styleUrls: ['./variance.component.scss']
})
export class VarianceComponent implements OnInit {
  public variances: Array<Variance>;
  public labResult: LabResult;
  constructor(public dialogRef: MatDialogRef<VarianceComponent>, private varianceService: VarianceService,
              private dataModalService: DataModalService) { }

  ngOnInit() {
    this.labResult = this.dataModalService.getDataModal();
    const params = {
      sampleId: this.labResult.sampleId
    }
    this.varianceService.getVariances(params).subscribe(
      result => {
        this.variances = result;
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
