import { Component, OnInit, Input } from '@angular/core';
import { umsMeter } from '../../../objects/ums-meter';

@Component({
  selector: 'app-selected-meters',
  templateUrl: './selected-meters.component.html',
  styleUrls: ['./selected-meters.component.css']
})
export class SelectedMetersComponent {
  @Input() selectedMeters: umsMeter[] = [];

  private collapsed: boolean;

  public isCollapsed(): boolean {
    return this.collapsed;
  }

  public setCollapsed(): void {
    this.collapsed = true;
  }

  public toggleContainer(): void {
    this.collapsed = !this.collapsed;
  }
}
