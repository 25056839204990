import { Component, OnInit } from '@angular/core';
import { Customer } from '../../objects/customer';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Facility } from '../../objects/facility';
import { HttpClient } from '@angular/common/http';
import { CustomerDialogService } from './customer-dialog.service';
import { UserService } from '../../services/user.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'customer-list',
  templateUrl: './customer.component.html'
})

export class CustomerComponent implements OnInit {
  public customers: Customer[];
  public customer: Customer;
  public gridCustomers: GridDataResult;

  public isInComplianceGroup: boolean;

  public state: State = {
    skip: 0,
    take: 25
  }

  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  private facilities: Facility[];


  constructor(private http: HttpClient, private dialogService: CustomerDialogService, private userService: UserService) {
    this.isInComplianceGroup = this.userService.isInGroup(['vpk-us-compliance']);
    this.allData = this.allData.bind(this);
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    let customerUrl = '/api/Customer';

    if (this.isInComplianceGroup) {
      customerUrl = '/api/Customer/compliance';
    }

    this.http.get(customerUrl)
      .subscribe((result : Customer[]) => {
        this.customers = result;
        this.refresh()
      });

    this.http.get('/api/Facility')
      .subscribe((result : Facility[]) => {
        this.facilities = result;
      });
  }


  customerUpdated(customer: Customer) {
    if (customer == undefined) {
      return;
    }

    this.customer = customer;
    var found: boolean = false;



    for (var i = 0; i < this.customers.length && !found; i++) {
      var c = this.customers[i];

      if (this.customer.customerID == c.customerID) {
        this.customers[i] = this.customer;
        found = true;
        break;
      }
    }

    if (!found) {
      this.customers.unshift(this.cloneCustomer(this.customer));
    } else if (!this.customer.activeFlag) {
      this.customers.splice(i, 1);
    }

    this.customers = [...this.customers];
    this.refresh();
  }

  onRowSelect(event) {
    var customer: Customer = this.cloneCustomer(event);
    this.dialogService
      .customerDetail(customer, this.facilities)
      .subscribe(res => {
        this.customerUpdated(res);
      });
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.customers, { sort: [{ field: 'customerName', dir: 'asc' }] }).data
    };

    return result;
  }

  refresh() {
    this.gridCustomers = process(this.customers, this.state);
  }

  showDialogToAdd() {
    var customer: Customer = new Customer();
    this.dialogService
      .customerDetail(customer, this.facilities)
      .subscribe(res => {
        this.customerUpdated(res);
      });
  }

  cloneCustomer(c: Customer): Customer {
    let customer = new Customer();

    for (let prop in c) {
      customer[prop] = c[prop];
    }

    return customer;
  }


}
