import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

/** modules **/
import { CoreModule } from '../core/core.module';

/** services */
import { DialogService } from './services/dialog.service';
import { EffluentService } from './services/effluent.service';
import { LogsService }  from './services/logs.service';
import { PhFlowService } from './services/ph-flow.service';
import { TerminalService } from './services/terminal.service';
import { VarianceService } from './services/variance.service';
import { VocReportsService } from './services/voc-reports.service';
import { TankReadingService } from './services/tank-reading.service';
import { NetDmrReportService } from './services/net-dmr-report.service';
import { ReportService } from './services/report.service';

const services = [
  DialogService,
  EffluentService,
  LogsService,
  NetDmrReportService,
  PhFlowService,
  ReportService,
  TankReadingService,
  TerminalService,
  VarianceService,
  VocReportsService,
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    ...services
  ]
})
export class CoreServicesModule {}