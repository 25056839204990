import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Meter } from '../../../interfaces/Meter';
import { umsMeterType } from '../../../objects/ums-meter-type';
import { MeterProduct } from '../../../objects/meter-product';
import { umsMeter } from '../../../objects/ums-meter';
import { Customer } from '../../../objects/customer';
import { SelectItem } from 'primeng/primeng';
import { MeterModel } from '../../../objects/meter-model';
import { KepMeter } from '../../../objects/kep-meter';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, CompositeFilterDescriptor, filterBy, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { MeterDialogService } from '../meter-dialog.service';
import { MeterReading } from '../../../objects/meter-reading';
import { Substation } from '../../../objects/substation';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-master-meter-list',
  templateUrl: './master-meter-list.component.html',
  styleUrls: ['./master-meter-list.component.css']
})
export class MasterMeterListComponent implements OnInit {
  @ViewChild('meterListComponent') elementView: ElementRef;
  public meters: Meter[];
  public filteredMeters: Meter[];
  meterTypes: umsMeterType[];
  products: MeterProduct[];
  meter: Meter = new umsMeter();
  selectedMeter: Meter;
  customers: Customer[];
  typeFilter: SelectItem[];
  productFilter: SelectItem[];
  customerFilter: SelectItem[];
  meterModels: MeterModel[];
  kepMeters: KepMeter[];
  substations: Substation[];
  viewHeight: number;

  public gridMeters: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  };
  private pageSizes: boolean = true;
  private previousNext: boolean = true;
  private type: 'numeric' | 'input' = 'numeric';


  constructor(public http: HttpClient, private dialogService: MeterDialogService) {

  }

  public checked: boolean = true;
  public filter: CompositeFilterDescriptor;

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    //this.switchChange(this.checked);
    this.refresh();
  }

  public switchChange(checked: boolean): void {
    const root = this.filter || { logic: "and", filters: [] };

    const [filter] = flatten(root).filter(x => x.field === "activeFlag");

    if (!filter) {
      if (checked || this.checked) {
        root.filters.push({
          field: "activeFlag",
          operator: "eq",
          value: checked
        });
      }
    } else {
      if (!checked) {
        var index = root.filters.indexOf(filter);
        root.filters.splice(index, 1);
      } else {
        filter.value = checked;
      }
    }
    this.checked = checked;
    this.filterChange(root);
  }

  ngOnInit() {

    this.http.get('/api/Meter?includeInactive=true').subscribe((result: Meter[]) => {
      this.meters = result;


      //this.gridMeters = process(this.meters, this.state);            
      this.switchChange(true);
      this.refresh();
    });

    this.http.get('/api/MeterType').subscribe((result: umsMeterType[]) => {
      this.meterTypes = result;

      this.typeFilter = new Array<SelectItem>();
      this.typeFilter.push({ label: 'All Status', value: null });

      for (let t in this.meterTypes) {
        this.typeFilter.push({
          label: this.meterTypes[t].meterTypeName,
          value: this.meterTypes[t].meterTypeName
        });
      }
    });

    this.http.get('/api/substation').subscribe((result: Substation[]) => {
      this.substations = result;
    });

    this.http.get('/api/Kep?onlyConfiguredMeters=true').subscribe(
      (result: KepMeter[]) => {
        this.kepMeters = result;
        console.log(this.kepMeters);
      });

    this.http.get('/api/MeterModel').subscribe((result: MeterModel[]) => {
      this.meterModels = result;
    });

    this.http.get('/api/Product/meterproducts').subscribe((result: MeterProduct[]) => {
      this.products = result;

      this.productFilter = new Array<SelectItem>();
      this.productFilter.push({ label: 'All Products', value: null });

      for (let t in this.products) {
        this.productFilter.push({
          label: this.products[t].productName,
          value: this.products[t].productName
        });
      }
    });

    this.http.get('/api/Customer').subscribe((result: Customer[]) => {
      this.customers = result;

      this.customerFilter = new Array<SelectItem>();
      this.customerFilter.push({ label: 'All Customers', value: null });

      for (let t in this.customers) {
        this.customerFilter.push({
          label: this.customers[t].customerName,
          value: this.customers[t].customerName
        });
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  refresh() {
    this.filteredMeters = filterBy(this.meters, this.filter);
    this.gridMeters = process(this.filteredMeters, this.state);
  }

  onRowSelect(event) {
    console.log("row selected => " + JSON.stringify(event));
    this.meter = this.cloneMeter(event);

    this.http.get("/api/MeterReading/" + this.meter.meterID)
      .subscribe((mrResult: MeterReading) => {
        var meterReading = mrResult;
        this.http.get("/api/meter/" + this.meter.meterID)
          .subscribe((result: Meter) => {
            this.dialogService
              .meterDetail(
                result,
                this.products,
                this.meterTypes,
                this.customers,
                this.meterModels,
                meterReading,
                this.kepMeters,
                this.substations)
              .subscribe(res => {
                this.meterUpdated(res);
              });
          });
      });
  }

  showDialogToAdd() {
    this.meter = new umsMeter();
    this.meter.activeFlag = true;

    this.dialogService
      .meterDetail(this.meter, this.products, this.meterTypes, this.customers, this.meterModels, new MeterReading(), this.kepMeters, this.substations)
      .subscribe(res => {
        this.meterUpdated(res);
      });
  }

  meterUpdated(meter: Meter) {
    this.meter = meter;
    var found: boolean = false;

    for (var i = 0; i < this.meters.length && !found; i++) {
      var m = this.meters[i];

      if (this.meter.meterID == m.meterID) {
        this.meters[i] = this.meter;
        found = true;
      }
    }

    if (!found) {
      this.meters.push(this.cloneMeter(this.meter));
    }

    this.meters = [...this.meters];
    this.refresh();
  }

  cloneMeter(m: Meter): Meter {
    let meter = new umsMeter();

    for (let prop in m) {
      meter[prop] = m[prop];
    }

    return meter;
  }

}
