import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MethanolAddition } from '../../objects/methanol-addition';
import { MethanolAdditionDialogComponent } from './methanol-addition-dialog/methanol-addition-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MethanolAdditionDialogService {

  constructor(private dialog: MatDialog) { }

  public methanolAddition(methanolAddition: MethanolAddition) {
    let dialogRef: MatDialogRef<MethanolAdditionDialogComponent>;

    dialogRef = this.dialog.open(MethanolAdditionDialogComponent);
    dialogRef.componentInstance.methanolAddition = methanolAddition;

    return dialogRef.afterClosed();
  }
}
