import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/toPromise';

import { Subject } from 'rxjs/Subject';
import * as toastr from 'toastr';
import { AuthService } from '../../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteTankService {
  private favoriteTanksKey: string = 'favtank-';
  public favoriteTanks: string[];
  public favoriteTanksChange: Subject<string[]> = new Subject<string[]>();

  constructor(private auth: AuthService) {
    this.favoriteTanks = this.favoriteTankStorage;
    this.favoriteTanksChange.subscribe((value) => {
      this.favoriteTanks = value;
    });
  }

  private get currentFavoriteTankKey(): string {
    const user = this.auth.getUserProfile();
    return `${this.favoriteTanksKey}${user.sub}`;
  }

  public get favoriteTankStorage(): string[] {
    const storedValue = localStorage.getItem(this.currentFavoriteTankKey);
    if (!storedValue || storedValue.length === 0) return [];

    const parsedValue = JSON.parse(storedValue);
    return Array.isArray(parsedValue) ? parsedValue : [];
  }

  public markTankAsFavorite(tankId: string) {
    const currentFavorites = this.favoriteTankStorage;
    if (currentFavorites.length >= 6) {
      return this.displayTankLimitWarningMessage();
    }
    const tanks = Array.from(new Set([...currentFavorites, tankId]));
    this.saveFavoriteTanks(tanks);
  }

  public unmarkTankAsFavorite(tankId: string) {
    const currentFavorites = this.favoriteTankStorage;
    const index = currentFavorites.indexOf(tankId);
    if (index < 0) return;
    currentFavorites.splice(index, 1);
    this.saveFavoriteTanks(currentFavorites);
  }

  public isFavorite(tankId: string) {
    return this.favoriteTanks.indexOf(tankId) >= 0;
  }

  public saveFavoriteTanks(tanks: string[]) {
    localStorage.setItem(this.currentFavoriteTankKey, JSON.stringify(tanks));
    this.favoriteTanks = tanks;
    this.favoriteTanksChange.next(tanks);
  }

  public displayTankLimitWarningMessage() {
    toastr.options.timeOut = 5000;
    toastr.warning('Only 6 tanks can be displayed on the watchlist');
  }

}
