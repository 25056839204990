import { Tank } from "./tank";
import { umsMeter } from "./ums-meter";
import { MeterGroup } from "./meter-group";

export class WalkingList {
  constructor(
    public walkingListID?: number,
    public createdDateUTC?: Date,
    public walkingListName?: string,
    public facilityID?: string,
    public walkingListGroup?: string,
    public tanks?: Tank[],
    public meters?: umsMeter[],
    public meterGroups?: MeterGroup[],
    public isEditable?: boolean,
    public frequency?: string,
    public timesOfDay?: string[],
    public isSelected?: boolean,
    public activeFlag?: boolean
  ) {
    this.isEditable = true;
    this.meterGroups = new Array<MeterGroup>();
  }

  ngOnInit() {
    if (this.isEditable == null) {
      this.isEditable = true;
    }
  }
}
