import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { Terminal } from '../../../../objects/terminal';

@Component({
  selector: 'terminal-selector',
  templateUrl: './terminal-selector.component.html',
  styleUrls: ['./terminal-selector.component.css']
})
export class TerminalSelectorComponent implements OnInit {

  public defaultItem: Terminal;
  public terminals: Terminal[];
  public selectedTerminal: Terminal;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.selectedTerminal = new Terminal();
    this.defaultItem = new Terminal();
    this.defaultItem.terminalName = "Vopak Terminal - NA";
    this.defaultItem.terminalNumber = "1";
    this.selectedTerminal = this.defaultItem;
  }

  ngOnInit() {
    this.http.get('/api/compliance/terminals')
      .subscribe((result: Array<Terminal>) => {
        this.terminals = result;
        if (this.terminals.length > 0) {
          // this.selectedTerminal = this.terminals[0];
        }
      });
  }
}
