import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Effluent } from '../../core/objects/effluent';
import { LabResult } from '../../core/objects/lab-result';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { ArchivedData } from '../../core/interfaces/ArchivedData';
import { RequestParams } from '../../core/interfaces/Params';
import { IsDownloading } from '../../core/objects/is-downloading';

export interface EffluentMetadata {
  current: Effluent,
  previous: Effluent
}

@Injectable({
  providedIn: 'root'
})
export class EffluentService {
  public baseUrl: string = '/api/effluent/';
  public archiveList: BehaviorSubject<Array<Effluent>> = new BehaviorSubject<Array<Effluent>>(new Array());
  public archiveCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public isDonwloading: BehaviorSubject<IsDownloading> = new BehaviorSubject(new IsDownloading({}));

  constructor(private httpClient: HttpClient) { }

  listEffluent() {
    return this.httpClient.get(this.baseUrl).pipe(map(result => {
      return <EffluentMetadata>result;
    }));
  }

  archievesEffluent(params: RequestParams) {
    return this.httpClient.get(this.baseUrl+'archives',{params: params}).pipe(map(result => {
      return <ArchivedData>result;
    }));
  }

  loadEffluent(params: RequestParams) {
    this.archievesEffluent(params).subscribe(response => {
      this.archiveList.next(this.addStatusToEffluent(response.data));
      this.archiveCount.next(response.totalResults);
    });
  }

  loadMoreEffluent(params: RequestParams) {
    this.archievesEffluent(params).subscribe(response => {
      this.archiveCount.next(response.totalResults);
      const archived = this.archiveList.value.concat(this.addStatusToEffluent(response.data));
      this.archiveList.next(archived);
    });
  }

  addStatusToEffluent(data: Array<Effluent>) {
    data.forEach(element => {
      element.status = 'COMPLETE'
    });
    return data;
  }

  public get archivedList$() {
    return this.archiveList.asObservable();
  }

  public get archiveCount$() {
    return this.archiveCount.asObservable();
  }

  getEffluent(params) {
    return this.httpClient.get(this.baseUrl+'labResult', {params: params}).pipe(map(result => {
      return <LabResult[]> result;
    }));
  }

  async downloadReport(params) {
    let response = {
      name: '',
      blob: undefined,
      status: 200
    }

    const file =  await this.httpClient.get<Blob>
                            (this.baseUrl+'report', 
                              {params: params, responseType: 'blob' as 'json', observe: 'response' })
                            .pipe(map(
                              result => {
                                //console.log(result)
                                //response.name = result.headers.get('Content-Disposition').split("''")[1];
                                response.blob = result.body;
                                return response;
                              }
                            ), catchError(
                              (error, caugth)=>{
                                response.status = error.status;
                                response.blob = error.error;
                                return of(response);
                              }
                            )).toPromise();
    return file;
  }

  get isDownloading$():Observable<IsDownloading> {
    return this.isDonwloading.asObservable();
  }

  set isDownloading(value: IsDownloading) {
    this.isDonwloading.next(value);
  }
}
