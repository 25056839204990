import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: Date, format: string): any {
    
    if (!value) {
      return 'Pending';
    }
    
    if (format) {
      return moment(value).format(format);
    }
    
    return moment(value).format('MM/DD/YY');
  }

}
