import { Component, OnInit } from '@angular/core';
import { MeterGroup } from '../../objects/meter-group';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { umsMeter } from '../../objects/ums-meter';

@Component({
  selector: 'app-meter-group',
  templateUrl: './meter-group.component.html',
  styleUrls: ['./meter-group.component.css']
})
export class MeterGroupComponent implements OnInit {
  public metergroups: MeterGroup[];
  detailvisible: boolean;
  metergroup: MeterGroup = new MeterGroup();
  selectedMetergroup: MeterGroup;

  public gridMeterGroups: GridDataResult;

  public state: State = {
    skip: 0,
    take: 25
  }

  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(public http: HttpClient) {
    this.detailvisible = false;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    this.http.get('/api/MeterGroup').subscribe((result : MeterGroup[])=> {
      this.metergroups = result;
      this.refresh();
    });
  }

  newMeterGroup() {
    this.metergroup = new MeterGroup();
    this.metergroup.createdDateUTC = new Date();
    this.metergroup.meters = new Array<umsMeter>();
    this.detailvisible = true;

  }

  updateGroups(meterGroup: MeterGroup) {

    this.metergroup = meterGroup;
    var found: boolean = false;

    for (var i = 0; i < this.metergroups.length && !found; i++) {
      var mg = this.metergroups[i];

      if (this.metergroup.meterGroupID == mg.meterGroupID) {
        this.metergroups[i] = this.metergroup;
        found = true;
        break;
      }
    }

    if (!found) {
      this.metergroups.push(this.cloneMeterGroup(this.metergroup));
    } else if (!this.metergroup.activeFlag) {
      this.metergroups.splice(i, 1);
    }

    this.metergroups = [...this.metergroups];
    this.refresh();

    this.metergroups = [...this.metergroups];
    this.detailvisible = false;
  }

  toggleDetail() {
    this.detailvisible = false;
  }

  onRowSelect(event) {
    this.http.get('/api/MeterGroup/' + event.meterGroupID)
      .subscribe((result : MeterGroup)=> {
        
        this.metergroup = result;

        this.detailvisible = true;

      });
  }

  refresh() {
    this.gridMeterGroups = process(this.metergroups, this.state);
  }

  cloneMeterGroup(mg: MeterGroup): MeterGroup {
    let metergroup = new MeterGroup();

    for (let prop in mg) {
      metergroup[prop] = mg[prop];
    }

    return metergroup;
  }


}
