import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConsentDecreeTank } from '../../objects/consent-decree-tank';
import { ConsentDecreeTransfer } from '../../objects/consent-decree-transfer';
import { ConsentDecreeTransferDialogComponent } from './consent-decree-transfer-dialog/consent-decree-transfer-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConsentDecreeTransferDialogService {

  constructor(private dialog: MatDialog) { }

  public consentDecreeTransfer(transfer: ConsentDecreeTransfer, tanks: ConsentDecreeTank[]) {
    let dialogRef: MatDialogRef<ConsentDecreeTransferDialogComponent>;

    dialogRef = this.dialog.open(ConsentDecreeTransferDialogComponent);
    dialogRef.componentInstance.transfer = transfer;
    dialogRef.componentInstance.tanks = tanks;

    return dialogRef.afterClosed();
  }
}
