import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import * as moment from 'moment';
import { Month } from '../../../interfaces/month';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VocReportsService {
  private selectedMonthsSubject: BehaviorSubject<Array<Month>> = new BehaviorSubject<Array<Month>>(new Array());
  private monthsSubject: BehaviorSubject<Array<Month>> = new BehaviorSubject<Array<Month>>(new Array());
  public availableMonths: Array<Month> = this.generateAvailableMonths();

  constructor(private httpClient: HttpClient) {
  }

  async download(months){
    const params = {'dates': months};
    let response = {
      name: '',
      blob: undefined,
      status: 200
    }
    const file =  await this.httpClient.get<Blob>
                            ('/api/reporting/vocreport', 
                              {params: params, responseType: 'blob' as 'json', observe: 'response' })
                            .pipe(map(
                              result => {
                                response.name = result.headers.get('Content-Disposition').split("''")[1];
                                response.blob = result.body;
                                return response;
                              }
                            ), catchError(
                              (error, caugth)=>{
                                response.status = error.status;
                                response.blob = error.error;
                                return of(response);
                              }
                            )).toPromise();
    return file;
  }


  resetData() {
    this.resetSelectedMonth();
    this.resetAvailableMonths();
    this.monthsSubject.next(this.generateMonths(''));
  }
  
  resetSelectedMonth() {
    this.selectedMonthsSubject.next(new Array());
  }

  public get selectedMonth(){
    return this.selectedMonthsSubject.getValue();
  }

  updateSelectedMonths(item) {
    if(item.checked){
      this.selectedMonth.push(item);
    } else {
      const indexSelected = this.selectedMonth.indexOf(item);
      if (indexSelected > -1) {
        this.selectedMonth.splice(indexSelected, 1);
      }
    }
    this.selectedMonthsSubject.next(this.selectedMonth);
  }

  getSelectedMonth() {
    return this.selectedMonthsSubject.asObservable();
  }

  updateMonths(filter) {
    this.monthsSubject.next(this.generateMonths(filter));
  }

  public get months$() {
    return this.monthsSubject.asObservable();
  }

  getAvailableMonths() {
    return this.availableMonths;
  }

  generateMonths(query) {
    if (query) {
      let months = this.availableMonths.filter((el) => el.date.toLowerCase().indexOf(query.toLowerCase()) > -1);
      return months.slice(0, 12);
    } else {
      let months = this.availableMonths.slice(0, 12);
      return months;
    }    
  }

  generateAvailableMonths() {
    let now = moment();
    let months: Array<Month> = new Array();
    let i = 1;
    let startDate = moment("2017-08-01");
    if (now.date() >= 20) {
      months.push({
        id: i,
        date: now.format("MMM - YYYY"),
        checked: false,
        value: now.format("YYYY-MM-DD")
      });
    }
    now.date(1);
    now.add(-1, 'month');
    
    while (now.get('year') >= 2017) {
      if (now > startDate) {
        months.push({
          id: i,
          date: now.format("MMM - YYYY"),
          checked: false,
          value: now.format("YYYY-MM-DD")
        });
        now.add(-1, 'month');
      } else {
        break;
      }      
      i++;
    }
    return months;
  }

  resetAvailableMonths() {
    this.availableMonths.forEach((item=>{
      item.checked = false;
    }));
  }
  
}
