import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TankUsageDetail } from '../../../objects/tank-usage-detail';
import { TankReadingDialogComponent } from './tank-reading-dialog/tank-reading-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TankReadingDialogService {

  constructor(private dialog: MatDialog) { }

  public tankReadingDetail(tankReading: TankUsageDetail, isUpdating: boolean) {
    console.log('Tank Reading Detail');
    let dialogRef: MatDialogRef<TankReadingDialogComponent>;

    dialogRef = this.dialog.open(TankReadingDialogComponent);
    dialogRef.componentInstance.tankReading = tankReading;
    dialogRef.componentInstance.isUpdating = isUpdating;

    return dialogRef.afterClosed();
  }
}
