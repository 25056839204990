import { Directive, Input, OnDestroy, OnInit, ElementRef, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Guard } from '../../../interfaces/guard';
import * as RM from 'route-matcher';

@Directive({
  selector: '[secured]'
})

export class SecuredDirective implements OnDestroy, OnInit {
  private el: HTMLElement;
  private visibleDisplay: string;

  @Input()
  destUrl: string;

  @Input()
  menuItem: any;

  constructor(private router: Router,
    el: ElementRef, private injector: Injector,
    private auth: AuthService) {
    this.el = el.nativeElement;
    this.visibleDisplay = this.el.style.display;
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    let allow = true;

    /* Change array because all routes are child that main route */
    let allroutes = this.router.config[0].children;
    
    for (let i = 1; i < this.router.config.length; i++) {
      allroutes = allroutes.concat(this.router.config[i]);
      if (this.router.config[i].children != undefined) {
        allroutes = allroutes.concat(this.router.config[i].children);
      }
    }

    /* Check that if first character is '/' and remove */
    while(this.destUrl.charAt(0) === '/'){
        this.destUrl = this.destUrl.substr(1);
    }
    
    var currentUserProfile = this.auth.getUserProfile();
    var userRoles: string[] = currentUserProfile["https://ums-api.uneelabs.com/roles"];
    var userGroups: string[] = currentUserProfile["https://ums-api.uneelabs.com/groups"];

    
    // iterate over all familiar routes
    for (let i = 0; i < allroutes.length; i++) {
      let path = allroutes[i].path;
      let canActivate = allroutes[i].canActivate;

      let allowedRoles = allroutes[i].data != null ? allroutes[i].data['roles'] : null;
      let allowedGroups = allroutes[i].data != null ? allroutes[i].data['groups'] : null;


      if (this.menuItem != undefined && this.menuItem != null) {
        for (let c = 0; c < this.menuItem.children.length; c++) {
          let childItem = {...this.menuItem.children[c]};
          /* Check that if first character is '/' and remove */
          while(childItem.link.charAt(0) === '/'){
            childItem.link = childItem.link.substr(1);
          }
          if (this.pathMatch(path, childItem.link)) {

            if (canActivate != null) {
              for (let a = 0; a < canActivate.length; a++) {
                try {
                  let canActivateInstance = this.injector.get(canActivate[a]) as Guard;

                  if (!canActivateInstance.allowTransition(allowedRoles, userRoles, allowedGroups, userGroups)) {
                    allow = false;
                    break;
                  }
                } catch (error) {
                  console.error('Error');
                }
              }
            }
          }
        }
      } else if (this.pathMatch(path, this.destUrl)) {

        if (canActivate != null) {
          for (let a = 0; a < canActivate.length; a++) {
            try {
              let canActivateInstance = this.injector.get(canActivate[a]) as Guard;

              if (!canActivateInstance.allowTransition(allowedRoles, userRoles, allowedGroups, userGroups)) {
                allow = false;
                break;
              }
            } catch (error) {
              console.error('Error');
            }
          }
        }
      }
    }
  
    this.el.style.display = allow ? this.visibleDisplay : 'none';
  }


  private pathMatch(template: String, test: String): boolean {


    if (template == "**")
      return false;

    return RM.routeMatcher(template).parse(test) != null;
  }
}
