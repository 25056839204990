import { Component, OnInit } from '@angular/core';
import { Tank } from '../../../objects/tank';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, CompositeFilterDescriptor, filterBy, process } from '@progress/kendo-data-query';
import { umsMeter } from '../../../objects/ums-meter';
import { Customer } from '../../../objects/customer';
import { TankProduct } from '../../../objects/tank-product';
import { KepMeter } from '../../../objects/kep-meter';
import { HttpClient } from '@angular/common/http';
import { TankDialogService } from '../tank-dialog.service';

@Component({
  selector: 'app-master-tank-list',
  templateUrl: './master-tank-list.component.html',
  styleUrls: ['./master-tank-list.component.css']
})

export class MasterTankListComponent implements OnInit {

  public tanks: Tank[];
  public filteredTanks: Tank[];

  public gridTanks: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25,
    sort: [
      { 'field': 'tankNumber', 'dir': 'asc' }
    ]
  };
  private pageSizes: boolean = true;
  private previousNext: boolean = true;
  private type: 'numeric' | 'input' = 'numeric';

  public filter: CompositeFilterDescriptor;

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.refresh();
  }

  public

  products: TankProduct[];
  customers: Customer[];
  meters: umsMeter[];
  kepMeters: KepMeter[];

  tank: Tank = new Tank();

  //customerFilters: SelectItem[];

  constructor(public http: HttpClient, private dialogService: TankDialogService) {

  }

  ngOnInit() {

    this.http.get('/api/Tank')
      .subscribe((result: Tank[]) => {
        console.log(result);
        this.tanks = result;
        this.refresh();
      });

    this.http.get('/api/product/tankproducts')
      .subscribe((result: TankProduct[]) => {
        this.products = result;
      });

    this.http.get('/api/Kep?onlyConfiguredMeters')
      .subscribe((result: KepMeter[]) => {
        this.kepMeters = result;
      });

    this.http.get('/api/customer')
      .subscribe((result: Customer[]) => {
        this.customers = result;
      });

    this.http.get('/api/meter')
      .subscribe((result: umsMeter[]) => {
        this.meters = result;

        this.meters.push({ meterName: 'No Meter', meterID: null });
      });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  refresh() {
    this.filteredTanks = filterBy(this.tanks, this.filter);
    this.gridTanks = process(this.filteredTanks, this.state);
  }

  showDialogToAdd() {
    this.tank = new Tank();
    this.dialogService
      .tankDetail(this.tank, this.products, this.customers, this.meters, this.kepMeters)
      .subscribe(res => {
        this.tankUpdated(res);
      });
  }

  onRowSelect(event) {
    this.http.get('/api/tank/' + event.tankID)
      .subscribe((result: Tank) => {
        this.tank = result;
        this.dialogService
          .tankDetail(this.tank, this.products, this.customers, this.meters, this.kepMeters)
          .subscribe(res => {
            this.tankUpdated(res);
          });
      })
  }


  tankUpdated(tank: Tank) {
    if (!!tank) {
      this.tank = tank;
      var found: boolean = false;

      for (var i = 0; i < this.tanks.length && !found; i++) {
        var t = this.tanks[i];

        if (this.tank.tankID == t.tankID) {
          this.tanks[i] = this.tank;
          found = true;
          break;
        }
      }

      if (!found) {
        this.tanks.push(this.cloneTank(this.tank));
      } else if (!this.tank.activeFlag) {
        this.tanks.splice(i, 1);
      }

      this.tanks = [...this.tanks];
      this.refresh();
    }
  }

  cloneTank(t: Tank): Tank {
    let tank = new Tank();

    for (let prop in t) {
      tank[prop] = t[prop];
    }

    return tank;
  }
}
