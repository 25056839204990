import { Component, OnInit, Input } from '@angular/core';
import { Tank } from '../../../objects/tank';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TankProduct } from '../../../objects/tank-product';
import { umsMeter } from '../../../objects/ums-meter';
import { Customer } from '../../../objects/customer';
import { KepMeter } from '../../../objects/kep-meter';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-tank-dialog',
  templateUrl: './tank-dialog.component.html',
  styleUrls: ['./tank-dialog.component.css']
})
export class TankDialogComponent implements OnInit {
  @Input()
  public tank: Tank;

  public tankForm: FormGroup;
  @Input()
  public products: TankProduct[];

  @Input()
  public meters: umsMeter[];

  @Input() public customers: Customer[];

  @Input()
  public kepMeters: KepMeter[];

  submitting: boolean = false;
  deleting: boolean = false;

  constructor(public http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<TankDialogComponent>) {

  }

  ngOnInit() {
    this.tankForm = this._fb.group({
      tankID: [this.tank.tankID],
      tankNumber: [this.tank.tankNumber],
      productName: [this.tank.productName],
      tankProductID: [this.tank.tankProductID],
      meterName: [this.tank.meterName],
      meterID: [this.tank.meterID],
      customerID: [this.tank.customerID],
      warning: [this.tank.warning],
      minTemperature: [this.tank.minTemperature],
      maxTemperature: [this.tank.maxTemperature],
      activeFlag: [this.tank.activeFlag],
      kepMeterID: [this.tank.kepMeterID],
      minLevelFeet: [Math.floor(+this.tank.minLevel / 12)],
      minLevelInches: [+this.tank.minLevel % 12],
      maxLevelFeet: [Math.floor(+this.tank.maxLevel / 12)],
      maxLevelInches: [+this.tank.maxLevel % 12],
      workInstructionNeeded: [this.tank.workInstructionNeeded]
    });
  }

  saveTank() {
    this.tankForm.value.minLevel = +this.tankForm.value.minLevelFeet * 12 + +this.tankForm.value.minLevelInches;
    this.tankForm.value.maxLevel = +this.tankForm.value.maxLevelFeet * 12 + +this.tankForm.value.maxLevelInches;

    console.log("tankform: " + JSON.stringify(this.tankForm.value));
    this.submitting = true;
    this.tank = this.tankForm.value;
    this.tank.activeFlag = true;

    this.http.post('/api/Tank', this.tank)
      .subscribe((result : string) => {
        this.tank = this.tankForm.value;

        this.tank.minLevel = +this.tankForm.value.minLevelFeet * 12 + +this.tankForm.value.minLevelInches;
        this.tank.maxLevel = +this.tankForm.value.maxLevelFeet * 12 + +this.tankForm.value.maxLevelInches;

        this.tank.tankID = result;
        this.tank.activeFlag = true;

        if (this.tank.tankProductID != null) {
          this.tank.productName = this.products.filter(p => p.tankProductID == this.tank.tankProductID)[0].productName;
        } else {
          this.tank.productName = "";
        }

        if (this.tank.customerID != null) {
          this.tank.customerName = this.customers.filter(c => c.customerID == this.tank.customerID)[0].customerName;
        } else {
          this.tank.customerName = "";
        }

        if (this.tank.meterID != null) {
          this.tank.meterName = this.meters.filter(m => m.meterID == this.tank.meterID)[0].meterName;
        } else {
          this.tank.meterName = "";
        }

        this.dialogRef.close(this.tank);
      })
  }

  deleteTank() {
    this.deleting = true;

    this.http.delete("/api/Tank/" + this.tank.tankID)
      .subscribe(result => {
        this.tank.activeFlag = false;
        this.deleting = false;
        this.dialogRef.close(this.tank);
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();

  }
}
