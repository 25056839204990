export class WalkingListStatus {
  constructor(
    public walkingListName?: string,
    public walkingListID?: number,
    public timeOfDay?: string,
    public weekOfYear?: number,
    public walkingListExecutionID?: string,
    public createdDateUTC?: Date,
    public intervalStop?: Date,
    public meterCount?: number,
    public tankCount?: number,
    public meterReadingCount?: number,
    public tankReadingCount?: number
  ) {

  }
}
