import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { WalkingListStatus } from '../../../../objects/walking-list-status';
import * as moment from 'moment';

@Component({
  selector: 'tank-read',
  templateUrl: './tank-read.component.html',
  styleUrls: ['./tank-read.component.css']
})
export class TankReadComponent implements OnInit {

  public walkingLists: WalkingListStatus[];
  public selectedwalkingList: WalkingListStatus;
  public utcNow: any = moment.utc();

  constructor(private http: HttpClient, private auth: AuthService) {
    this.walkingLists = [];
    this.selectedwalkingList = new WalkingListStatus();
  }

  ngOnInit() {
    this.http.get('/api/dashboard/walkingliststatus?walkingListType=1')
      .subscribe((result: Array<WalkingListStatus>) => {
        this.walkingLists = result;
        for (var i = 0; i < this.walkingLists.length; i++) {
          var start = moment(this.walkingLists[i].createdDateUTC + "Z");
          var stop = moment(this.walkingLists[i].intervalStop + "Z");

          if (this.utcNow.isBetween(start, stop)) {
            this.selectedwalkingList = this.walkingLists[i];
          }
        }        
      });
  }
  selectStatus(status: WalkingListStatus) {
    this.selectedwalkingList = status;
  }

}

