import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Log } from '../../core/objects/log';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { IsDownloading } from '../../core/objects/is-downloading';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private baseUrl: string = '/api/Effluent/';
  public isDonwloading: BehaviorSubject<IsDownloading> = new BehaviorSubject(new IsDownloading({}));
  
  constructor(private httpClient: HttpClient) { }

  listLogs(params) {
    return this.httpClient.get(this.baseUrl+'logs', {params: params}).pipe(map(result => {
      return <[Log]> result;
    }));
  }

  async downloadFile(id) {
    let response = {
      name: '',
      blob: undefined,
      status: 200
    }

    const file =  await this.httpClient.get<Blob>
                            (this.baseUrl+'logdocument/'+id, 
                              {responseType: 'blob' as 'json', observe: 'response' })
                            .pipe(map(
                              result => {
                                response.name = result.headers.get('Content-Disposition').split("''")[1];
                                response.blob = result.body;
                                return response;
                              }
                            ), catchError(
                              (error, caugth)=>{
                                response.status = error.status;
                                response.blob = error.error;
                                return of(response);
                              }
                            )).toPromise();
    return file;
  }

  get isDownloading$():Observable<IsDownloading> {
    return this.isDonwloading.asObservable();
  }

  set isDownloading(value: IsDownloading) {
    this.isDonwloading.next(value);
  }
}
