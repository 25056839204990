export class MethanolAddition {
  public amTotal?: number;
  public pmTotal?: number;
  public dailyTotal?: number;
  public massTotal?: number;

  constructor(
    public methanolAdditionID?: number,
    public recordDate?: Date,
    public amRate?: number,
    public pmRate?: number,
    public facilityID?: string
  ) {
    this.amTotal = this.amRate * 60.0 * 12.0 * 0.00264172;
    this.pmTotal = this.pmRate * 60.0 * 12.0 * 0.00264172;
    this.dailyTotal = this.amTotal + this.pmTotal;
    this.massTotal = this.dailyTotal * 6.6;
  }
}
