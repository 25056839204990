import { SubstationRate } from "./substation-rate";

export class Substation {
  constructor(
    public substationID?: number,
    public facilityID?: string,
    public substationName?: string,
    public currentRate?: number,
    public currentRateStart?: Date,
    public rates?: SubstationRate[]
  ) { }
}
