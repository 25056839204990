import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentDecreeTransferComponent } from './components/consent-decree-transfer/consent-decree-transfer.component';
import { ConsentDecreeTransferDialogComponent } from './components/consent-decree-transfer/consent-decree-transfer-dialog/consent-decree-transfer-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BsDropdownModule } from 'ngx-bootstrap';
import { MatDatepickerModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule, MatDialogModule, MatAutocompleteModule, MatInputModule, MatFormFieldModule, MatSlideToggleModule, MatSliderModule, MatSelectModule, MatCheckboxModule, MatButtonModule, MatTableModule } from '@angular/material';
import { MethanolAdditionComponent } from './components/methanol-addition/methanol-addition.component';
import { MethanolAdditionDialogComponent } from './components/methanol-addition/methanol-addition-dialog/methanol-addition-dialog.component';

const components = [
  ConsentDecreeTransferComponent,
  ConsentDecreeTransferDialogComponent
]

@NgModule({
  declarations: [...components, MethanolAdditionComponent, MethanolAdditionDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    GridModule,
    DropDownsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTableModule,
    BsDropdownModule.forRoot()
  ],
  exports: [...components],
  entryComponents: [
    ConsentDecreeTransferComponent,
    MethanolAdditionComponent
  ]
})
export class EnvironmentalModule { }
