import { Component, OnInit } from '@angular/core';
import { WalkingList } from '../../../objects/walking-list';
import { MeterGroup } from '../../../objects/meter-group';
import { HttpClient } from '@angular/common/http';
import { WalkingListDialogService } from '../walking-list-dialog.service';

@Component({
  selector: 'app-walking-list',
  templateUrl: './walking-list.component.html',
  styleUrls: ['./walking-list.component.css']
})
export class WalkingListComponent implements OnInit {
  public walkingLists: WalkingList[];
  private meterGroups: MeterGroup[];

  constructor(private http: HttpClient, private dialogService: WalkingListDialogService) {

  }

  ngOnInit() {
    this.http.get('/api/walkinglist').subscribe((result : WalkingList[])=> {
      this.walkingLists = result;
    });

    this.http.get('/api/meterGroup').subscribe((result : MeterGroup[])=> {
      this.meterGroups = result;
    });
  }

  onRowSelect(event) {
    var walkinglist = event.data as WalkingList;
    this.http.get('/api/walkinglist/' + walkinglist.walkingListID)
      .subscribe((result : WalkingList)=> {
        this.dialogService
          .walkingListDetail(result, this.meterGroups)
          .subscribe(res => {
            this.walkingListUpdated(res);
          });
      })
  }

  showDialogToAdd() {
    this.dialogService
      .walkingListDetail(new WalkingList(), this.meterGroups)
      .subscribe(res => {
        this.walkingListUpdated(res);
      });
  }

  walkingListUpdated(walkingList: WalkingList) {
    if (walkingList == undefined || walkingList == null) {
      return;
    }

    var existingListIndex = this.walkingLists.findIndex(wl => wl.walkingListID == walkingList.walkingListID);

    if (walkingList.activeFlag) {
      if (existingListIndex > -1) {
        this.walkingLists[existingListIndex] = walkingList;
      } else {
        this.walkingLists.push(walkingList);
      }
    } else {
      if (existingListIndex > -1) {
        this.walkingLists.splice(existingListIndex, 1);
      }
    }


    this.walkingLists = [...this.walkingLists];
  }

}
