import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { WatchlistDialogComponent } from '../components/watchlist-dialog/watchlist-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class WatchlistDialogService {
  constructor(private dialog: MatDialog) {

  }

  public openWatchlist() {

    let dialogRef: MatDialogRef<WatchlistDialogComponent>;

    dialogRef = this.dialog.open(WatchlistDialogComponent, {
      width: '400px',
      height: '627px',
      panelClass: 'watchlist-dialog'
    });

    return dialogRef.afterClosed();
  }
}
