import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let roles = next.data["roles"] as Array<string>;

    if (this.auth.isAuthenticated()) {
      var currentUserProfile = this.auth.getUserProfile();


      var userRoles: string[] = currentUserProfile["https://ums-api.uneelabs.com/roles"];
      if (roles == null || roles.length == 0 || userRoles.filter(r => roles.indexOf(r) !== -1).length > 0) {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }

    } else {

      localStorage.setItem('redirect_url', state.url);
      this.auth.login();
      this.router.navigate(['']);
      return false;
    }
  }
}
