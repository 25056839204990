import { Component } from '@angular/core';

@Component({
  selector: 'app-eom-reconciliation',
  templateUrl: './eom-reconciliation.component.html',
  styleUrls: ['./eom-reconciliation.component.css']
})

export class EomReconciliationComponent {
  
  constructor() {

  }
  

}
