import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Substation } from '../../objects/substation';
import { SubstationDialogComponent } from '../substation-dialog/substation-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubstationDialogService {
 
  constructor(private dialog: MatDialog) { }

  public substationDetail(substation: Substation): Observable<Substation> {
    let dialogRef: MatDialogRef<SubstationDialogComponent>;

    dialogRef = this.dialog.open(SubstationDialogComponent);
    dialogRef.componentInstance.substation = substation;

    return dialogRef.afterClosed();
  }
}
