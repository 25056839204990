export class WorkRequest {
    constructor(
        public workRequestID?: string,
        public meterID?: string,
        public tankID?: string,
        public comment?: string,
        public workRequestTypeID?: number,
        public workRequestTypeName?: string,
        public createdDateUTC?: Date,
        public completedDateUTC?: Date,
        public requestingUserID?: string,
        public meterName?: string,
        public tankNumber?: string
    ) { }
}
