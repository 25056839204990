import { Component, OnInit, Input } from '@angular/core';
import { KepMeter } from '../../../objects/kep-meter';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-kep-meter-dialog',
  templateUrl: './kep-meter-dialog.component.html',
  styleUrls: ['./kep-meter-dialog.component.css']
})
export class KepMeterDialogComponent implements OnInit {
  @Input()
  public kepmeter: KepMeter;

  @Input()
  public metrics: any[];

  public kepmeterForm: FormGroup;
  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<KepMeterDialogComponent>) { }

  ngOnInit() {
    this.kepmeterForm = this._fb.group({
      kepMeterID: [this.kepmeter.kepMeterID],
      kepID: [this.kepmeter.kepID],
      facilityID: [this.kepmeter.facilityID],
      metricID: [this.kepmeter.metricID]
    })
  }

  saveKepMeter() {
    this.submitting = true;

    this.http.post('/api/kep', this.kepmeterForm.value)
      .subscribe(result => {
        this.kepmeter = this.kepmeterForm.value;
        this.dialogRef.close(this.kepmeter);
        this.submitting = false;
      },
        err => console.log("Error => " + err));
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
