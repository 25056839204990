import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';

@Component({
  selector: 'app-tank-reading-dialog',
  templateUrl: './tank-reading-dialog.component.html',
  styleUrls: ['./tank-reading-dialog.component.css']
})
export class TankReadingDialogComponent implements OnInit {

  @Input()
  public tankReading: TankUsageDetail;
  @Input()
  public isUpdating: boolean;


  submitting: boolean = false;
  private readingDate: Date;

  public tankReadingForm: FormGroup;

  constructor(public http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<TankReadingDialogComponent>) {

  }

  ngOnInit() {
    this.tankReadingForm = this._fb.group({
      level: [this.tankReading.level],
      tankReadingID: [this.tankReading.tankReadingID],
      temperature: [this.tankReading.temperature],
      feet: [Math.floor(+this.tankReading.level / 12)],
      inches: [+this.tankReading.level % 12],
      circulationToggle: [this.tankReading.circulationToggle],
      steamToggle: [this.tankReading.steamToggle],
      nitrogenToggle: [this.tankReading.nitrogenToggle],
      tankID: [this.tankReading.tankID],
      createdDateUTC: [this.tankReading.createdDateUTC]
    });

    this.readingDate = new Date(this.tankReading.createdDateUTC + 'Z');
  }

  saveTankReading() {
    this.submitting = true;
    this.tankReadingForm.value.level = +this.tankReadingForm.value.feet * 12 + +this.tankReadingForm.value.inches;
    this.tankReadingForm.value.createdDateUTC = this.readingDate;

    this.http.put('/api/tankreading', this.tankReadingForm.value)
      .subscribe((result: string) => {
        this.tankReading.level = +this.tankReadingForm.value.feet * 12 + +this.tankReadingForm.value.inches;
        this.tankReading.temperature = this.tankReadingForm.value.temperature;
        this.tankReading.circulationToggle = this.tankReadingForm.value.circulationToggle;
        this.tankReading.steamToggle = this.tankReadingForm.value.steamToggle;
        this.tankReading.nitrogenToggle = this.tankReadingForm.value.nitrogenToggle;
        this.tankReading.createdDateUTC = this.tankReadingForm.value.createdDateUTC;
        this.tankReading.tankReadingID = result;

        this.dialogRef.close(this.tankReading);
        this.submitting = false;
      })
  }

  deleteTankReading() {
    this.http.delete('/api/tankreading/' + this.tankReading.tankReadingID)
      .subscribe(result => {
        this.dialogRef.close();
      });
  }

  closeModal() {
    this.dialogRef.close();
  }


}

