import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feetInches'
})
export class FeetInchesPipe implements PipeTransform {

  transform(value): any {
    const feet = value / 12;
    const tunkedfeet = Math.floor(feet);
    const inches = Math.floor((feet - tunkedfeet) * 12);
    return `${tunkedfeet}' ${inches}"`;
  }

}
