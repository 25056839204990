import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { ReportService } from '../../../modules/core-services/services/report.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReportViewerComponent implements OnInit, OnDestroy {
  @Input() reportUrl: string;
  @Input() reportTitle: string;
  public htmlreport: string;
  public report: any;
  public pageStyles: any;
  public processing: boolean = false;
  public pagenumbers = [];
  public canSubmit: boolean;
  public canSubmitSubscription: Subscription;
  public parameters: Array<any>;
  public parametersSubsctiprion: Subscription;

  constructor(private reportService: ReportService) { }

  ngOnInit() {
    this.canSubmitSubscription = this.reportService.canSubmit$.subscribe((result) => {
      this.canSubmit = result;
    });

    this.parametersSubsctiprion =  this.reportService.parameters$.subscribe((result) => {
      this.parameters = result;
    });

    debugger;
  }

  ngOnDestroy() {
    this.canSubmitSubscription.unsubscribe();
    this.parametersSubsctiprion.unsubscribe();
  }

  async exportFile(type: string) {
    this.processing = true;
    const params = {
      ReportName: this.reportUrl,
      Parameters: this.parameters,
      Format: type
    }
    const file = await this.reportService.downloadReport(params, this.reportTitle + "." + type);
    this.processing = false;
  }

  executeAction(actionID) {
    console.log('executeAction')
    const params = {
      clientID: this.report.clientID,
      instanceID: this.report.instanceID,
      documentID: this.report.documentID,
      actionID: actionID
    }
    this.reportService.executeReportAction(params)
      .subscribe((result: any) => {
        this.report = result;
        this.reportService.updateStyleReport(result.reportPage.pageStyles);
        this.htmlreport = result.reportPage.pageContent;
        this.pagenumbers = new Array(result.reportDocument.pageCount).fill(0).map((v, i) => i + 1);
      });
  }

  executeReport(event: any) {
    this.processing = true;
    const params = { report: this.reportUrl, parameters: this.parameters };
    this.reportService.executeReport(params)
      .subscribe((result: any) => {
        this.report = result;
        this.reportService.updateStyleReport(result.reportPage.pageStyles);
        this.htmlreport = result.reportPage.pageContent;
        this.pagenumbers = new Array(result.reportDocument.pageCount).fill(0).map((v, i) => i + 1);
        this.processing = false;
      });
  }

  changePage(page) {
    this.processing = true;
    this.reportService.getReportPage(page, this.report)
      .subscribe((result: any) => {
        this.processing = false;
        this.report = result;
        this.htmlreport = result.reportPage.pageContent;
        this.reportService.updateStyleReport(result.reportPage.pageStyles);
      });
  }
}
