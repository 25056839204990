import { Injectable } from '@angular/core';
import { Auth0User } from '../../objects/auth0/auth0-user';
import { Auth0Group } from '../../objects/auth0/auth0-group';
import { Auth0Role } from '../../objects/auth0/auth0-role';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { MatDialogRef, MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService {

  constructor(private dialog: MatDialog) {

  }

  public userDetail(user: Auth0User, groups: Auth0Group[], roles: Auth0Role[]) {
    let dialogRef: MatDialogRef<UserDetailComponent>;

    dialogRef = this.dialog.open(UserDetailComponent);
    dialogRef.componentInstance.groups = groups;
    dialogRef.componentInstance.roles = roles;
    dialogRef.componentInstance.user = user;


    return dialogRef.afterClosed();
  }
}
