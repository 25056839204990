import { Component, OnInit, Input } from '@angular/core';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';
import { MeterReading } from '../../../../objects/meter-reading';
import { TankService, MeterService } from '../../services';
import { Tank } from '../../../../objects/tank';
import { umsMeter } from '../../../../objects/ums-meter';

@Component({
  selector: 'details-card',
  templateUrl: './details-card.component.html',
  styleUrls: ['./details-card.component.css']
})

export class DetailsCardComponent {
  @Input()
  public tankUsage: TankUsageDetail;
  @Input()
  public meterReading: MeterReading;

  public tank: Tank;
  public meter: umsMeter;

  constructor(private tankService: TankService, private meterService: MeterService) { }

  ngOnInit() {
    this.tank = this.tankService.tanksById[this.tankUsage.tankID];
    this.meter = this.meterService.metersById[this.meterReading.meterID];
  }

}
