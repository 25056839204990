import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Substation } from '../objects/substation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubstationService {
  private urlBase = '/api/substation';

  constructor(private http: HttpClient) { }

  getSubstations() {
    return this.http.get(this.urlBase);
  }

  getSubstationDetail(substationID: number) {
    return this.http.get(this.urlBase + "/" + substationID);
  }

  saveSubstation(substation: Substation): Observable<Substation> {
    return this.http.post(this.urlBase, substation)
      .map((response: Substation) => {
        return response;
      });
      //.subscribe((result: Substation) => {
        
      //})
  }
}
