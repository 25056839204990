import { Component, OnInit, Input } from '@angular/core';
import { Vessel } from '../../../../objects/compliance/vessel';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-vessel-dialog',
  templateUrl: './vessel-dialog.component.html',
  styleUrls: ['./vessel-dialog.component.css']
})
export class VesselDialogComponent implements OnInit {
  @Input()
  public vessel: Vessel;

  public vesselForm: FormGroup;
  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<VesselDialogComponent>) {

  }

  ngOnInit() {
    this.vesselForm = this._fb.group({
      vesselID: [this.vessel.vesselID],
      vesselName: [this.vessel.vesselName],
      ffv_flag: [this.vessel.ffV_FLAG],
      uscG_VON: [this.vessel.uscG_VON],
      imO_VON: [this.vessel.imO_VON],
      activeFlag: [this.vessel.activeFlag]
    })
  }

  saveVessel() {
    this.submitting = true;
    this.vesselForm.value.activeFlag = true;

    this.http.post('/api/compliance/vessels/', this.vesselForm.value)
      .subscribe((result: number) => {
        this.vessel = this.vesselForm.value;
        this.vessel.vesselID = result;
        this.dialogRef.close(this.vessel);
        this.submitting = false;
      },
        err => console.log("Error => " + err));
  }

  deleteVessel() {
    this.deleting = true;

    this.http.delete('/api/compliance/vessels/' + this.vessel.vesselID)
      .subscribe(result => {
        this.vessel.activeFlag = false;
        this.dialogRef.close(this.vessel);
        this.submitting = false;
        this.deleting = false;
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
