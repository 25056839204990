import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Meter } from '../../interfaces/Meter';
import { MeterProduct } from '../../objects/meter-product';
import { umsMeterType } from '../../objects/ums-meter-type';
import { Customer } from '../../objects/customer';
import { MeterModel } from '../../objects/meter-model';
import { MeterReading } from '../../objects/meter-reading';
import { KepMeter } from '../../objects/kep-meter';
import { Observable } from 'rxjs';
import { MeterDialogComponent } from './meter-dialog/meter-dialog.component';
import { Substation } from '../../objects/substation';

@Injectable({
  providedIn: 'root'
})
export class MeterDialogService {

  constructor(private dialog: MatDialog) {

  }

  public meterDetail(
    meter: Meter,
    products: MeterProduct[],
    meterTypes: umsMeterType[],
    customers: Customer[],
    meterModels: MeterModel[],
    meterReading: MeterReading,
    kepMeters: KepMeter[],
    substations: Substation[]
  ): Observable<Meter> {
    let dialogRef: MatDialogRef<MeterDialogComponent>;

    dialogRef = this.dialog.open(MeterDialogComponent);
    dialogRef.componentInstance.meter = meter;
    dialogRef.componentInstance.products = products;
    dialogRef.componentInstance.meterTypes = meterTypes;
    dialogRef.componentInstance.customers = customers;
    dialogRef.componentInstance.meterModels = meterModels;
    dialogRef.componentInstance.meterReading = meterReading;
    dialogRef.componentInstance.kepMeters = kepMeters;
    dialogRef.componentInstance.substations = substations;

    return dialogRef.afterClosed();
  }
}
