import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { IRSProductCodeDialogComponent } from './irsproduct-code-dialog/irsproduct-code-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class IRSProductCodeDialogService {
  constructor(private dialog: MatDialog) {

  }

  public irsProductCodeDetail(IRSProductCode: any) {
    let dialogRef: MatDialogRef<IRSProductCodeDialogComponent>;

    dialogRef = this.dialog.open(IRSProductCodeDialogComponent);
    dialogRef.componentInstance.irsproductcode = IRSProductCode;

    return dialogRef.afterClosed();
  }
}
