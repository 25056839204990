import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Terminal } from '../../../objects/terminal';

@Injectable()
export class TerminalService {

    constructor(private httpClient: HttpClient) {
    }

    list(){
        return this.httpClient.get('/api/compliance/terminals')
            .pipe(map(result => {
                return <Terminal[]>result; 
            }))
    }

}