import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, Input } from '@angular/core';
import { WalkingList } from '../../../objects/walking-list';
import { MeterGroup } from '../../../objects/meter-group';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-walking-list-dialog',
  templateUrl: './walking-list-dialog.component.html',
  styleUrls: ['./walking-list-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalkingListDialogComponent implements OnInit, AfterViewInit {
  @Input()
  public walkingList: WalkingList;

  @Input()
  public meterGroups: MeterGroup[];


  submitting: boolean = false;
  deleting: boolean = false;

  frequency: string;
  timesOfDay: string;
  frequencies: string[] = ["Daily", "Weekly", "Monthly"];
  walkingListForm: FormGroup;

  selectedMeterGroups: MeterGroup[];

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<WalkingListDialogComponent>) {
    this.walkingList = new WalkingList();

  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    if (this.walkingList.isEditable == null) {
      this.walkingList.isEditable = true;
    }

    this.meterGroups.forEach(mg => {
      var idx = this.walkingList.meterGroups.findIndex(m => m == mg.meterGroupID);

      if (idx >= 0) {
        mg.isSelected = true;
      } else {
        mg.isSelected = false;
      }
    });

    if (this.walkingList.timesOfDay != undefined && this.walkingList.timesOfDay != null) {
      this.timesOfDay = this.walkingList.timesOfDay.join(",");
    }

    this.walkingListForm = this._fb.group({
      walkingListName: [this.walkingList.walkingListName, Validators.required],
      frequency: [this.walkingList.frequency, Validators.required],
      timesOfDay: [this.timesOfDay]
    });

    console.log(this.walkingList.meterGroups);
    console.log(this.meterGroups);
  }

  saveWalkingList(data: WalkingList) {
    this.submitting = true;
    console.log(JSON.stringify(data));
    console.log("Frequency: " + this.frequency);
    //console.log("TimesOfDay: " + this.timesOfDay);

    //if (this.timesOfDay != null && this.timesOfDay != undefined) {
    //    data.timesOfDay = this.timesOfDay.split(",");
    //}

    data.meterGroups = this.walkingList.meterGroups;
    data.walkingListID = this.walkingList.walkingListID;
    data.activeFlag = true;

    this.http.post('/api/WalkingList', data)
      .subscribe((result : number)=> {
        this.walkingList = data;
        this.walkingList.activeFlag = true;
        this.walkingList.walkingListID = result;

        this.dialogRef.close(this.walkingList);
      })
  }

  deleteWalkingList() {
    this.deleting = true;

    this.http.delete('/api/WalkingList/' + this.walkingList.walkingListID)
      .subscribe(result => {
        this.walkingList.activeFlag = false;
        this.deleting = false;

        this.dialogRef.close(this.walkingList);
      });
  }

  selectFrequency(frequency) {

    this.walkingList.frequency = frequency;

  }

  selectMeterGroup(event, meterGroup) {
    console.log(event);
    console.log(meterGroup);

    if (event.checked) {
      //var meterGroup: MeterGroup = this.meterGroups.find(mg => mg.meterGroupID == meterGroup.MeterGroupID);
      this.walkingList.meterGroups.push(meterGroup.meterGroupID);
    } else {
      var index = this.walkingList.meterGroups.findIndex(wl => wl.meterGroupID == meterGroup.MeterGroupID);
      this.walkingList.meterGroups.splice(index);
    }
  }

  closeModal() {
    console.log(this.selectedMeterGroups);
    this.submitting = false;
    this.dialogRef.close();

  }
}
