import { Component, OnInit } from '@angular/core';
import { Tank } from '../../../../objects/tank';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { TankService } from '../../services/tank.service';
import { MeterService } from '../../services/meter.service';
import { FavoriteTankService } from '../../services/favorite-tank.service';
import { MatDialogRef } from '@angular/material';

interface SelectedTank {
  id: string;
  tankNumber: string;
  selected: boolean;
}
interface SelectedTankMap { [s: string]: SelectedTank; }

@Component({
  selector: 'watchlist-dialog',
  templateUrl: './watchlist-dialog.component.html',
  styleUrls: ['./watchlist-dialog.component.css']
})
export class WatchlistDialogComponent implements OnInit {

  public tanks: Tank[] = [];
  public favorites: string[] = [];
  public selectedTanks: SelectedTankMap = {};
  public selectedIDs: string[] = [];
  public filterTerm: string = "";

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    public tankService: TankService,
    private meterService: MeterService,
    private favoriteTankService: FavoriteTankService,
    public dialogRef: MatDialogRef<WatchlistDialogComponent>
  ) {
    const tanksById = this.tankService.tanksById || {};
    this.tanks = Object.keys(tanksById).map(key => tanksById[key]);
  }

  ngOnInit(): void {
    // create new array to avoid favorite array mutation in favoriteTankService.favoriteTanks
    this.favorites = [... this.favoriteTankService.favoriteTanks];
    const defaultTanks = <SelectedTankMap>this.tanks.reduce((acc, tank): any => {
      const val: SelectedTank = { id: tank.tankID, selected: false, tankNumber: tank.tankNumber };
      acc[tank.tankID] = val;
      return acc;
    }, {});
    const reducedFavorites = this.favorites.reduce((acc, id): any => {
      const tankNumber = defaultTanks[id] ? defaultTanks[id].tankNumber : "test tank";
      const val: SelectedTank = { id, selected: true, tankNumber };
      acc[id] = val;
      return acc;
    }, {});
    this.selectedTanks = Object.assign({}, defaultTanks, reducedFavorites);
    this.selectedIDs = this.favorites;
  }

  public updateSelection(tankId: string) {
    const tank = this.selectedTanks[tankId];
    if (tank.selected) {
      if (this.selectedIDs.length >= 6) {
        this.favoriteTankService.displayTankLimitWarningMessage();
        setTimeout(() => {
          tank.selected = false;
        }, 0);
        return;
      }
      this.addTank(tankId);
    } else {
      this.removeTank(tankId);
    }
  }

  private addTank(tankId: string) {
    this.selectedIDs = Array.from(new Set([...this.selectedIDs, tankId]));
  }

  public removeTank(tankId: string) {
    const index = this.selectedIDs.indexOf(tankId);
    if (index > -1) {
      this.selectedIDs.splice(index, 1);
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  public save() {
    this.favoriteTankService.saveFavoriteTanks(this.selectedIDs);
    this.dialogRef.close();
  }

  public closeTag(tankID: string) {
    this.removeTank(tankID);
    const tankStatus = this.selectedTanks[tankID];
    if (tankStatus) {
      this.selectedTanks[tankID].selected = false;
    }
  }
}

