import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meter } from '../../../interfaces/meter';
import { MeterReading } from '../../../objects/meter-reading';
import { Observable } from 'rxjs';

interface MeterMap { [s: string]: Meter; }
interface MeterReadingMap { [s: string]: MeterReading; }

@Injectable({
  providedIn: 'root'
})
export class MeterService {
  public metersById: MeterMap = {};
  public meterReadingByMeterId: MeterReadingMap = {};

  constructor(private http: HttpClient) {
  }

  public getMeters(): Observable<Meter[]> {
    return this.http.get('api/meter/')
      .map((response: Meter[]) => {
        const data = <Meter[]>response;
        data.map(meter => {
          this.metersById[meter.meterID] = meter;
        });
        return data;
      });
  }

  public getMeterById(id: string): Observable<Meter> {
    return new Observable((observer) => {
      if (this.metersById[id]) {
        observer.next(this.metersById[id]);
      }
      this.http
        .get(`api/meter/${id}`)
        .map((response: Meter) => {
          const meter = <Meter>response;
          if (meter) {
            this.metersById[meter.meterID] = meter;
            observer.next(meter);
          }
        });
    });
  }

  public getMeterReading(meterID: string) {
    return new Observable<MeterReading>((observer) => {
      if (this.meterReadingByMeterId[meterID]) {
        observer.next(this.meterReadingByMeterId[meterID]);
      }
      this.requestMeterReading(meterID).subscribe(meter => {
        if (meter) {
          this.meterReadingByMeterId[meter.meterID] = meter;
          observer.next(meter);
        }
      });
    });
  }
  private requestMeterReading(meterID: string) {
    return this.http.get(`api/MeterReading/${meterID}`)
      .map((response: MeterReading) => {
        const meter = <MeterReading>response;
        return meter;
      });
  }
}
