import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VocReportsService } from '../../../core-services/services/voc-reports.service';
import { Observable, Subscription } from 'rxjs';
import { Month } from '../../../../interfaces/month';
import { MatSnackBar } from '@angular/material';
import { DataModalService } from '../../../core-services/services/data-modal.service';

@Component({
  selector: 'app-voc-reports',
  templateUrl: './voc-reports.component.html',
  styleUrls: ['./voc-reports.component.scss']
})
export class VocReportsComponent  implements OnInit, OnDestroy {
  public endDate: string;
  public filter: string;
  public showSearch: boolean;
  public lastReport: boolean;
  public selectedMonths: Array<Month>;
  public selectedMonths$: Observable<Array<Month>>;
  public selectedMonthsSubscription: Subscription;
  public submittedDate: Month;
  public submittedDates: Array<Month>;
  public isDownloading: boolean;
  @ViewChild('downloadZipLink') private downloadZipLink: ElementRef;

  constructor(public dialogRef: MatDialogRef<VocReportsComponent>, private dataModalService: DataModalService,
    private vocReportsService: VocReportsService, public snackBar: MatSnackBar) {
    this.filter = '';
    this.showSearch = false;
    this.lastReport = false;
    this.isDownloading = false;
    this.getSubmittedDates();
  }

  ngOnInit() {
    this.selectedMonths$ = this.vocReportsService.getSelectedMonth();
    this.selectedMonthsSubscription = this.selectedMonths$.subscribe(data => this.selectedMonths = data);
  }

  ngOnDestroy() {
    this.selectedMonthsSubscription.unsubscribe();
  }

  getSubmittedDates(){
    this.submittedDates = this.vocReportsService.getAvailableMonths();
  }

  close() {
    this.dataModalService.setStatus(false);
    this.dialogRef.close();
  }

  async download() {
    this.isDownloading = true;
    let params: Array<string> = new Array();
    
    if (this.showSearch) {
      this.selectedMonths.map((month)=>{
        params.push(month.value);
      });
    } else {
      params.push(this.submittedDate.value);
    }

    const data = await this.vocReportsService.download(params);
    let binaryData = [];
    binaryData.push(data.blob);
    const contentBlob = new Blob(binaryData, {type: data.blob.type});
    if (data.status === 200) {
      const url = window.URL.createObjectURL(contentBlob);
      const link = this.downloadZipLink.nativeElement;
      link.href = url;
      link.download = data.name;
      link.click();
      window.URL.revokeObjectURL(url);
      this.isDownloading = false;
      this.close();
    } else {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        this.snackBar.open(JSON.parse(e.srcElement['result']).message, '', {
          panelClass: 'wastewater-toast',
          duration: 2000,
        });
      });
      reader.readAsText(contentBlob);
      this.isDownloading = false;
    }   
  }

  changeValue(event: any) {
    this.vocReportsService.updateMonths(this.filter);
  }

  startSearch() {
    if(!this.showSearch){
      this.vocReportsService.updateMonths('');
    }
    this.showSearch = true;
  }

  closeSearch() {
    this.showSearch = false;
    this.filter = '';
  }  

}
