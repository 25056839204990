import { Component, OnInit, OnChanges, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { MeterGroup } from '../../../objects/meter-group';
import { umsMeter } from '../../../objects/ums-meter';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SelectItem, DataTable } from 'primeng/primeng';
import { MeterProduct } from '../../../objects/meter-product';
import { Customer } from '../../../objects/customer';
import { umsMeterType } from '../../../objects/ums-meter-type';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-meter-group-detail',
  templateUrl: './meter-group-detail.component.html',
  styleUrls: ['./meter-group-detail.component.css']
})

export class MeterGroupDetailComponent implements OnInit, OnChanges {
  @Input()
  public metergroup: MeterGroup;
  availableMeters: umsMeter[];
  public selectedMeters: umsMeter[] = [];
  public meterGroupForm: FormGroup;
  public selectedFilter: SelectItem[] = [{ label: "Selected", value: true }, { label: "Not Selected", value: false }];
  public meterFilter: string = "";
  public daysOfWeek: string[];
  public frequency: string;

  submitting: boolean = false;
  deleting: boolean = false;

  productFilter: SelectItem[];
  customerFilter: SelectItem[];
  typeFilter: SelectItem[];

  products: MeterProduct[];
  customers: Customer[];
  meterTypes: umsMeterType[];

  @Input() public isActive: boolean;
  @Output() save: EventEmitter<MeterGroup> = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ViewChild(DataTable) dataTableComponent: DataTable;


  constructor(
    public http: HttpClient,
    private _fb: FormBuilder) {
    this.metergroup = new MeterGroup();
    this.isActive = false;


  }

  ngOnChanges() {
    this.meterGroupForm = this._fb.group({
      meterGroupID: [this.metergroup.meterGroupID],
      meterGroupName: [this.metergroup.meterGroupName],
      meters: [this.selectedMeters]
    });

    if (this.metergroup.meters) {
      let selectedIDs = this.metergroup.meters.map(m => m.meterID);
      this.selectedMeters = this.availableMeters.filter(am => selectedIDs.indexOf(am.meterID) != -1);

      if (this.availableMeters) {
        this.availableMeters.forEach(am => am.selected = false);
      }

      //if (this.metergroup.meters) {

      this.metergroup.meters.forEach(m => {
        m.selected = true;
        var availableMeter = this.availableMeters.find(a => a.meterID == m.meterID);
        //availableMeter.selected = true;
        m.meterType = availableMeter.meterType;
      });

      //this.selectedMeters = this.metergroup.meters;
    }


  }

  rowTrackBy(index: number, row: any) { return row.meterID; }

  ngOnInit() {
    console.log('OnInit');
    this.http.get('/api/Meter')
      .subscribe((result: umsMeter[]) => {
        this.availableMeters = result;
        this.availableMeters.forEach(m => m.display = true);

        this.selectedMeters = this.metergroup.meters;
        this.meterGroupForm = this._fb.group({
          meterGroupID: [this.metergroup.meterGroupID],
          meterGroupName: [this.metergroup.meterGroupName],
          meters: [this.selectedMeters]
        });
      });

    this.http.get('/api/Product/meterproducts').subscribe((result: MeterProduct[]) => {
      this.products = result;

      this.productFilter = new Array<SelectItem>();
      this.productFilter.push({ label: 'All Products', value: null });

      for (let t in this.products) {
        this.productFilter.push({
          label: this.products[t].productName,
          value: this.products[t].productName
        });
      }
    });

    this.http.get('/api/Customer').subscribe((result: Customer[]) => {
      this.customers = result;

      this.customerFilter = new Array<SelectItem>();
      this.customerFilter.push({ label: 'All Customers', value: null });

      for (let t in this.customers) {
        this.customerFilter.push({
          label: this.customers[t].customerName,
          value: this.customers[t].customerName
        });
      }
    });


    this.http.get('/api/MeterType').subscribe((result: umsMeterType[]) => {
      this.meterTypes = result;

      this.typeFilter = new Array<SelectItem>();
      this.typeFilter.push({ label: 'All Types', value: null });

      for (let t in this.meterTypes) {
        this.typeFilter.push({
          label: this.meterTypes[t].meterTypeName,
          value: this.meterTypes[t].meterTypeName
        });
      }
    });
  }

  onTableHeaderCheckboxToggle(event: any) {
    var meters: umsMeter[];

    if (this.dataTableComponent.hasFilter()) {
      meters = <umsMeter[]>this.dataTableComponent.filteredValue;
    } else {
      meters = this.availableMeters;
    }

    if (event.checked === true) {
      for (let m of meters) {
        if (this.selectedMeters.findIndex(sm => sm.meterID == m.meterID) < 0) {
          this.selectedMeters.push(m);
        }
      }
    } else {
      this.selectedMeters.length = 0;
    }
  }

  filterSelected(event: any, dt: any, colField: any, colFilterMatchMode: any) {
    return true;
  }

  selectMeter(event) {
    //debugger;
    //var meter: umsMeter = event.data;

    //var m: umsMeter = this.availableMeters.find(m => m.meterID == meter.meterID);
    //m.selected = true;
    //this.selectedMeters.push(m);
  }

  deselectMeter(event) {
    //var meter: umsMeter = event.data;

    //var m: umsMeter = this.availableMeters.find(m => m.meterID == meter.meterID);
    //m.selected = false;

    //var meterIndex = this.selectedMeters.findIndex(m => m.meterID == meter.meterID);
    //this.selectedMeters.splice(meterIndex, 1);
  }

  saveMeterGroup(data) {

    this.submitting = true;
    data.activeFlag = true;
    data.meters = this.selectedMeters;

    for (var i = 0; i < data.meters.length; i++) {
      if (data.meters[i].kepMeterID == null) {
        data.meters[i].kepMeterID = [];
      }
    }

    data.createdDateUTC = Date.now;
    console.log("Saving this meter -> " + JSON.stringify(data));

    this.http.post('/api/MeterGroup', data)
      .subscribe((result : number) => {
        var retValue: MeterGroup = data;
        retValue.meterGroupID = result;
        retValue.meterCount = retValue.meters.length;

        this.isActive = false;
        this.save.emit(data);
        this.submitting = false;
      });

  }

  deleteMeterGroup() {
    this.metergroup.activeFlag = false;
    this.deleting = true;

    this.http.delete('/api/MeterGroup/' + this.metergroup.meterGroupID)
      .subscribe(result => {
        this.deleting = false;
        this.save.emit(this.metergroup);
      });
  }

  cancelDetail() {
    console.log("Cancel Clicked");
    this.isActive = false;
    this.cancel.emit();
  }

  filterMeters(event) {

    var filter = new RegExp(event.target.value, "gi");

    this.availableMeters.forEach(m => {

      if (m.meterName.search(filter) >= 0) {
        m.display = true;
      } else {
        m.display = false;
      }
    })
  }
}
