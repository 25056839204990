import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImporterOfRecordDialogComponent } from './importer-of-record-dialog/importer-of-record-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ImporterOfRecordDialogService {

  constructor(private dialog: MatDialog) {

  }

  public importerDetail(ImporterOfRecord: any) {
    let dialogRef: MatDialogRef<ImporterOfRecordDialogComponent>;

    dialogRef = this.dialog.open(ImporterOfRecordDialogComponent);
    dialogRef.componentInstance.importerOfRecord = ImporterOfRecord;

    return dialogRef.afterClosed();
  }
}
