import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { JDEProductDialogComponent } from './jdeproduct-dialog/jdeproduct-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class JDEProductDialogService {
  constructor(private dialog: MatDialog) { }

  public jdeProductDetail(JDEProduct: any, IRSProductCodes: any[]) {
    let dialogRef: MatDialogRef<JDEProductDialogComponent>;

    dialogRef = this.dialog.open(JDEProductDialogComponent);
    dialogRef.componentInstance.irsProductCodes = IRSProductCodes;
    dialogRef.componentInstance.jdeproduct = JDEProduct;


    return dialogRef.afterClosed();
  }
}
