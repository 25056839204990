import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-book-stock-audit-report',
  templateUrl: './book-stock-audit-report.component.html',
  styleUrls: ['./book-stock-audit-report.component.css']
})
  
export class BookStockAuditReportComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

}
