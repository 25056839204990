export class MeterProductUsage {
  constructor(
    public total?: number,
    public meterRollover?: number,
    public meterName?: string,
    public correctionFactor?: number,
    public previousReadingDate?: Date,
    public previousReading?: number,
    public reading?: number,
    public meterID?: string,
    public meterReadingID?: string,
    public diff?: number,
    public productName?: string,
    public meterProductID?: number,
    public cost?: number,
    public contractPrice?: number,
    public createdDateUTC?: Date,
    public customerName?: string,
    public customerID?: number,
    public readDateUTC?: Date,
    public pctDifference?: number,
    public usage?: number,
    public comment?: string
  ) {




  }
}
