import { Component, OnInit, Input } from '@angular/core';
import { Auth0User } from '../../../objects/auth0/auth0-user';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Auth0Role } from '../../../objects/auth0/auth0-role';
import { Auth0Group } from '../../../objects/auth0/auth0-group';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  @Input()
  user: Auth0User;


  @Input()
  currentAction: string;

  public userDetailForm: FormGroup;

  @Input()
  roles: Auth0Role[];

  @Input()
  groups: Auth0Group[];

  submitting: boolean = false;
  deleting: boolean = false;

  constructor(private _fb: FormBuilder, private http: HttpClient, public dialogRef: MatDialogRef<UserDetailComponent>) {
    this.roles = new Array<Auth0Role>();
    this.groups = new Array<Auth0Group>();


  }

  ngOnInit() {

    this.userDetailForm = this._fb.group({
      user_id: [this.user.user_id],
      name: [this.user.name],
      email: [this.user.email],
      nickname: [this.user.nickname],
      password: [this.user.password],
      allroles: this._fb.array(this.roles),
      allgroups: this._fb.array(this.groups)
    });


    var formTeams = this.userDetailForm.get('allgroups') as FormArray;
    for (let i = 0; i < this.groups.length; i++) {
      if (this.user.app_metadata && this.user.app_metadata.authorization.groups && this.user.app_metadata.authorization.groups.findIndex(ur => ur == this.groups[i].name) > -1) {
        this.groups[i].selected = true;
      } else {
        this.groups[i].selected = false;
      }

      formTeams.push(new FormControl(this.groups[i]));
    }

    var formRoles = this.userDetailForm.get('allroles') as FormArray;
    for (let i = 0; i < this.roles.length; i++) {

      if (this.user.app_metadata && this.user.app_metadata.authorization.roles && this.user.app_metadata.authorization.roles.findIndex(ur => ur == this.roles[i].name) > -1) {
        this.roles[i].selected = true;
      } else {
        this.roles[i].selected = false;
      }

      formRoles.push(new FormControl(this.roles[i]));
    }

    console.log(JSON.stringify(this.user));
    this.initCheckboxLists();
  }

  ngOnChanges() {
    this.initCheckboxLists();
    console.log("current user: " + JSON.stringify(this.user));
    console.log("form: " + this.userDetailForm);
    if (this.userDetailForm) {
      this.userDetailForm
        .patchValue(this.user);
    }
  }

  initCheckboxLists() {
    console.log("Init Checkbox Lists");
    if (this.roles != null) {
      this.roles.forEach(r => {

        if (this.user.app_metadata && this.user.app_metadata && this.user.app_metadata.authorization && this.user.app_metadata.authorization.roles) {
          r.selected = (this.user.app_metadata.authorization.roles.findIndex(ur => ur == r.name) > -1);
        } else {
          r.selected = false;
        }
      });
    }

    if (this.groups != null) {
      console.log("this.teams=> " + JSON.stringify(this.groups));
      this.groups.forEach(t => {

        if (this.user.app_metadata && this.user.app_metadata && this.user.app_metadata.authorization && this.user.app_metadata.authorization.groups) {
          t.selected = (this.user.app_metadata.authorization.groups.findIndex(ug => ug == t.name) > -1);
        } else {
          t.selected = false;
        }
      });
    }
  }

  saveUserDetail(data) {
    this.submitting = true;
    console.log("Saving this user => " + JSON.stringify(data));

    data.roles = data.allroles;
    data.groups = data.allgroups;

    this.http.post('/api/usermanagement', data)
      .subscribe((result : Auth0User)=> {
        this.dialogRef.close(result);
        this.submitting = false;
      }, error => {
        this.submitting = false;
        alert(error);
      });
  }

  deleteUser() {
    this.deleting = true;

    this.http.delete('/api/usermanagement/' + this.user.user_id)
      .subscribe(result => {
        this.user.user_id = null;
        this.dialogRef.close(this.user);
        this.deleting = false;
      }, error => {
        this.deleting = false;
        alert(error);
      });
  }

  closeModal() {
    this.dialogRef.close();
  }
}

