import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';
import * as d3 from 'd3';
import { TankService } from '../../services/tank.service';
import { Router } from '@angular/router';
import { RadialChart } from '../../utils/radial-chart';

@Component({
  selector: 'temperature-card',
  templateUrl: './temperature-card.component.html',
  styleUrls: ['./temperature-card.component.css']
})

export class TemperatureCardComponent implements OnInit, OnDestroy {
  private timer: d3.Timer;
  public _tankUsage: TankUsageDetail;

  @Input()
  public set tankUsage(usage: TankUsageDetail) {
    this._tankUsage = usage;
    this.updateChart();
  };

  public get tankUsage() {
    return this._tankUsage;
  };

  @ViewChild('widget') widget: ElementRef;
  private chart: RadialChart;

  constructor(    
    public tankService: TankService,
    private router: Router
  ) {
  }

  public readableStatus(): string {
    return this.tankService.humanReadableStatus(this.tankUsage);
  }

  ngOnInit(): void {
    this.chart = new RadialChart(this.widget.nativeElement);
    this.updateChart();
  }

  updateChart() {
    if (this.chart && this.tankUsage) {
      this.chart.minTemperature = this.tankUsage.minTemperature;
      this.chart.maxTemperature = this.tankUsage.maxTemperature;
      this.chart.updateTemperature(this.tankUsage.temperature);

      const tank = this.tankService.tanksById[this.tankUsage.tankID] || { minLevel: 0, maxLevel: 0 };
      this.chart.minLevel = tank.minLevel;
      this.chart.maxLevel = tank.maxLevel;
      this.chart.updateLevel(this.tankUsage.level);

    }
  }

  ngOnDestroy(): void {
    if (this.timer) {
      this.timer.stop();
    }
  }

  public redirectToTankDetails() {
    this.router.navigate(['/tankusage']);
  }
}

