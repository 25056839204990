import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/** external modules */
import { FormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

/** components */
import { IconCloseComponent } from './components/icon-close/icon-close.component';
import { MonthSelectedComponent } from './components/month-selected/month-selected.component';
import { SearchMonthComponent } from './components/search-month/search-month.component';
import { VarianceComponent } from './components/variance/variance.component';
import { VocReportsComponent } from './components/voc-reports/voc-reports.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';

/** pipes */
import { DecimalsPipe } from './pipes/decimals.pipe';
import { FeetInchesPipe } from './pipes/feet-inches.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { GetMonthPipe } from './pipes/get-month.pipe';
import { TimePipe } from './pipes/time.pipe';

/** directives */
import { SecuredDirective } from './directives/secured.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSnackBarModule,
    RouterModule    
  ],
  declarations: [
    DecimalsPipe,
    FeetInchesPipe,
    FormatDatePipe,
    GetMonthPipe,
    IconCloseComponent,
    MonthSelectedComponent,
    SearchMonthComponent,
    SecuredDirective,
    TimePipe,
    
    VarianceComponent,
    VocReportsComponent,
    NavMenuComponent    
  ],
  exports: [
    DecimalsPipe,
    FeetInchesPipe,
    FormatDatePipe,
    GetMonthPipe,
    SecuredDirective,
    TimePipe,
    NavMenuComponent    
  ],
  entryComponents: [
    VarianceComponent,
    VocReportsComponent,
    NavMenuComponent,
  ]
})
export class CoreModule {}
