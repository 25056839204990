import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private auth: AuthService) { }

  isInRole(roles: string[]): boolean {
    if (this.auth.isAuthenticated()) {
      var currentUserProfile = this.auth.getUserProfile();
      var userRoles: string[] = currentUserProfile["https://ums-api.uneelabs.com/roles"];

      let isInRole: boolean = (roles != null && roles.length > 0 && userRoles.filter(r => roles.indexOf(r) !== -1).length > 0);

      return isInRole;
    } else {
      return false;
    }
  }

  isInGroup(groups: string[]): boolean {
    if (this.auth.isAuthenticated()) {
      var currentUserProfile = this.auth.getUserProfile();
      var userGroups: string[] = currentUserProfile["https://ums-api.uneelabs.com/groups"];

      let isInGroup: boolean = (groups != null && groups.length > 0 && userGroups.filter(g => groups.indexOf(g) !== -1).length > 0);

      return isInGroup;
    } else {
      return false;
    }
  }
}
