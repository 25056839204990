import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-irs-transactions-report',
  templateUrl: './irs-transactions-report.component.html',
  styleUrls: ['./irs-transactions-report.component.css']
})
export class IrsTransactionsReportComponent implements OnInit { 

  constructor(private http: HttpClient) {

  }

  ngOnInit() {
  }
}
