export class Carrier {
  constructor(
    public carrierID?: number,
    public carrierNumber?: string,
    public carrierName?: string,
    public carrierFEIN?: string,
    public carrierNameControl?: string,
    public activeFlag?: boolean
  ) { }
}
