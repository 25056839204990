import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ChartModule } from '@progress/kendo-angular-charts';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CarrierListComponent } from './compliance/compliance-lookup/Carrier/carrier-list/carrier-list.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { HttpModule } from '@angular/http';
import { ConfigurationService } from './services/configuration.service';

import { RoleGuardService } from './services/role-guard.service';

import { CustomerComponent } from './components/customer/customer.component';

import { JwtModule } from '@auth0/angular-jwt';
import { CustomerDialogComponent } from './components/customer/customer-dialog/customer-dialog.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { MomentModule } from 'ngx-moment';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatInputModule,
  MatFormFieldModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatCheckboxModule,
  MatButtonModule,
  MatTableModule,

  MatTabsModule
} from '@angular/material';
import { LoginComponent } from './components/login/login.component';
import { CallbackComponent } from './components/callback/callback.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ImporterOfRecordListComponent } from './compliance/compliance-lookup/ImporterOfRecord/importer-of-record-list/importer-of-record-list.component';
import { ImporterOfRecordDialogComponent } from './compliance/compliance-lookup/ImporterOfRecord/importer-of-record-dialog/importer-of-record-dialog.component';
import { PepiUploadComponent } from './compliance/pepi-upload/pepi-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MeterProductUsageComponent } from './components/reports/meter-product-usage/meter-product-usage.component';
import { MeterReadingDialogComponent } from './components/reports/meter-product-usage/meter-reading-dialog/meter-reading-dialog.component';
import { TankUsageComponent } from './components/reports/tank-usage/tank-usage.component';
import { TankReadingDialogComponent } from './components/reports/tank-usage/tank-reading-dialog/tank-reading-dialog.component';
import { IRSProductCodeListComponent } from './compliance/compliance-lookup/IRSProductCode/irsproduct-code-list/irsproduct-code-list.component';
import { IRSProductCodeDialogComponent } from './compliance/compliance-lookup/IRSProductCode/irsproduct-code-dialog/irsproduct-code-dialog.component';
import { JDEProductDialogComponent } from './compliance/compliance-lookup/JDEProduct/jdeproduct-dialog/jdeproduct-dialog.component';
import { JDEProductListComponent } from './compliance/compliance-lookup/JDEProduct/jdeproduct-list/jdeproduct-list.component';
import { TerminalDialogComponent } from './compliance/compliance-lookup/Terminal/terminal-dialog/terminal-dialog.component';
import { TerminalListComponent } from './compliance/compliance-lookup/Terminal/terminal-list/terminal-list.component';
import { VesselListComponent } from './compliance/compliance-lookup/Vessel/vessel-list/vessel-list.component';
import { VesselDialogComponent } from './compliance/compliance-lookup/Vessel/vessel-dialog/vessel-dialog.component';
import { CarrierDialogComponent } from './compliance/compliance-lookup/Carrier/carrier-dialog/carrier-dialog.component';
import { BookStockAuditReportComponent } from './compliance/compliance-reporting/book-stock-audit-report/book-stock-audit-report.component';
import { EomReconciliationComponent } from './compliance/compliance-reporting/eom-reconciliation/eom-reconciliation.component';
import { ErrorReportComponent } from './compliance/compliance-reporting/error-report/error-report.component';
import { IrsSummaryReportComponent } from './compliance/compliance-reporting/irs-summary-report/irs-summary-report.component';
import { IrsTransactionsReportComponent } from './compliance/compliance-reporting/irs-transactions-report/irs-transactions-report.component';
import { ProductBalanceReportComponent } from './compliance/compliance-reporting/product-balance-report/product-balance-report.component';
import { ServiceAuditReportComponent } from './compliance/compliance-reporting/service-audit-report/service-audit-report.component';
import { TankBalanceReportComponent } from './compliance/compliance-reporting/tank-balance-report/tank-balance-report.component';
import { KepMeterComponent } from './components/kep-meter/kep-meter.component';
import { KepMeterDialogComponent } from './components/kep-meter/kep-meter-dialog/kep-meter-dialog.component';
import { MeterGroupComponent } from './components/meter-group/meter-group.component';
import { MeterGroupDetailComponent } from './components/meter-group/meter-group-detail/meter-group-detail.component';
import { SelectedMetersComponent } from './components/meter-group/selected-meters/selected-meters.component';
import { MeterDialogComponent } from './components/meters/meter-dialog/meter-dialog.component';
import { MasterMeterListComponent } from './components/meters/master-meter-list/master-meter-list.component';
import { MeterProductComponent } from './components/meters/meter-product/meter-product.component';
import { MeterProductDialogComponent } from './components/meters/meter-product-dialog/meter-product-dialog.component';
import { MasterTankListComponent } from './components/tanks/master-tank-list/master-tank-list.component';
import { TankDialogComponent } from './components/tanks/tank-dialog/tank-dialog.component';
import { TankProductComponent } from './components/tanks/tank-product/tank-product.component';
import { TankProductDialogComponent } from './components/tanks/tank-product-dialog/tank-product-dialog.component';
import { UserComponent } from './components/users/user/user.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { WalkingListComponent } from './components/walkinglist/walking-list/walking-list.component';
import { WalkingListDialogComponent } from './components/walkinglist/walking-list-dialog/walking-list-dialog.component';
import { WorkRequestComponent } from './components/workrequests/work-request/work-request.component';
import { WorkRequestDialogComponent } from './components/workrequests/work-request-dialog/work-request-dialog.component';
import { DataTableModule, CheckboxModule, SharedModule, DialogModule, ButtonModule, InputTextModule, DropdownModule, SelectItem } from 'primeng/primeng';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FloorPipe } from './pipes/floor.pipe';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import 'hammerjs';
import { TruncateModule } from 'ng2-truncate';
import { BsDropdownModule } from 'ngx-bootstrap';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AsyncPipe } from '@angular/common';
import { MessagingService } from './services/messaging.service';
import { KepUsageReportComponent } from './components/reports/kep-usage-report/kep-usage-report.component';
import { ConsentDecreeTransferDialogComponent } from './modules/environmental/components/consent-decree-transfer/consent-decree-transfer-dialog/consent-decree-transfer-dialog.component';
import { MethanolAdditionDialogComponent } from './modules/environmental/components/methanol-addition/methanol-addition-dialog/methanol-addition-dialog.component';
import { EnvironmentalModule } from './modules/environmental/environmental.module';

import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './components/main/main.component';
import { CoreModule } from './modules/core/core.module';
import { CoreServicesModule } from './modules/core-services/core-services.module';
import { ReportDisplayComponent } from './components/reports/report-viewer/report-display/report-display.component';
import { ReportViewerComponent } from './components/reports/report-viewer/report-viewer.component';
import { ParameterContainerComponent } from './components/reports/report-viewer/parameter-container/parameter-container.component';
import { SafePipe } from './pipes/safe.pipe';
import { SubstationListComponent } from './components/substation-list/substation-list.component';
import { SubstationDialogComponent } from './components/substation-dialog/substation-dialog.component';
import { AirEmissionsReportComponent } from './compliance/compliance-reporting/air-emissions-report/air-emissions-report.component';
import { ErrorDetailReportComponent } from './compliance/compliance-reporting/error-detail-report/error-detail-report.component';


export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,    
    CarrierListComponent,
    TopBarComponent,
    CustomerComponent,
    CustomerDialogComponent,
    LoginComponent,
    UnauthorizedComponent,
    CallbackComponent,
    ImporterOfRecordListComponent,
    ImporterOfRecordDialogComponent,
    PepiUploadComponent,
    MeterProductUsageComponent,
    MeterReadingDialogComponent,
    TankUsageComponent,
    TankReadingDialogComponent,
    IRSProductCodeListComponent,
    IRSProductCodeDialogComponent,
    JDEProductDialogComponent,
    JDEProductListComponent,
    TerminalDialogComponent,
    TerminalListComponent,
    VesselListComponent,
    VesselDialogComponent,
    CarrierDialogComponent,
    BookStockAuditReportComponent,
    EomReconciliationComponent,
    ErrorReportComponent,
    IrsSummaryReportComponent,
    IrsTransactionsReportComponent,
    ProductBalanceReportComponent,
    ServiceAuditReportComponent,
    TankBalanceReportComponent,
    KepMeterComponent,
    KepMeterDialogComponent,
    MeterGroupComponent,
    MeterGroupDetailComponent,
    SelectedMetersComponent,
    MeterDialogComponent,
    MasterMeterListComponent,
    MeterProductComponent,
    MeterProductDialogComponent,
    MasterTankListComponent,
    TankDialogComponent,
    TankProductComponent,
    TankProductDialogComponent,
    UserComponent,
    UserDetailComponent,
    WalkingListComponent,
    WalkingListDialogComponent,
    WorkRequestComponent,
    WorkRequestDialogComponent,    
    FloorPipe,
    SafePipe,
    MainComponent, 
    KepUsageReportComponent,
    ReportDisplayComponent,
    ReportViewerComponent,
    ParameterContainerComponent,
    SubstationListComponent,
    SubstationDialogComponent,
    AirEmissionsReportComponent,
    ErrorDetailReportComponent    
  ],
  entryComponents: [
    TankDialogComponent,
    MeterDialogComponent,    
    WorkRequestDialogComponent,
    
    MeterProductDialogComponent,
    
    WalkingListDialogComponent,
    UserDetailComponent,
    CustomerDialogComponent,
    TankProductDialogComponent,
    MeterReadingDialogComponent,
    TerminalDialogComponent,
    SubstationListComponent,
    SubstationDialogComponent,
    TankReadingDialogComponent,
    VesselDialogComponent,
    IRSProductCodeDialogComponent,
    JDEProductDialogComponent,
    ImporterOfRecordDialogComponent,
    KepMeterDialogComponent,
    CarrierDialogComponent,
    ConsentDecreeTransferDialogComponent,
    MethanolAdditionDialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    MomentModule,
    DateInputsModule,
    GridModule,
    InputsModule,
    ExcelModule,
    ChartModule,
    PDFModule,
    BsDropdownModule,
    DataTableModule,
    CheckboxModule,
    SharedModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,       
    TelerikReportingModule,
    TruncateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTableModule,
    MatTabsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:50456'],
        blacklistedRoutes: ['localhost:50456/auth/']
      }
    }),
    AppRoutingModule,
    GridModule,
    BrowserAnimationsModule,
    DashboardModule,
    CoreModule,
    EnvironmentalModule,
    CoreServicesModule
  ],
  providers: [
    TopBarComponent,
    RoleGuardService,
    MessagingService,
    AsyncPipe,
    SafePipe,
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) =>
        () => configService.loadConfigurationData(),
      deps: [ConfigurationService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
