import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { RowClassArgs, GridDataResult } from '@progress/kendo-angular-grid';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { FavoriteTankService } from '../../services/favorite-tank.service';
import { TankService } from '../../services/tank.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'tank-status-table',
  templateUrl: './tank-status-table.component.html',
  styleUrls: ['./tank-status-table.component.css']
})
export class TankStatusTableComponent implements OnInit {

  protected _tankUsageItems: TankUsageDetail[] = new Array<TankUsageDetail>();
  public _displayAlertsOnly: boolean = false;
  public filterTerm: string = "";

  set displayAlertsOnly(display) {
    this._displayAlertsOnly = display;
    this.applyTankFilters();
  }

  get displayAlertsOnly() {
    return this._displayAlertsOnly;
  }

  @Input()
  set tankUsageItems(items: TankUsageDetail[]) {
    this._tankUsageItems = items;
    this.applyTankFilters();
  }
  get tankUsageItems() {
    return this._tankUsageItems;
  }

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  @ViewChild('search')
  public searchButton: ElementRef;
  private searchfilter: BehaviorSubject<string>;

  @ViewChild('searchBox')
  public searchBox: ElementRef;

  constructor(
    public router: Router,
    private http: HttpClient,
    private auth: AuthService,
    private favorite: FavoriteTankService,
    public tankService: TankService
  ) {
    this.rowCallback = this.rowCallback.bind(this);
    this.tankUsageItems = [];

    this.applyTankFilters();
  }

  ngOnInit() {
    this.searchfilter = new BehaviorSubject("");
    this.setFilterSubject();
  }

  public rowCallback(context: RowClassArgs) {
    const data: TankUsageDetail = context.dataItem;

    const isEven = context.index % 2 == 0;
    const temperatureDanger = this.tankService.isTankTemperatureStatusDanger(data);
    const temperatureWarning = this.tankService.isTankTemperatureStatusWarning(data);
    const levelDanger = this.tankService.isTankLevelStatusDanger(data);
    const levelWarning = this.tankService.isTankLevelStatusWarning(data);
    if (temperatureDanger || temperatureWarning || levelDanger || levelWarning) {
      data.hasAlert = true;
      data.temperatureStatus = temperatureDanger ? 'danger' : temperatureWarning ? 'warning' : 'ok';
      data.levelStatus = levelDanger ? 'danger' : levelWarning ? 'warning' : 'ok';
    }
    return {
      even: isEven,
      odd: !isEven,
      danger: temperatureDanger || levelDanger,
      warning: temperatureWarning || levelWarning,
      "temperature-danger": temperatureDanger,
      "temperature-warning": temperatureWarning,
      "level-danger": levelDanger,
      "level-warning": levelWarning,
    };
  }


  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.applyTankFilters();
  }


  public isFavorite(tankId: string) {
    return this.favorite.isFavorite(tankId);
  }

  public toggleFavorite(tankId: string) {
    if (this.isFavorite(tankId)) {
      this.favorite.unmarkTankAsFavorite(tankId);
    } else {
      this.favorite.markTankAsFavorite(tankId);
    }
  }

  public redirectToTankDetail(event) {
    event.preventDefault();
    this.router.navigate(['/tankusage']);
  }

  public displaySearch() {
    this.searchButton.nativeElement.classList.add("display-search");
  }
  // Push a search term into the observable stream.
  public searchTank(term: string): void {
    this.searchfilter.next(term);
  }

  public searchLostFocus() {
    if (this.searchBox.nativeElement.value && this.searchBox.nativeElement.value.length > 0) return;
    this.searchButton.nativeElement.classList.remove("display-search");
  }

  private filterTanksByName(items: TankUsageDetail[], term: string) {
    return items.filter(item => item.tankNumber.toLowerCase().includes(term.toLowerCase()));
  }

  private filterTanksWithAlerts(items: TankUsageDetail[]) {
    if (this.displayAlertsOnly) {
      return items.filter(item => item.hasAlert);
    }
    return items;
  }

  public applyTankFilters(): void {
    let filteredTanks = this.filterTanksWithAlerts(this.tankUsageItems);
    filteredTanks = this.filterTanksByName(filteredTanks, this.filterTerm);
    this.gridView = {
      data: orderBy(filteredTanks, this.sort),
      total: this.tankUsageItems.length
    };
  }

  private setFilterSubject() {
    this.searchfilter
      .debounceTime(300)        // wait 300ms after each keystroke before considering the term
      .distinctUntilChanged()   // ignore if next search term is same as previous
      .subscribe(
        term => {
          this.filterTerm = term;
          this.applyTankFilters();
        },
        error => {
          // TODO: add real error handling
          console.log(error);
        })
  }


}
