import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { KepMeter } from '../../objects/kep-meter';
import { Metric } from '../../metric';
import { Observable } from 'rxjs';
import { KepMeterDialogComponent } from './kep-meter-dialog/kep-meter-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class KepMeterDialogService {
  constructor(private dialog: MatDialog) { }

  public kepMeterDetail(
    kepMeter: KepMeter,
    metrics: Metric[]): Observable<KepMeter> {
    let dialogRef: MatDialogRef<KepMeterDialogComponent>;

    dialogRef = this.dialog.open(KepMeterDialogComponent);
    dialogRef.componentInstance.kepmeter = kepMeter;
    dialogRef.componentInstance.metrics = metrics;

    return dialogRef.afterClosed();
  }
}
