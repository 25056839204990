import { Component, OnInit, Input } from '@angular/core';
import { VocReportsService } from '../../../core-services/services/voc-reports.service';
import { Month } from '../../../../interfaces/month';

@Component({
  selector: 'app-month-selected',
  templateUrl: './month-selected.component.html',
  styleUrls: ['./month-selected.component.scss']
})
export class MonthSelectedComponent implements OnInit {
  @Input() month: Month;
  public isActive: boolean;
  constructor(private vocReportsService: VocReportsService ) {
    this.isActive = false;
  }

  ngOnInit() {
  }

  activeItem(){
    this.isActive = !this.isActive;
  }

  close(){
    this.month.checked = false;
    this.vocReportsService.updateSelectedMonths(this.month);
  }

}
