import { Component, OnInit } from '@angular/core';
import { Vessel } from '../../../../objects/compliance/vessel';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { VesselDialogService } from '../vessel-dialog.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-vessel-list',
  templateUrl: './vessel-list.component.html',
  styleUrls: ['./vessel-list.component.css']
})
export class VesselListComponent implements OnInit {
  public vessels: Vessel[];
  public vessel: Vessel;
  public gridVessels: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }
  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: VesselDialogService) {
    this.allData = this.allData.bind(this);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    this.http.get('/api/compliance/vessels')
      .subscribe((result : Vessel[])=> {
        this.vessels = result;
        this.refresh();
      });
  }

  showDialogToAdd() {
    this.vessel = new Vessel();
    this.dialogService
      .vesselDetail(this.vessel)
      .subscribe(res => {
        if (res != undefined) {
          this.vesselUpdated(res);
        }
      })
  }

  onRowSelect(event) {
    this.vessel = this.cloneVessel(event);

    this.dialogService
      .vesselDetail(this.vessel)
      .subscribe(res => {
        this.vesselUpdated(res);
      });
  }

  vesselUpdated(vessel: Vessel) {
   
    if (vessel != undefined && vessel != null) {
      this.vessel = vessel;
      var found: boolean = false;

      for (var i = 0; i < this.vessels.length && !found; i++) {
        var v = this.vessels[i];

        if (this.vessel.vesselID == v.vesselID) {
          this.vessels[i] = this.vessel;
          found = true;
          break;
        }
      }

      if (!found) {
        this.vessels.unshift(this.cloneVessel(this.vessel));
      } else if (!this.vessel.activeFlag) {
        this.vessels.splice(i, 1);
      }

      this.vessels = [...this.vessels];
      this.refresh();
    }
  }

  refresh() {
    this.gridVessels = process(this.vessels, this.state);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.vessels, { sort: [{ field: 'vesselName', dir: 'asc' }] }).data
    };

    return result;
  }

  cloneVessel(v: Vessel): Vessel {
    let vessel = new Vessel();

    for (let prop in v) {
      vessel[prop] = v[prop];
    }

    return vessel;
  }
}
