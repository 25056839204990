import { Component, OnInit, Input } from '@angular/core';
import { JDEProduct } from '../../../../objects/compliance/jdeproduct';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-jdeproduct-dialog',
  templateUrl: './jdeproduct-dialog.component.html',
  styleUrls: ['./jdeproduct-dialog.component.css']
})
export class JDEProductDialogComponent implements OnInit {
  @Input()
  public jdeproduct: JDEProduct;

  @Input()
  public irsProductCodes: any[];

  public productForm: FormGroup;
  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<JDEProductDialogComponent>) { }

  ngOnInit() {

    this.productForm = this._fb.group({
      jdeProductID: [this.jdeproduct.jdeProductID],
      irsProductCodeID: [this.jdeproduct.irsProductCodeID],
      irsCode: [this.jdeproduct.irsCode],
      hpProductNumber: [this.jdeproduct.hpProductNumber],
      productNumber: [this.jdeproduct.productNumber],
      productDescription: [this.jdeproduct.productDescription],
      eiaWeeklyCode: [this.jdeproduct.eiaWeeklyCode],
      eiaMonthlyCode: [this.jdeproduct.eiaMonthlyCode],
      txTORCode: [this.jdeproduct.txTORCode],
      comment: [this.jdeproduct.comment],
      activeFlag: [this.jdeproduct.activeFlag]
    })
  }

  saveJDEProduct() {
    this.submitting = true;
    this.productForm.value.activeFlag = true;

    this.http.post('/api/compliance/jdeproduct', this.productForm.value)
      .subscribe((result: number) => {
        this.jdeproduct = this.productForm.value;
        this.jdeproduct.jdeProductID = result;

        this.dialogRef.close(this.jdeproduct);
        this.submitting = false;
      },
        err => {
          console.log("Error => " + err);
          alert("An error occurred saving this product. Please contact support with details about the product that was being added.");
          this.dialogRef.close();
          this.submitting = false;
        });

  }

  deleteJDEProduct() {
    this.deleting = true;

    this.http.delete('/api/compliance/jdeproduct/' + this.jdeproduct.jdeProductID)
      .subscribe(result => {
        this.jdeproduct.activeFlag = false;
        this.dialogRef.close(this.jdeproduct);
        this.submitting = false;
        this.deleting = false;
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
