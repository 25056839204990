export class Terminal {
  constructor(
    public facilityID?: string,
    public facilityName?: string,
    public terminalNumber?: string,
    public tcn?: string,
    public fein?: string,
    public t637nbr?: string,
    public parentCompany?: string,
    public address?: string,
    public city?: string,
    public state?: string,
    public zipCode?: string,
    public contact?: string,
    public phone?: string,
    public fax?: string,
    public email?: string,
    public terminalName?: string,
    public terminalLocation?: string,
    public uidNumber?: string,
    public activeFlag?: boolean
  ) { }
}
