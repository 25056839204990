import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Terminal } from '../../../../objects/terminal';
import { TerminalDialogService } from '../terminal-dialog.service';

@Component({
  selector: 'app-terminal-list',
  templateUrl: './terminal-list.component.html',
  styleUrls: ['./terminal-list.component.css']
})
export class TerminalListComponent implements OnInit {
  public terminals: Terminal[];
  public terminal: Terminal;

  constructor(public http: HttpClient, private dialogService: TerminalDialogService) {

  }

  ngOnInit() {
    this.http.get('/api/compliance/terminals')
      .subscribe((result : Terminal[])=> {
        this.terminals = result;
      });
  }

  showDialogToAdd() {
    this.terminal = new Terminal();
    this.dialogService
      .terminalDetail(this.terminal)
      .subscribe(res => {
        if (res != undefined) {
          this.terminalUpdated(res);
        }
      })
  }

  onRowSelect(event) {
    this.terminal = this.cloneTerminal(event);

    this.dialogService
      .terminalDetail(this.terminal)
      .subscribe(res => {
        this.terminalUpdated(res);
      });
  }

  terminalUpdated(terminal: Terminal) {
    this.terminal = terminal;
    var found: boolean = false;

    for (var i = 0; i < this.terminals.length && !found; i++) {
      var t = this.terminals[i];

      if (this.terminal.facilityID == t.facilityID) {
        this.terminals[i] = this.terminal;
        found = true;
        break;
      }
    }

    if (!found) {
      this.terminals.push(this.cloneTerminal(this.terminal));
    } else if (!this.terminal.activeFlag) {
      this.terminals.splice(i, 1);
    }

    this.terminals = [...this.terminals];
    this.refresh();

  }

  refresh() {
    //this.gridTerminals = process(this.terminals, this.state);
  }

  cloneTerminal(t: Terminal): Terminal {
    let terminal = new Terminal();

    for (let prop in t) {
      terminal[prop] = t[prop];
    }

    return terminal;
  }
}
