import { Component, OnInit } from '@angular/core';
import { ReportServerConfig } from '../../../objects/reporting/report-server-config';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-service-audit-report',
  templateUrl: './service-audit-report.component.html',
  styleUrls: ['./service-audit-report.component.css']
})
export class ServiceAuditReportComponent implements OnInit{
  public reportServerConfig: ReportServerConfig;
  public reportSource: any;

  constructor(private http: HttpClient) {

  }

  ngOnInit() {
    this.reportSource = {
      report: 'compliance/Service Audit.trdp'
    };

    this.http.get("/api/clientconfiguration/reportserver")
      .subscribe((result: ReportServerConfig) => {
        this.reportServerConfig = result;
      });
  }

  viewerContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '800px',
    ['font-family']: 'ms sans serif'
  };

}
