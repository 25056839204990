import { umsMeter } from "./ums-meter";
import { TankKepMeter } from "./tank-kep-meter";


export class Tank {
  constructor(
    public tankID?: string,
    public tankQR?: string,    
    public tankNumber?: string,
    public tankProductID?: number,
    public customerName?: string,
    public customerID?: number,
    public productName?: string,
    public warning?: string,
    public correctionFactor?: number,
    public dataImportCode?: string,
    public meterID?: string,
    public meterName?: string,
    public meter?: umsMeter,
    public steamMeter?: umsMeter,
    public activeFlag?: boolean,
    public minTemperature?: number,
    public maxTemperature?: number,
    public kepMeterID?,
    public minLevel?: number,
    public maxLevel?: number,
    public workInstructionNeeded?: boolean,
    public tankKepMeters?: TankKepMeter[]
  ) {

  }
}
