import * as d3 from 'd3';
import { ReadableInchesPipe } from '../pipes/readable-inches.pipe';


export class RadialChart {
  private temperatureProgress = 1;
  private levelProgress = 1;
  private currentTemperature = 1;
  private currentLevel = 1;
  private rotate = - Math.PI;
  private thickness = 10;
  private minPercent = 15;
  private maxPercent = 85;
  public mainArcPath;
  public mainArc: d3.Arc<any, d3.DefaultArcObject>;
  public volumeArcPath;
  public volumeArc: d3.Arc<any, d3.DefaultArcObject>;
  public svg: d3.Selection<d3.BaseType, {}, HTMLElement, any>;
  public percentLabel: d3.Selection<d3.BaseType, {}, HTMLElement, any>;
  public volumeLabel: d3.Selection<d3.BaseType, {}, HTMLElement, any>;

  constructor(
    public selector,
    public minTemperature: number = 0,
    public maxTemperature: number = 0,
    public minLevel: number = 0,
    public maxLevel: number = 0,
  ) {
    const parent = d3.select(selector)
    const size: ClientRect = (parent.node() as Element).getBoundingClientRect()
    this.svg = parent.append('svg')
      .attr('width', size.width)
      .attr('height', size.height);
    const outerRadius = Math.min(size.width, size.height) * 0.45;

    const defs = this.svg.append("defs");
    const goodGradient = this.addGoodGradient(defs);

    const riskGradient = this.addRiskGradient(defs);

    const { mainArcPath, mainArc } = this.addMainArc(outerRadius, size);
    this.mainArcPath = mainArcPath;
    this.mainArc = mainArc;
    const { volumeArc, volumeArcPath } = this.addVolumeArc(outerRadius, size);
    this.volumeArcPath = volumeArcPath;
    this.volumeArc = volumeArc;


    this.percentLabel = this.svg.append("text")
      .attr('class', 'progress-temp-label')
      .attr('transform', `translate(${size.width / 2},${size.height / 2})`)
      .text('0\u00B0');
    let tempTitle = this.svg.append("text")
      .attr('class', 'progress-temp-title')
      .attr('transform', `translate(${size.width / 2},${size.height / 2 + 25})`)
      .text('CURRENT TEMP');

    this.volumeLabel = this.svg.append("text")
      .attr('class', 'progress-volume-label')
      .attr('transform', `translate(${size.width / 2},${size.height / 2})`)
      .text('0');
    let volumeTitle = this.svg.append("text")
      .attr('class', 'progress-volume-title')
      .attr('transform', `translate(${size.width / 2},${size.height / 2 + 25})`)
      .text('LEVEL');
    this.appendLines(size);
  }

  private setProgressFill(path, progress) {
    path.attr('fill', `url(${window.location.pathname}#goodGradient)`);

    if (progress <= 25 || progress >= 75) {
      path.attr('fill', '#F9C756');

    }
    if (progress < 15 || progress > 85) {
      path.attr('fill', `url(${window.location.pathname}#riskGradient)`);
    }
  }

  private addGoodGradient(defs) {
    const goodGradient = defs.append("linearGradient");
    goodGradient.attr("id", "goodGradient")
      .attr("x1", "64.828%")
      .attr("y1", "95.264%")
      .attr("y2", "9.334%");
    goodGradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#B4EC51");
    goodGradient.append("stop")
      .attr("offset", "29.682%")
      .attr("stop-color", "#5FAA2D");
    goodGradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#429321");
    return goodGradient;
  }

  private addRiskGradient(defs) {
    const riskGradient = defs.append("linearGradient");
    riskGradient.attr("id", "riskGradient")
      .attr("x1", "64.828%")
      .attr("y1", "95.264%")
      .attr("y2", "9.334%");
    riskGradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#f66d1d");

    riskGradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#fad961");
    return riskGradient;
  }

  private appendLines(size) {
    const translateLine = -Math.min(size.width, size.height) * 0.45;
    this.svg.append("line")
      .attr("x1", size.width / 2 - translateLine - this.thickness)
      .attr("x2", size.width / 2 - translateLine - this.thickness + 28)
      .attr("y1", `${size.height / 2}`)
      .attr("y2", `${size.height / 2}`)
      .style("stroke-dasharray", "3,1")
      .style("stroke", 'black');
    this.svg.append("line")
      .attr("x1", size.width / 2 + translateLine - 14)
      .attr("x2", size.width / 2 + translateLine - 14 + 28)
      .attr("y1", `${size.height / 2}`)
      .attr("y2", `${size.height / 2}`)
      .style("stroke-dasharray", "3,1")
      .style("stroke", 'black');
    this.svg.append('g')
      .attr('style', `transform-origin: 50% 50%; transform: rotate(${-40}deg) translate(${translateLine}px);`)
      .attr("transform", ` rotate(${-40}) translate(${translateLine})`)
      .append("line")
      .attr("x1", (size.width) / 2 - 14)
      .attr("x2", size.width / 2 + 14)
      .attr("y1", `${size.height / 2}`)
      .attr("y2", `${size.height / 2}`)
      .style("stroke-dasharray", "3,1")
      .style("stroke", 'black');

    this.svg.append('g')
      .attr('style', `transform-origin: 50% 50%; transform: rotate(${-140}deg) translate(${translateLine}px); box-shadow: 5px 10px #888888;`)
      // .style('transform-origin', ' 50% 50%')
      // .style('box-shadow', '5px 10px #888888;')
      .attr("transform", ` rotate(${-140}) translate(${translateLine})`)
      .append("line")
      .attr("x1", (size.width) / 2 - 14)
      .attr("x2", size.width / 2 + 14)
      .attr("y1", `${size.height / 2}`)
      .attr("y2", `${size.height / 2}`)
      .style("stroke-dasharray", "3,1")
      .style("stroke", 'black');
    let minLabel = this.svg.append("text")
      .attr('class', 'min-label')
      .attr('transform', `translate(${size.width / 2 + translateLine},${size.height / 2 - translateLine - 10})`)
      .text('MIN');
    let maxLabel = this.svg.append("text")
      .attr('class', 'max-label')
      .attr('transform', `translate(${size.width / 2 - translateLine - 20},${size.height / 2 - translateLine - 10})`)
      .text('MAX');
  }

  private addMainArc(outerRadius: number, size) {
    const mainArc = d3.arc()
      .startAngle(0 + this.rotate)
      .endAngle(Math.PI * 2 + this.rotate)
      .innerRadius(outerRadius - this.thickness)
      .outerRadius(outerRadius)
      .cornerRadius(this.thickness / 2);
    this.svg.append("path")
      .attr('class', 'progress-bar-bg')
      .attr('transform', `translate(${size.width / 2},${size.height / 2})`)
      .attr('d', mainArc(null));
    const mainArcPath = this.svg.append("path")
      .attr('class', 'progress-bar')
      .attr('transform', `translate(${size.width / 2},${size.height / 2})`);
    return { mainArcPath, mainArc };
  }

  private addVolumeArc(outerRadius: number, size) {
    const radius = outerRadius - this.thickness - 5;
    const volumeArc = d3.arc()
      .startAngle(0 + this.rotate)
      .endAngle(Math.PI * 2 + this.rotate)
      .innerRadius(radius - this.thickness)
      .outerRadius(radius)
      .cornerRadius(this.thickness / 2);
    this.svg.append("path")
      .attr('class', 'progress-bar-bg')
      .attr('transform', `translate(${size.width / 2},${size.height / 2})`)
      .attr('d', volumeArc(null));
    const volumeArcPath = this.svg.append("path")
      .attr('class', 'volume-progress-bar')
      .attr('transform', `translate(${size.width / 2},${size.height / 2})`);
    return { volumeArcPath, volumeArc };
  }

  public update(progressPercent) {
    const startValue = this.temperatureProgress;
    const startAngle = Math.PI * startValue / 50 + this.rotate;
    const angleDiff = Math.PI * progressPercent / 50 - startAngle + this.rotate;
    const startAngleDeg = startAngle / Math.PI * 180;
    const angleDiffDeg = angleDiff / Math.PI * 180;
    const transitionDuration = 1500;
    this.setProgressFill(this.mainArcPath, progressPercent);
    this.mainArcPath.transition().duration(transitionDuration).attrTween('d', () => {
      return (t) => {
        this.mainArc.endAngle(startAngle + angleDiff * t)
        return this.mainArc(null);
      }
    });
    this.volumeArcPath.transition().duration(transitionDuration).attrTween('d', () => {
      return (t) => {
        this.volumeArc.endAngle(startAngle + angleDiff * t)
        return this.volumeArc(null);
      }
    });
    this.percentLabel.transition().duration(transitionDuration).tween('bla', () => {
      return (t) => {
        this.percentLabel.text(`${Math.round(startValue + (progressPercent - startValue) * t)}\u00B0`);
      }
    });
    this.temperatureProgress = progressPercent;
  }

  public updateTemperatureProgress(progressPercent: number, temperature: number) {
    if (progressPercent < 3) progressPercent = 3;
    if (progressPercent > 100) progressPercent = 100;
    const startValue = this.temperatureProgress;
    const startTemperature = this.currentTemperature;
    const startAngle = Math.PI * startValue / 50 + this.rotate;
    const angleDiff = Math.PI * progressPercent / 50 - startAngle + this.rotate;
    const startAngleDeg = startAngle / Math.PI * 180;
    const angleDiffDeg = angleDiff / Math.PI * 180;
    const transitionDuration = 1500;
    this.setProgressFill(this.mainArcPath, progressPercent);
    this.mainArcPath.transition().duration(transitionDuration).attrTween('d', () => {
      return (t) => {
        this.mainArc.endAngle(startAngle + angleDiff * t)
        return this.mainArc(null);
      }
    });
    this.percentLabel.transition().duration(transitionDuration).tween('bla', () => {
      return (t) => {
        this.percentLabel.text(`${Math.round(startTemperature + (temperature - startTemperature) * t)}\u00B0`);
      }
    });
    this.temperatureProgress = progressPercent;
    this.currentTemperature = temperature;
  }

  public updateTemperature(temperature: number) {
    // range of accepted values 
    if (this.minTemperature === 0) this.minTemperature = 15;
    if (this.maxTemperature === 0) this.maxTemperature = 85;
    let tankRange = this.maxTemperature - this.minTemperature;
    let minChartValue = 0, maxChartValue = 0;
    if (tankRange === 0) {
      tankRange = 100;
      maxChartValue = 100;
    }
    // range from min to max is 70% of the chart
    const totalRange = Math.abs(tankRange) / 0.7;
    // differential value on each end of the chart
    const rangediff = Math.abs(this.minTemperature + totalRange - this.maxTemperature) / 2;
    // minChartValue ==>0% of the chart
    minChartValue = this.minTemperature - rangediff;
    // maxChartValue ==>100% of the chart
    maxChartValue = this.maxTemperature + rangediff;
    // difference of current temperature from the minimum displayable temperature
    const temperatureDiff = temperature - minChartValue;
    // range of values to be displayed on the chart fom 0%(minTemperature) to 100%(maxTemperature)
    const totalRangeOfValues = maxChartValue - minChartValue;
    this.updateTemperatureProgress((temperatureDiff / totalRangeOfValues) * 100, temperature);
  }

  public updateLevelProgress(progressPercent: number, level: number) {
    if (progressPercent < 3) progressPercent = 3;
    if (progressPercent > 100) progressPercent = 100;
    const startValue = this.levelProgress;
    const startLevel = this.currentLevel;
    const startAngle = Math.PI * startValue / 50 + this.rotate;
    const angleDiff = Math.PI * progressPercent / 50 - startAngle + this.rotate;
    const startAngleDeg = startAngle / Math.PI * 180;
    const angleDiffDeg = angleDiff / Math.PI * 180;
    const transitionDuration = 1500;
    const readableInchesPipe = new ReadableInchesPipe();

    this.volumeArcPath.transition().duration(transitionDuration).attrTween('d', () => {
      return (t) => {
        this.volumeArc.endAngle(startAngle + angleDiff * t)
        return this.volumeArc(null);
      }
    });
    this.volumeLabel.transition().duration(transitionDuration).tween('bla', () => {
      return (t) => {
        this.volumeLabel.text(`${readableInchesPipe.transform(Math.round(startLevel + (level - startLevel) * t))}`);
      }
    });
    this.levelProgress = progressPercent;
    this.currentLevel = level;
  }

  public updateLevel(level: number) {
    // range of accepted values 
    if (this.minLevel === 0) this.minLevel = 100;
    if (this.maxLevel === 0) this.maxLevel = 1000;
    let tankRange = this.maxLevel - this.minLevel;
    let minChartValue = 0, maxChartValue = 0;
    if (tankRange === 0) {
      tankRange = 100;
      maxChartValue = 100;
    }
    // range from min to max is 70% of the chart
    const totalRange = Math.abs(tankRange) / 0.7;
    // differential value on each end of the chart
    const rangediff = Math.abs(this.minLevel + totalRange - this.maxLevel) / 2;
    // minChartValue ==>0% of the chart
    minChartValue = this.minLevel - rangediff;
    // maxChartValue ==>100% of the chart
    maxChartValue = this.maxLevel + rangediff;
    // difference of current level from the minimum displayable level
    const levelDiff = level - minChartValue;
    // range of values to be displayed on the chart fom 0%(minLevel) to 100%(maxLevel)
    const totalRangeOfValues = maxChartValue - minChartValue;
    this.updateLevelProgress((levelDiff / totalRangeOfValues) * 100, level);
  }
}
