import { Component, OnInit, Input } from '@angular/core';
import { Terminal } from '../../../../objects/terminal';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-terminal-dialog',
  templateUrl: './terminal-dialog.component.html',
  styleUrls: ['./terminal-dialog.component.css']
})
export class TerminalDialogComponent implements OnInit {
  @Input()
  public terminal: Terminal;

  public terminalForm: FormGroup;
  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<TerminalDialogComponent>) {

  }

  ngOnInit() {
    this.terminalForm = this._fb.group({
      facilityID: [this.terminal.facilityID],
      facilityName: [this.terminal.facilityName],
      terminalNumber: [this.terminal.terminalNumber],
      tcn: [this.terminal.tcn],
      fein: [this.terminal.fein],
      t637nbr: [this.terminal.t637nbr],
      parentCompany: [this.terminal.parentCompany],
      address: [this.terminal.address],
      city: [this.terminal.city],
      state: [this.terminal.state],
      zipCode: [this.terminal.zipCode],
      contact: [this.terminal.contact],
      phone: [this.terminal.phone],
      fax: [this.terminal.fax],
      email: [this.terminal.email],
      terminalName: [this.terminal.terminalName],
      terminalLocation: [this.terminal.terminalLocation],
      uidNumber: [this.terminal.uidNumber],
      activeFlag: [this.terminal.activeFlag]
    })
  }

  saveTerminal() {
    this.submitting = true;
    this.terminalForm.value.activeFlag = true;

    this.http.post('/api/compliance/terminals/', this.terminalForm.value)
      .subscribe(result => {
        this.terminal = this.terminalForm.value;

        this.dialogRef.close(this.terminal);
        this.submitting = false;
      },
        err => console.log("Error => " + err));
  }

  deleteTerminal() {
    this.deleting = true;

    this.http.delete('/api/compliance/terminals/' + this.terminal.facilityID)
      .subscribe(result => {
        this.terminal.activeFlag = false;
        this.dialogRef.close(this.terminal);
        this.submitting = false;
        this.deleting = false;
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
