import * as d3 from "d3";
import * as moment from "moment";

export const lineStyle = {
  continuous: "",
  dashdot: "10, 4, 3,4",
  dashed: "5,5",
  dotted: "1,5"
};
export class LineChart {
  //public size: DOMRect;
  public size: ClientRect;
  public svg: d3.Selection<d3.BaseType, {}, HTMLElement, any>;
  public chart: any;
  public xScale: any;
  public yScale: any;
  public line: any;
  public tooltipdiv: any;
  public tooltipCirclediv: any;
  public tooltipInnerCirclediv: any;
  public graphic: any;
  public parentEl: d3.Selection<any, {}, null, undefined>;
  public lines: any = {};
  constructor(
    public selector,
    public initialData: any[] = []
  ) {
    this.chart = this.generateChart();
  }

  private generateChart() {
    this.parentEl = d3.select(this.selector);
    this.size = (this.parentEl.node() as HTMLElement).getBoundingClientRect() as ClientRect;//DOMRect;
    this.svg = this.parentEl
      .append("svg")
      .attr("width", this.size.width)
      .attr("height", this.size.height)
      .attr("class", "line-chart");
    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = +this.svg.attr("width") - margin.left - margin.right;
    const height = +this.svg.attr("height") - margin.top - margin.bottom;
    // define the div for the tooltip
    this.tooltipdiv = this.parentEl.append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    this.tooltipCirclediv = this.parentEl.append("div")
      .attr("class", "tooltip-circle")
      .style("opacity", 0);
    this.tooltipInnerCirclediv = this.tooltipCirclediv.append("div")
      .attr("class", "tooltip-inner-circle")
      .style("opacity", 0);
    this.graphic = this.svg
      .append("g")
    // add vertical lines
    const horizontalScale = d3
      .scaleLinear()
      .rangeRound([0, width])
      .domain([0, 6]);
    for (let i = 1; i <= 6; i++) {
      this.graphic
        .append("line")
        .attr("x1", horizontalScale(i))
        .attr("x2", horizontalScale(i))
        .attr("y1", height)
        .attr("y2", 0)
        .attr("stroke-width", 1.5)
        .attr("stroke", "#ffffff")
        .attr("opacity", "0.25");
    }
  }

  public addLine(data, strokeStyle, tag) {

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = +this.svg.attr("width") - margin.left - margin.right;
    const height = +this.svg.attr("height") - margin.top - margin.bottom;
    const graphicContainer = this.svg.append("g");

    const formatTime = d3.timeFormat("%e %B");
    const xScale = d3.scaleTime().rangeRound([0, width]);
    const yScale = d3.scaleLinear().rangeRound([height, 0]);

    const xExtent = d3.extent(data, function (d: any): Date {
      return moment(d.date).toDate();
    });
    const yExtent = d3.extent(data, function (d: any): number {
      return d.close;
    });
    if (xExtent[0]) {
      const date = moment(xExtent[0]);
      xExtent[0] = date.startOf("day").toDate();
      xExtent[1] = date.endOf("day").toDate();
    }
    if (yExtent[0] && yExtent[0] === yExtent[1]) {
      yExtent[0] = yExtent[0] - 20;
      yExtent[1] = yExtent[0] + 20;
    }
    xScale.domain(xExtent).nice(1);
    // 5. X scale will use the index of our data
    yScale.domain(yExtent).nice(1);

    const line = d3
      .line()
      .x((d: any) => {
        return xScale(d.date);
      })
      .y((d: any) => {
        return yScale(d.close);
      })
      .curve(d3.curveMonotoneX); // apply smoothing to the line;


    const linePath = graphicContainer.append("path")
      .datum(data)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-width", 2)
      .style("stroke-dasharray", strokeStyle) // dashed line
      .attr("d", line);

    const newDots = graphicContainer.selectAll(".dot")
      .data(data)
      .enter()
      .append("circle") // uses the enter().append() method
      .attr("class", "dot") // assign a class for styling
      .attr("cx", (d: any, i) => xScale(d.date))
      .attr("cy", (d: any) => yScale(d.close))
      .attr("r", 3);
    this.addTooltip(newDots, tag);
    this.addHoverToDot(newDots);
    this.lines[tag] = {
      xScale,
      yScale,
      line,
      tag,
      data,
      strokeStyle,
      graphicContainer
    };

    // add mouse events with .active namespace
    // this makes d3 avoid replacing the tootip event
    const projection = graphicContainer.selectAll(".dot, path.line")
      .on("mouseover.active", (d) => {
        graphicContainer.classed("active", true);
        this.svg.classed("active", true);
        // bring line to front
        (<HTMLElement>this.svg.node()).appendChild(<Node>graphicContainer.node());
      })
      .on("mouseout.active", (d) => {
        graphicContainer.classed("active", false);
        this.svg.classed("active", false);
      });
  }

  private generateTooltipContent(title, data) {
    return `<div class="title">${title}</div> <br/><div class="data">${data}</div>`;
  }

  public randomizeData(data) {
    // const parseTime = d3.timeParse("%d-%b-%y");
    return data.map((datapoint: any) => {
      // datapoint.date = parseTime(datapoint.date);
      datapoint.close = Math.random() * 50 + Math.random() * 50 + Math.random() * 50 + Math.random() * 50;
      return datapoint;
    });
  }

  public addHoverToDot(dots) {
    dots.on("mouseover.dot", function (d: any) {
      d3.select(this).attr("r", 6)
        .style("stroke-width", 4);
    }).on("mouseout.dot", function (d) {
      d3.select(this).attr("r", 3)
        .style("stroke-width", 1);
    });
  }

  public addTooltip(selection, tag) {
    selection.on("mouseover.tooltip", (d: any) => {
      this.tooltipdiv.transition()
        .duration(200)
        .style("opacity", 1);
      this.tooltipdiv.html(this.generateTooltipContent(tag, d.close.toFixed(2)))
        .style("left", (d3.event.pageX - 54) + "px")
        .style("top", (d3.event.pageY - 40) + "px");
      // this.tooltipCirclediv.transition()
      //     .duration(200)
      //     .style("opacity", 1);
      // this.tooltipInnerCirclediv.transition()
      //     .duration(200)
      //     .style("opacity", 1);

      // this.tooltipCirclediv.style("left", (d3.event.pageX - 8) + "px")
      //     .style("top", (d3.event.pageY - 54) + "px");
    }).on("mouseout.tooltip", (d) => {
      this.tooltipdiv.transition()
        .duration(500)
        .style("opacity", 0);
      this.tooltipCirclediv.transition()
        .duration(500)
        .style("opacity", 0);
      this.tooltipInnerCirclediv.transition()
        .duration(500)
        .style("opacity", 0);
    });
  }

  public updateData(newdata, tag: string) {
    const lineMetaData = this.lines[tag];
    const xExtent = d3.extent(newdata, function (d: any) {
      return d.date;
    });
    const yExtent = d3.extent(newdata, (d: any) => {
      return d.close;
    });
    lineMetaData.xScale.domain(xExtent).nice();
    lineMetaData.yScale.domain(yExtent).nice();
    // select the section we want to apply our changes to
    const svgTrans = lineMetaData.graphicContainer.transition();

    // make the changes
    svgTrans
      .select(".line") // change the line
      .duration(750)
      .attr("d", lineMetaData.line(newdata));
    const dot = lineMetaData.graphicContainer.selectAll(".dot").data(newdata);

    // create new circles needed
    const newDots = dot.enter()
      .append("circle"); // uses the enter().append() method
    newDots.merge(dot) // merge new dots with the ones that already exist
      .attr("class", "dot")
      .transition()
      .duration(750)
      .attr("cx", (d: any, i) => lineMetaData.xScale(d.date))
      .attr("cy", (d: any) => lineMetaData.yScale(d.close))
      .attr("r", 3)
      .style("opacity", 1); // assign a class for styling
    this.addTooltip(newDots, tag);
    this.addHoverToDot(newDots);
    dot.exit().remove();
    dot.transition().duration(750)
      .attr("cx", (d: any, i) => {
        return lineMetaData.xScale(d.date);
      })
      .attr("cy", (d: any) => {
        return lineMetaData.yScale(d.close);
      })
      .attr("r", 3);
  }

  public toggleLine(tag: string): boolean {
    const hideLineClass = "line-off";
    const lineContainer = this.lines[tag].graphicContainer;
    lineContainer.classed(hideLineClass, !lineContainer.classed(hideLineClass));
    return !lineContainer.classed(hideLineClass);
  }
}
