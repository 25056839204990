import { Auth0Role } from './auth0-role';
import { Auth0Group } from './auth0-group';

export class Auth0User {
  email_verified: boolean;
  email: string;
  updated_at: Date;
  name: string;
  picture: string;
  user_id: string;
  sub: string;
  nickname: string;
  created_at: Date;
  last_ip: string;
  last_login: string;
  password: string;
  logins_count: number;
  app_metadata: Auth0MetaData;
  allroles: Auth0Role[];
  allgroups: Auth0Group[];

  constructor() {
    this.email_verified = false;
    this.email = "";
    this.updated_at = null;
    this.name = "";
    this.picture = "";
    this.user_id = "";
    this.nickname = "";
    this.sub = "";
    this.created_at = null;
    this.last_ip = "";
    this.last_login = "";
    this.logins_count = 0;
    this.password = null;
    this.app_metadata = new Auth0MetaData();

    this.allroles = new Array<Auth0Role>();
    this.allgroups = new Array<Auth0Group>();
  }
}

export class Auth0MetaData {
  authorization: Auth0Authorization;

  constructor() {
    this.authorization = new Auth0Authorization();
  }
}

export class Auth0Authorization {
  groups: string[];
  roles: string[];
}
