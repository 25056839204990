import { Component, OnInit } from '@angular/core';
import { umsMeter } from '../../../objects/ums-meter';
import { SortDescriptor, State, orderBy, GroupDescriptor, process } from '@progress/kendo-data-query';
import { MeterProduct } from '../../../objects/meter-product';
import { Customer } from '../../../objects/customer';
import { Meter } from '../../../interfaces/Meter';
import { MeterProductUsage } from '../../../objects/meter-product-usage';
import { GridDataResult, DataStateChangeEvent} from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MeterReadingDialogService } from './meter-reading-dialog.service';

@Component({
  selector: 'meter-product-usage',
  templateUrl: './meter-product-usage.component.html',
  styleUrls: ['./meter-product-usage.component.css']
})
export class MeterProductUsageComponent implements OnInit {



  protected startDateFilter: Date = new Date();
  protected endDateFilter: Date = new Date();
  protected meterProductID: number;
  protected customerID: number;
  protected meterID: string;
  protected selectedMeter: umsMeter = new umsMeter();
  private root: any = this;
  private aggregates: any[] = [{ field: 'diff', aggregate: 'sum' }, { field: 'cost', aggregate: 'sum' }, { field: 'contractPrice', aggregate: 'sum' }, { field: 'usage', aggregate: 'sum' }];
  private sort: SortDescriptor[] = [{ dir: 'asc', field: 'createdDateUTC' }];
  public hiddenColumns: string[] = ["customerName", "meterName"];

  protected products: MeterProduct[] = new Array<MeterProduct>();
  protected customers: Customer[] = new Array<Customer>();
  public meters: Meter[] = new Array<umsMeter>();

  private editDataItem: MeterProductUsage;
  public showHidden: boolean = false;
  public showComments: boolean = true;
  public processing: boolean = false;
  public meterName: string;

  public state: State = {
    sort: this.sort,
    group: [{ field: "customerName", aggregates: this.aggregates }, { field: "meterName", aggregates: this.aggregates }]
  }


  public gridView: GridDataResult;
  private meterUsageDetails: MeterProductUsage[];// = new Array<MeterProductUsage>();


  filteredMeters: Observable<umsMeter[]>;

  meterCtrl: FormControl = new FormControl();

  constructor(
    private http: HttpClient,
    private dialogService: MeterReadingDialogService) {

    this.startDateFilter.setMonth(this.startDateFilter.getMonth() - 1);
  }


  //private _filter(meters: umsMeter[], val: string) {
  //    const filterValue = val.toLowerCase();

  //    return meters.filter(meter => meter.meterName.toLowerCase().startsWith(filterValue));
  //}

  ngOnInit() {
    this.processing = true;

    this.http.get('/api/product/meterproducts')
      .subscribe((result: MeterProduct[]) => {
        this.products.push({
          meterProductID: null,
          productName: "All Products"
        });

        var products: MeterProduct[] = result;

        products.forEach(mp => {
          this.products.push(mp);
        });

      });

    this.http.get('/api/customer')
      .subscribe((result: Customer[]) => {
        this.customers.push({
          customerID: null,
          customerName: "All Customers"
        });


        //debugger;
        var customers: Customer[] = result;

        customers.forEach(c => {
          this.customers.push(c);
        });

        if (customers[0] != null) {
          this.customerID = customers[0].customerID;
        }

        this.refreshData();
      });



    this.filteredMeters = this.meterCtrl.valueChanges
      //.startWith(null)
      .map(meter => meter ? this.filterMeters(meter) : this.meters.slice());

    this.http.get('/api/meter')
      .subscribe((result: umsMeter[]) => {

        var emptyChoice: umsMeter = new umsMeter();
        emptyChoice.meterID = null;
        emptyChoice.meterName = "All Meters";

        this.meters = new Array<umsMeter>();
        this.meters.push(emptyChoice);

        var localMeters = result;

        localMeters.forEach(m => {
          this.meters.push(m as umsMeter);
        });

        this.filteredMeters = this.meterCtrl.valueChanges          
          .map(meter => meter ? this.filterMeters(meter) : this.meters.slice());        
      });

  }

  public filterMeters(name: string): umsMeter[] {
    if (this.root.meters != undefined) {

      return this.root.meters.filter(meter => meter.meterName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }
  }

  public editMe(meterReadingID) {
    this.editDataItem = this.meterUsageDetails.find(mu => mu.meterReadingID == meterReadingID);

    this.dialogService
      .meterReadingDetail(this.editDataItem, true)
      .subscribe(res => {
        if (res != undefined) {
          this.editDataItem = res;
          this.refreshData();
        }
      });
  }

  public displayFn(meterID: string): string {

    if (this.meters != undefined && meterID != null) {
      try {
        return this.meters.find(fm => fm.meterID == meterID).meterName;
      } catch (ex) {
        debugger;
      }
      //return meter ? this.meters.find(m => m.meterID == meter).meterName : meter.meterName;            
    } else {
      return "";
    }
  }

  public deleteMe(meterReadingID) {
    this.editDataItem = this.meterUsageDetails.find(mu => mu.meterReadingID == meterReadingID);

    this.dialogService
      .meterReadingDetail(this.editDataItem, false)
      .subscribe(res => {
        var index = this.meterUsageDetails.findIndex(m => m.meterReadingID == meterReadingID);
        this.meterUsageDetails.splice(index, 1);
        this.refreshData();
      });
  }

  public editHandler({ sender, rowIndex, dataItem }) {


    console.log(dataItem);
    this.editDataItem = dataItem;

    this.dialogService
      .meterReadingDetail(this.editDataItem, true)
      .subscribe(res => {
        if (res != undefined) {
          this.editDataItem = res;
          this.refreshData();
        }
      });
  }

  public addHandler(dataItem) {
    console.log(dataItem.items[0]);


    this.editDataItem = this.cloneItem(dataItem.items[0]);
    this.editDataItem.meterReadingID = null;
    this.editDataItem.reading = null;


    this.dialogService
      .meterReadingDetail(this.editDataItem, true)
      .subscribe(res => {
        this.meterUsageDetails.push(res);
        this.refreshData();
      });
  }

  public removeHandler({ dataItem }) {
    this.editDataItem = dataItem;

    this.dialogService
      .meterReadingDetail(this.editDataItem, false)
      .subscribe(res => {
        var index = this.meterUsageDetails.findIndex(m => m.meterReadingID == dataItem.meterReadingID);
        this.meterUsageDetails.splice(index, 1);
        this.refreshData();
      });
  }

  private refreshData(): void {
    this.processing = true;

    this.startDateFilter.setHours(0, 0, 0, 0);
    this.endDateFilter.setHours(0, 0, 0, 0);

    var filter = {
      startDateUTC: this.startDateFilter.toUTCString(),
      endDateUTC: this.endDateFilter.toUTCString(),
      meterProductID: this.meterProductID,
      customerID: this.customerID,
      meterID: this.meterID
    }

    this.http.post('api/reporting/meterusagedetail', filter)
      .subscribe((response: Array<MeterProductUsage>) => {        
        this.meterUsageDetails = response;

        this.loadData();
        this.processing = false;
      });
  }

  private refreshReport(): void {
    this.refreshData();
  }

  private loadData(): void {
    this.processing = true;
    this.gridView = process(orderBy(this.meterUsageDetails, this.sort), this.state);
    this.processing = false;
  }

  public dataStateChange(state: DataStateChangeEvent): void {

    console.log("dataStateChange event");
    console.log(state.group);

    state.group.map(
      group => group.aggregates = this.aggregates);

    this.state = state;  
  }

  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }


  public groupChange(groups: GroupDescriptor[]): void {
    this.state.group = groups;
    this.hiddenColumns = groups.map(g => g.field);
    console.log("Hidden columns: ");
    console.log(this.hiddenColumns);
    console.log("Groups: ");
    console.log(groups);
    this.loadData();
  }

  cloneItem(mpu: MeterProductUsage): MeterProductUsage {
    let newMpu = new MeterProductUsage();

    for (let prop in mpu) {
      newMpu[prop] = mpu[prop];
    }

    return newMpu;
  }
  //private total: any = aggregateBy(this.data, this.aggregates);
}
