import { Component, OnInit } from '@angular/core';
import { Carrier } from '../../../../objects/compliance/carrier';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { CarrierDialogService } from '../carrier-dialog.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'compliance-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: ['./carrier-list.component.css']
})
export class CarrierListComponent implements OnInit {
  public carriers: Carrier[];
  public carrier: Carrier;



  public gridCarriers: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }

  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: CarrierDialogService) {
    this.allData = this.allData.bind(this);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }


  ngOnInit() {
    this.http.get('/api/compliance/carrier')
      .subscribe((result: Carrier[]) => {
        this.carriers = result;

        this.refresh();
      });
  }

  showDialogToAdd() {
    this.carrier = new Carrier();
    this.carrier.activeFlag = true;

    this.dialogService
      .carrierDetail(this.carrier)
      .subscribe(res => {
        if (res != undefined) {
          this.carrierUpdated(res);
        }
      })
  }

  onRowSelect(event) {
    this.carrier = this.cloneCarrier(event);
    this.dialogService
      .carrierDetail(this.carrier)
      .subscribe(res => {
        this.carrierUpdated(res);
      })
  }

  carrierUpdated(carrier: Carrier) {
    this.carrier = carrier;
    var found: boolean = false;

    for (var i = 0; i < this.carriers.length && !found; i++) {
      var c = this.carriers[i];

      if (this.carrier.carrierID == c.carrierID) {
        this.carriers[i] = this.carrier;
        found = true;
        break;
      }
    }

    if (!found) {
      this.carriers.unshift(this.cloneCarrier(this.carrier));
    } else if (!this.carrier.activeFlag) {
      this.carriers.splice(i, 1);
    }

    this.carriers = [...this.carriers];
    this.refresh();
  }

  refresh() {
    this.gridCarriers = process(this.carriers, this.state);
  }

  public group: any[] = [{
    field: 'carrierName'
  }];

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      //group: this.group,
      data: process(this.carriers, { sort: [{ field: 'carrierName', dir: 'asc' }] }).data
    };
    return result;
  }

  cloneCarrier(c: Carrier): Carrier {
    let carrier = new Carrier();

    for (let prop in c) {
      carrier[prop] = c[prop];
    }

    return carrier;
  }
}
