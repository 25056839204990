import { Component, OnInit, OnDestroy, Input, Renderer, ElementRef, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportService } from '../../../../modules/core-services/services/report.service';

@Component({
  selector: 'app-report-display',
  templateUrl: './report-display.component.html',
  styleUrls: ['./report-display.component.scss']
})
export class ReportDisplayComponent implements OnInit, OnDestroy {
  @Input() public reportHTML: string;
  @Output() public executeReportAction: EventEmitter<string> = new EventEmitter<string>();
  public styleData: string;
  public styleDataSubscription: Subscription;

  constructor(elementRef: ElementRef, renderer: Renderer, private reportService: ReportService) {
    renderer.listen(elementRef.nativeElement, 'click', (event) => {
      let reportingAction = event.target.getAttribute('data-reporting-action');
      if (reportingAction != undefined && reportingAction != null) {
        this.executeReportAction.emit(reportingAction);
      }
    })
  }

  ngOnInit() {
    this.styleDataSubscription = this.reportService.styleReport$.subscribe((result) => {
      $(document).ready(function () {
        result += " .trv-pages-area div { box-sizing: content-box } ";
        $("style").append(result);
      })
    });
  }

  ngOnDestroy() {
    this.styleDataSubscription.unsubscribe();
  }
}
