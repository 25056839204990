import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Customer } from '../../objects/customer';
import { Facility } from '../../objects/facility';
import { CustomerDialogComponent } from './customer-dialog/customer-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CustomerDialogService {

  constructor(private dialog: MatDialog) { }

  public customerDetail(customer: Customer, facilities: Facility[]) {
    let dialogRef: MatDialogRef<CustomerDialogComponent>;

    dialogRef = this.dialog.open(CustomerDialogComponent);
    dialogRef.componentInstance.customer = customer;
    dialogRef.componentInstance.facilities = facilities;

    return dialogRef.afterClosed();
  }
}
