import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PhFlow } from '../../core/objects/ph-flow';

@Injectable({
  providedIn: 'root'
})
export class PhFlowService {
  private baseUrl: string = '/api/Effluent/phflow';

  constructor(private httpClient: HttpClient) { }

  listPhFlow(params){
    return this.httpClient.get(this.baseUrl, {params: params}).pipe(map(result => {
      return <[PhFlow]> result;
    }));
  }
}
