import { Component, OnInit } from '@angular/core';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { ImporterOfRecordDialogService } from '../importer-of-record-dialog.service';
import { ImporterOfRecord } from '../../../../objects/compliance/importer-of-record';

@Component({
  selector: 'app-importer-of-record-list',
  templateUrl: './importer-of-record-list.component.html',
  styleUrls: ['./importer-of-record-list.component.css']
})
export class ImporterOfRecordListComponent implements OnInit {
  public importerOfRecords: ImporterOfRecord[];
  public importerOfRecord: ImporterOfRecord;
  public gridImporters: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }
  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: ImporterOfRecordDialogService) { }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    this.http.get('/api/compliance/importer')
      .subscribe((result: ImporterOfRecord[]) => {
        this.importerOfRecords = result;
        this.refresh();
      });
  }

  showDialogToAdd() {
    this.importerOfRecord = new ImporterOfRecord();
    this.dialogService
      .importerDetail(this.importerOfRecord)
      .subscribe(res => {
        if (res != undefined) {
          this.importerUpdated(res);
        }
      })
  }

  onRowSelect(event) {
    this.importerOfRecord = this.cloneImporter(event);
    this.dialogService
      .importerDetail(this.importerOfRecord)
      .subscribe(res => {
        this.importerUpdated(res);
      });
  }

  importerUpdated(importer: ImporterOfRecord) {
    this.importerOfRecord = importer;
    var found: boolean = false;

    for (var i = 0; i < this.importerOfRecords.length && !found; i++) {
      var imp = this.importerOfRecords[i];

      if (this.importerOfRecord.importerID == imp.importerID) {
        this.importerOfRecords[i] = this.importerOfRecord;
        found = true;
      }
    }

    if (!found) {
      this.importerOfRecords.push(this.cloneImporter(this.importerOfRecord));
    }

    this.importerOfRecords = [...this.importerOfRecords];
    this.refresh();
  }

  refresh() {
    this.gridImporters = process(this.importerOfRecords, this.state);
  }

  cloneImporter(imp: ImporterOfRecord): ImporterOfRecord {
    let importer = new ImporterOfRecord();

    for (let prop in imp) {
      importer[prop] = imp[prop];
    }

    return importer;
  }
}
