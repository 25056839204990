import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { VesselDialogComponent } from './vessel-dialog/vessel-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class VesselDialogService {
  constructor(private dialog: MatDialog) {
  }

  public vesselDetail(Vessel: any) {
    let dialogRef: MatDialogRef<VesselDialogComponent>;

    dialogRef = this.dialog.open(VesselDialogComponent);
    dialogRef.componentInstance.vessel = Vessel;


    return dialogRef.afterClosed();
  }
}
