import { Meter } from '../interfaces/Meter';

export class umsMeter implements Meter {
  constructor(
    public meterID?,
    public meterQR?,
    public meterName?,
    public meterNumber?,
    public meterModelID?,
    public modelNumber?,
    public meterType?,
    public meterTypeID?,
    public importCode?,
    public correctionFactor?,
    public meterProductID?,
    public productName?,
    public customerID?,
    public customerName?,
    public meterGroupID?,
    public display?,
    public selected?,
    public meterGroupOrdinal?,
    public createdDateUTC?,
    public resetDateUTC?,
    public meterLimit?,
    public activeFlag?,
    public contractPrice?,
    public kepMeterID?,
    public smartMeter?,
    public electricMeter?,
    public substation?,
    public horsepower?,
    public efficiency?,
    public meterKepMeters?,
    public meterReadingID?,
    public meterReadingDateUTC?,
    public meterReading?
  ) {

    if (display == null) {
      display = true;
    }
  }
}

