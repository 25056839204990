import { Component, OnInit, Input } from '@angular/core';


import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';
import { MeterReading } from '../../../../objects/meter-reading';
import { HttpClient } from '@angular/common/http';
import { TankService } from '../../services/tank.service';
import { MeterService } from '../../services/meter.service';
import { FavoriteTankService } from '../../services/favorite-tank.service';

@Component({
  selector: 'tank-card',
  templateUrl: './tank-card.component.html',
  styleUrls: ['./tank-card.component.css']
})
export class TankCardComponent {

  public selectedTab: number = 1;
  public meterReading: MeterReading;
  public _tankUsage: TankUsageDetail;
  @Input()
  set tankUsage(item: TankUsageDetail) {
    this._tankUsage = item;
    this.loadMeterReading();
  }
  get tankUsage() {
    return this._tankUsage;
  }
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    public tankService: TankService,
    private meterService: MeterService,
    private router: Router,
    public favoriteTankService: FavoriteTankService
  ) {

  }

  public cardStatusIcon() {
    const tankData: TankUsageDetail = this.tankUsage;

    const temperatureDanger = this.tankService.isTankTemperatureStatusDanger(tankData);
    const temperatureWarning = this.tankService.isTankTemperatureStatusWarning(tankData);
    return {
      danger: temperatureDanger,
      warning: temperatureWarning,
      "temperature-danger": temperatureDanger,
      "temperature-warning": temperatureWarning
    };
  }

  public selectTab(tabNumber: number) {
    this.selectedTab = tabNumber;
  }

  public activeTabClass(tabNumber: number) {
    return { active: this.selectedTab === tabNumber };
  }

  public loadMeterReading() {
    if (!this.tankUsage) return;

    const tank = this.tankService.tanksById[this.tankUsage.tankID];
    if (!tank || !tank.steamMeter || !tank.steamMeter.meterReadingID) return;
      this.meterReading = new MeterReading(tank.steamMeter.meterReadingID, tank.steamMeter.meterID, tank.steamMeter.meterReading, tank.steamMeter.meterReadingDateUTC);
    //this.meterService.getMeterReading(tank.meterID).subscribe(reading => {
    //  console.log(reading);
    //});
  }

  public redirectToTankDetails() {
    this.router.navigate(['/tankusage']);
  }

  public redirectToWorkRequest() {
    this.router.navigate(['/workrequests']);
  }

  public removeFromWatchlist() {
    this.favoriteTankService.unmarkTankAsFavorite(this.tankUsage.tankID);
  }
}

