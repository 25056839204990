import { Component } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

  constructor(private authGuard: AuthGuard, public auth: AuthService) {

  }

  menuItems = [
    {
      name: 'Dashboard',
      icon: 'icon-home',
      link: '/home',
      linkActive: true,
      expanded: false
    },
    {
      name: 'Walking Lists',
      icon: 'icon-screen-tablet',
      link: '/walkinglists',
      linkActive: true,
      expanded: false
    },
    {
      name: 'Reports',
      icon: 'icon-book-open',
      link: '/nolink',
      linkActive: false,
      expanded: false,
      children: [{
        name: 'Meter Product Usage',
        link: '/meterproductusage'
      }, {
        name: 'Tank Usage',
        link: '/tankusage'
      }, {
        name: 'KEP Meter Usage',
        link: '/kepmeterusage'
      }]
    },
    {
      name: 'Users',
      icon: 'icon-user',
      link: '/users',
      linkActive: true,
      expanded: false
    },
    {
      name: 'Customers',
      icon: 'icon-users',
      link: '/customers',
      linkActive: true,
      expanded: false
    },
    {
      name: 'Products',
      icon: 'icon-folder',
      linkActive: false,
      link: '/nolink',
      expanded: false,
      children: [{
        name: 'Meter Products',
        link: '/meterproduct'
      }, {
        name: 'Tank Products',
        link: '/tankproduct'
      }]
    },
    {
      name: 'Meters',
      icon: 'icon-speedometer',
      link: '/nolink',
      linkActive: false,
      expanded: false,
      children: [{
        name: 'Meter Groups',
        link: '/metergroups'
      },
      {
        name: 'Master Meter List',
        link: '/master-meter-list'
      }, {
        name: 'Substations',
        link: '/substation-list'
      },
      {
        name: 'KEP Meter Tags',
        link: '/kepmeters'
      }]
    },
    {
      name: 'Tanks',
      icon: 'icon-drop',
      link: '/master-tank-list',
      linkActive: true,
      expanded: false
    },
    {
      name: 'Work Requests',
      icon: 'icon-folder',
      link: '/workrequests',
      linkActive: true,
      expanded: false
    },
    {
      name: 'Environmental',
      icon: 'icon-globe',
      linkActive: true,
      link: '/environmental',
      expanded: false,
      children: [
        {
          name: 'Consent Decree Transfers',
          icon: 'icon-doc',
          link: '/consent-decree-transfer'
        },
        {
          name: 'Methanol Additions',
          link: '/methanol-additions'
        }
      ]
    },
    {
      name: 'Compliance',
      icon: 'icon-notebook',
      linkActive: false,
      link: '/nolink',
      expanded: false,
      children: [{
        name: 'File Upload',
        icon: 'icon-doc',
        link: '/pepi-upload'
      },
      {
        name: 'IRS Product Codes',
        link: '/irsproductcode'
      },
      {
        name: 'Products',
        link: '/product'
      },
      {
        name: 'Terminals',
        link: '/terminals'
      },
      {
        name: 'Vessels',
        link: '/vessels'
      },
      //{
      //    name: 'Importers Of Record',
      //    link: 'importers'
      //},
      {
        name: 'Carriers',
        link: '/carriers'
      },
      {
        name: 'Customers',
        link: '/comp-customer'
      }]
    },
    {
      name: 'Compliance Reporting',
      linkActive: false,
      link: '/nolink',
      icon: 'icon-book-open',
      expanded: false,
      children: [{
        name: 'Tank Balance',
        link: '/tank-balance-report'
      },
      {
        name: 'End Of Month Balance',
        link: '/eom-balance'
      },
      {
        name: 'IRS Transactions',
        link: '/irs-transactions-report'
      },
      {
        name: 'IRS Summary',
        link: '/irs-summary-report'
      },
      {
        name: 'Process Errors',
        link: '/error-report'
      },
      {
        name: 'Process Error Details',
        link: '/error-detail-report'
      },
      {
        name: 'Air Emissions',
        link: '/air-emissions-report'
      }]
    }
  ];

  toggleMenuLink(menuitem) {
    this.menuItems.forEach(mi => mi.expanded = false);

    if (menuitem.children) {
      menuitem.expanded = !menuitem.expanded
    }


    console.log(menuitem);
  }
}
