import { Component, OnInit, Input } from '@angular/core';
import { MethanolAddition } from '../../objects/methanol-addition';
import { MethanolAdditionDialogService } from './methanol-addition-dialog.service';
import { HttpClient } from '@angular/common/http';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-methanol-addition',
  templateUrl: './methanol-addition.component.html',
  styleUrls: ['./methanol-addition.component.scss']
})
export class MethanolAdditionComponent implements OnInit {
  @Input()
  public facilityID: string;

  public methanolAdditions: MethanolAddition[];
  public methanolAddition: MethanolAddition;

  public gridMethanolAdditions: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }

  public pageSizes: boolean = true;
  public previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: MethanolAdditionDialogService) {
      
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }
   

  ngOnInit() {
    if (this.facilityID == null || this.facilityID == "") {
      //TODO: ensure that the facilityID is passed in to the component. This is hard coded currently for the dev ID of deer-park
      this.facilityID = "3A0CF74A-5522-49C6-9977-28DE248FB8BB";
    }

    this.http.get('/api/methanoladdition?facilityID=' + this.facilityID + '&startDate=1/1/2001&endDate=1/1/2019')
      .subscribe((result: MethanolAddition[]) => {
        this.methanolAdditions = result;
        this.refresh();
      });
    
  }

  onRowSelect(event) {
    this.methanolAddition = this.cloneMethanolAddition(event);
    this.dialogService.methanolAddition(this.methanolAddition)
      .subscribe(res => {
        if (res != undefined) {
          this.methanolAdditionsUpdated(res);
        }
      })
  }

  showDialogToAdd() {
    var methanol: MethanolAddition = new MethanolAddition();
    methanol.facilityID = this.facilityID;

    this.dialogService.methanolAddition(methanol)
      .subscribe(res => {
        this.methanolAdditionsUpdated(res);
      })
  }

  methanolAdditionsUpdated(methanolAddition: MethanolAddition) {
    this.methanolAddition = methanolAddition;
    var found: boolean = false;

    for (var i = 0; i < this.methanolAdditions.length && !found; i++) {
      var m = this.methanolAdditions[i];

      if (m.methanolAdditionID == methanolAddition.methanolAdditionID) {
        this.methanolAdditions[i] = this.methanolAddition;
        found = true;
      }
    }

    if (!found) {
      this.methanolAdditions.push(this.cloneMethanolAddition(methanolAddition));
    }

    this.methanolAdditions = [...this.methanolAdditions];
    this.refresh();
  }

  refresh() {
    this.gridMethanolAdditions = process(this.methanolAdditions, this.state);
  }

  cloneMethanolAddition(m: MethanolAddition) {
    let ma = new MethanolAddition();

    for (let prop in ma) {
      ma[prop] = m[prop];
    }

    return ma;
  }
}
