import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ReportViewerComponent } from '../../../components/reports/report-viewer/report-viewer.component';

@Component({
  selector: 'app-tank-balance-report',
  templateUrl: './tank-balance-report.component.html',

  styleUrls: ['./tank-balance-report.component.css'],
  //encapsulation: ViewEncapsulation.None
})

export class TankBalanceReportComponent implements OnInit {
  constructor() {

  }

  ngOnInit() {}
}
