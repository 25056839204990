import { Pipe, PipeTransform } from '@angular/core';
/*
 * Transform inches from number to a human readable string
 * Usage:
 *   amountOfInches | readableInches
 * Example:
 *   {{ 610 | exponentialStrength:10 }}
 *   formats to: 50' 10"
*/
@Pipe({ name: 'readableInches' })
export class ReadableInchesPipe implements PipeTransform {
  transform(value: number = 0): string {
    const feet = value / 12;
    const tunkedfeet = Math.floor(feet);
    const inches = Math.floor((feet - tunkedfeet) * 12);
    return `${tunkedfeet}' ${inches}"`;
  }
}
