import { Component, OnInit } from '@angular/core';
import { ReportServerConfig } from '../../../objects/reporting/report-server-config';


@Component({
  selector: 'app-kep-usage-report',
  templateUrl: './kep-usage-report.component.html',
  styleUrls: ['./kep-usage-report.component.scss']
})
export class KepUsageReportComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }



}
