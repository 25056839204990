import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';
import { MeterProductUsage } from '../../../../objects/meter-product-usage';
import { MeterReading } from '../../../../objects/meter-reading';

@Component({
  selector: 'app-meter-reading-dialog',
  templateUrl: './meter-reading-dialog.component.html',
  styleUrls: ['./meter-reading-dialog.component.css']
})

export class MeterReadingDialogComponent implements OnInit {
  @Input()
  public meterReading: MeterProductUsage;
  @Input()
  public isUpdating: boolean;

  submitting: boolean = false;
  public meterReadingForm: FormGroup;

  private readingDate: Date;

  constructor(public http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<MeterReadingDialogComponent>) {

  }

  ngOnInit() {
    this.meterReadingForm = this._fb.group({
      meterReadingID: [this.meterReading.meterReadingID],
      meterID: [this.meterReading.meterID],
      createdDateUTC: [this.meterReading.createdDateUTC],
      reading: [this.meterReading.reading]
    });

    this.readingDate = new Date(this.meterReading.createdDateUTC + 'Z');
  }

  saveMeterReading() {
    this.submitting = true;
    this.meterReadingForm.value.createdDateUTC = this.readingDate;

    if (this.meterReading.meterReadingID == null) {
      this.http.post('/api/meterreading', this.meterReadingForm.value)
        .subscribe((result: MeterReading) => {
          this.meterReading.reading = this.meterReadingForm.value.reading;
          this.meterReading.createdDateUTC = this.meterReadingForm.value.createdDateUTC;
          this.meterReading.meterReadingID = result.meterReadingID;

          this.dialogRef.close(this.meterReading);
          this.submitting = false;
        });
    } else {
      this.http.put('/api/meterreading', this.meterReadingForm.value)
        .subscribe(result => {
          this.meterReading.reading = this.meterReadingForm.value.reading;
          this.meterReading.createdDateUTC = this.meterReadingForm.value.createdDateUTC;
          this.dialogRef.close(this.meterReading);
          this.submitting = false;
        })
    }
  }

  deleteMeterReading() {
    this.submitting = true;

    this.http.delete('/api/meterreading/' + this.meterReading.meterReadingID)
      .subscribe(result => {
        this.dialogRef.close();
        this.submitting = false;
      });

  }

  closeModal() {
    this.dialogRef.close();
  }
}
