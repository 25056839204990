import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { VocReportsService } from '../../../core-services/services/voc-reports.service';
import { Observable, Subscription } from 'rxjs';
import { Month } from '../../../../interfaces/month';

@Component({
  selector: 'app-search-month',
  templateUrl: './search-month.component.html',
  styleUrls: ['./search-month.component.scss']
})
export class SearchMonthComponent implements OnInit, OnDestroy {
  public months: Array<Month>;
  public months$: Observable<Array<Month>>;
  public monthsSubscription: Subscription;
  public monthSelected: Array<Month>;
  public monthSelected$: Observable<Array<Month>>;
  public monthSelectedSubscription: Subscription;
  constructor(private vocReportsService: VocReportsService) {
    this.months = new Array();
  }

  ngOnInit() {
    this.vocReportsService.resetData();
    this.months$ = this.vocReportsService.months$;
    this.monthsSubscription = this.months$.subscribe(data => this.months = data);
    this.monthSelected$ = this.vocReportsService.getSelectedMonth();
    this.monthSelectedSubscription = this.monthSelected$.subscribe(data => this.monthSelected = data);
  }

  ngOnDestroy(){
    this.vocReportsService.resetSelectedMonth();
    this.monthsSubscription.unsubscribe();
    this.monthSelectedSubscription.unsubscribe();
  }

  checkMonth(index){
    const itemChecked = this.months[index];
    this.vocReportsService.updateSelectedMonths(itemChecked);
  }

}
