import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TerminalDialogComponent } from './terminal-dialog/terminal-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TerminalDialogService {
  constructor(private dialog: MatDialog) {
  }

  public terminalDetail(terminal: any) {
    let dialogRef: MatDialogRef<TerminalDialogComponent>;

    dialogRef = this.dialog.open(TerminalDialogComponent);
    dialogRef.componentInstance.terminal = terminal;


    return dialogRef.afterClosed();
  }
}
