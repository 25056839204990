import { Component, OnInit } from '@angular/core';
import { MeterProduct } from '../../../objects/meter-product';
import { HttpClient } from '@angular/common/http';
import { MeterProductDialogService } from '../meter-product-dialog.service';

@Component({
  selector: 'app-meter-product',
  templateUrl: './meter-product.component.html',
  styleUrls: ['./meter-product.component.css']
})

export class MeterProductComponent implements OnInit {
  public products: MeterProduct[];
  public product: MeterProduct;


  constructor(private http: HttpClient, private dialogService: MeterProductDialogService) {

  }

  ngOnInit() {
    this.http.get('/api/product/meterproducts')
      .subscribe((result : MeterProduct[]) => {
        this.products = result;
      });
  }

  productUpdated(product: MeterProduct) {
    this.product = product;
    var found: boolean = false;

    for (var i = 0; i < this.products.length && !found; i++) {
      var p = this.products[i];

      if (this.product.meterProductID == p.meterProductID) {
        this.products[i] = this.product;
        found = true;
        break;
      }
    }

    if (!found) {
      this.products.push(this.cloneProduct(this.product));
    } else if (!this.product.activeFlag) {
      this.products.splice(i, 1);
    }

    this.products = [... this.products];
  }

  onRowSelect(event) {
    this.product = this.cloneProduct(event);
    this.dialogService
      .productDetail(this.product)
      .subscribe(res => {
        this.productUpdated(res);
      });
  }

  showDialogToAdd() {
    this.product = new MeterProduct();
    this.dialogService
      .productDetail(this.product)
      .subscribe(res => {
        this.products.push(res);
      });
  }

  cloneProduct(p: MeterProduct): MeterProduct {
    let product = new MeterProduct();

    for (let prop in p) {
      product[prop] = p[prop];
    }

    return product;
  }

}
