import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'filterByProp'
})

@Injectable()
export class FilterByPropPipe implements PipeTransform {
  transform(items: any[], prop: string, term: string): any[] {
    if (!items) {
      return [];
    }
    if (!prop || !term) {
      return items;
    }

    return items.filter(currentItem => currentItem[prop].toLowerCase().includes(term.toLowerCase()));
  }
}
