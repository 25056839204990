import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input } from '@angular/core';
import * as ProgressBar from 'progressbar.js';

@Component({
  selector: 'read-progressbar',
  templateUrl: './read-progressbar.component.html',
  styleUrls: ['./read-progressbar.component.css']
})

export class ReadProgressbarComponent implements AfterViewInit {
  public barId: string = "";
  public bar: any;
  public current: number = 0; //current tank read
  public totalCount: number = 0; // total number of tanks
  public progress: number = 0;

  @ViewChild('barcontainer') barcontainer: ElementRef;
  @Input()
  public label: string = "";
  @Input('read')
  set read(read: number) {
    this.current = read;
    this.updateProgress();
  }

  @Input('total')
  set total(total: number) {
    this.totalCount = total;
    this.updateProgress();
  }


  constructor() {
    this.progress = 0;
  }

  ngAfterViewInit() {
    // progressbar.js@1.0.0 version is used
    // Docs: http://progressbarjs.readthedocs.org/en/1.0.0/

    this.bar = new ProgressBar.Line(this.barcontainer.nativeElement, {
      strokeWidth: 3,
      trailWidth: 3,
      easing: 'easeInOut',
      duration: 1400,
      color: '#757e8c',
      trailColor: '#d8dade',
      svgStyle: { width: '100%', height: '100%' }
    });

    this.bar.animate(0.0);  // Number from 0.0 to 1.0
    this.bar.path.setAttribute('stroke-linecap', 'round');
    this.bar.trail.setAttribute('stroke-linecap', 'round');
    this.updateProgress();
  }

  updateProgress() {
    if (this.totalCount === 0) {
      this.progress = 1;
    } else {
      const percent = this.current / this.totalCount;
      this.progress = percent > 1 ? 1 : percent < 0 ? 0 : percent;
    }
    if (this.bar) this.bar.animate(this.progress);
  }


  // uuidv4() {
  //   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
  //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  //   )
  // }


}

