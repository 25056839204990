import { Component, OnInit, Input } from '@angular/core';
import { MeterProduct } from '../../../objects/meter-product';
import { FormGroup, FormBuilder } from '@angular/forms';

import { MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-meter-product-dialog',
  templateUrl: './meter-product-dialog.component.html',
  styleUrls: ['./meter-product-dialog.component.css']
})
export class MeterProductDialogComponent implements OnInit {
  @Input()
  public product: MeterProduct;

  public productForm: FormGroup;

  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<MeterProductDialogComponent>) {

  }

  ngOnInit() {
    this.productForm = this._fb.group({
      activeFlag: [this.product.activeFlag],
      meterProductID: [this.product.meterProductID],
      productName: [this.product.productName],
      facilityID: [this.product.facilityID],
      cost: [this.product.cost],
      unitOfMeasure: [this.product.unitOfMeasure],
      description: [this.product.description]
    })
  }

  saveProduct() {
    this.submitting = true;

    this.http.post('/api/product/meterproduct', this.productForm.value)
      .subscribe((result : number) => {
        this.product = this.productForm.value;
        this.product.meterProductID = result;

        this.dialogRef.close(this.product);
        this.submitting = false;
      },
        err => console.log(err),
        () => {
          console.log('Request Complete');
          this.submitting = false;
        });
  }

  deleteProduct() {
    this.deleting = true;

    this.http.delete('/api/product/meterproduct/' + this.product.meterProductID)
      .subscribe(result => {
        this.product.activeFlag = false;
        this.deleting = false;

        this.dialogRef.close(this.product);
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
