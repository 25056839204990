import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BsDropdownModule } from 'ngx-bootstrap';
import { MatSliderModule, MatSlideToggleModule } from '@angular/material';

//* components
import { TankStatusTableComponent } from "./components/tank-status-table/tank-status-table.component";
import {
  ReadProgressbarComponent,
  TankReadComponent,
  TerminalInfoComponent,
  TerminalSelectorComponent,
  RecentAlertsComponent
} from './components/terminal-info';
import {
  TankCardComponent,
  TankGridComponent,
  TemperatureCardComponent,
  DetailsCardComponent,
  ReadingsCardComponent
} from './components/tank-card';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { WatchlistDialogComponent } from './components/watchlist-dialog/watchlist-dialog.component';
import { WatchlistTagComponent } from './components/watchlist-tag/watchlist-tag.component';

//* services
import {
  FavoriteTankService,
  TankService,
  MeterService,
  WatchlistDialogService
} from './services';

//* Pipes
import { ReadableInchesPipe } from './pipes/readable-inches.pipe';
import { FilterByPropPipe } from './pipes/filter-by-prop.pipe';
import { AuthService } from '../../services/auth.service';
import { MomentModule } from 'ngx-moment';

const pipes = [
  ReadableInchesPipe,
  FilterByPropPipe,
];

const components = [
  TankStatusTableComponent,
  ReadProgressbarComponent,
  TankReadComponent,
  TerminalInfoComponent,
  TerminalSelectorComponent,
  DashboardComponent,
  TankCardComponent,
  TankGridComponent,
  TemperatureCardComponent,
  DetailsCardComponent,
  ReadingsCardComponent,
  WatchlistDialogComponent,
  WatchlistTagComponent,
  RecentAlertsComponent,
];

const services = [
  FavoriteTankService,
  TankService,
  MeterService,
  WatchlistDialogService,
];

@NgModule({
  declarations: [
    ...components,
    ...pipes
  ],
  imports: [
    FormsModule,
    CommonModule,
    HttpModule,
    GridModule,
    MomentModule,
    DropDownsModule,
    BsDropdownModule.forRoot(),
    MatSlideToggleModule,
    MatSliderModule,
  ],
  exports: [...components],
  
  entryComponents: [
    WatchlistDialogComponent,
  ]
})
export class DashboardModule { }
