import { Tank } from "./tank";
import { Meter } from "../interfaces/meter";

export class Customer {
  constructor(
    public customerID?: number,
    public customerName?: string,
    public facilityID?: string,
    public facilityName?: string,
    public contactPerson?: string,
    public email?: string,
    public telephone?: string,
    public address?: string,
    public accountNumber?: string,
    public activeFlag?: Boolean,
    public createdDateUTC?: Date,
    public inactiveDateUTC?: Date,
    public customerMeters?: Meter[],
    public customerTanks?: Tank[],
    public customerFEIN?: string,
    public importerOfRecord?: boolean,
    public customer637Number?: string
  ) {

  }
}
