import { Component, OnInit, Input } from '@angular/core';
import { WorkRequest } from '../../../objects/work-request';
import { WorkRequestType } from '../../../objects/work-request-type';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-work-request-dialog',
  templateUrl: './work-request-dialog.component.html',
  styleUrls: ['./work-request-dialog.component.css']
})
export class WorkRequestDialogComponent implements OnInit {
  @Input()
  public workRequest: WorkRequest;

  @Input()
  public workRequestTypes: WorkRequestType[];

  submitting: boolean = false;
  public workRequestForm: FormGroup;

  constructor(public http: HttpClient,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<WorkRequestDialogComponent>) {

  }

  ngOnInit() {
    this.workRequestForm = this._fb.group({
      meterID: [this.workRequest.meterID],
      workRequestID: [this.workRequest.workRequestID],
      workRequestTypeID: [this.workRequest.workRequestTypeID],
      tankID: [this.workRequest.tankID],
      comment: [this.workRequest.comment],
      createdDateUTC: [this.workRequest.createdDateUTC],
      completedDateUTC: [this.workRequest.completedDateUTC]
    })
  }

  saveWorkRequest() {
    this.submitting = true;
  }

  completeWorkRequest() {
    //debugger;
    this.workRequest.completedDateUTC = new Date(Date.now());

    this.http.post('/api/workrequest', this.workRequest)
      .subscribe(result => {
        //console.log("Work Request Complete Status: " + result.status);
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
