import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { TankService } from '../../services/tank.service';
import * as moment from 'moment';
import { LineChart, lineStyle } from '../../utils/line-chart';

@Component({
  selector: 'readings-card',
  templateUrl: './readings-card.component.html',
  styleUrls: ['./readings-card.component.css']
})
export class ReadingsCardComponent implements OnInit {
  @Input()
  public tankUsage: TankUsageDetail;
  @ViewChild('widget')
  public widget: ElementRef;
  private chart: LineChart;
  //private timer: NodeJS.Timer;
  public isSteamOn: boolean = true;
  public isNitrogenOn: boolean = true;
  public isCirculationOn: boolean = true;

  public readonly lineTags = {
    temperature: 'Temperature',
    steam: 'Steam',
    circulation: 'Circulation',
    nitrogen: 'Nitrogen'
  };

  constructor(public tankService: TankService) {
  }

  ngOnInit(): void {
    const chartdata = this.tankService.dailyTankUsage
      .filter((element) => element.tankID === this.tankUsage.tankID)
      .sort((left, right) => {
        return moment(left.createdDateUTC).diff(right.createdDateUTC);
      })
      .map((element) => ({
        date: moment.utc(element.createdDateUTC).toDate(),
        close: element.temperature
      }));
    let filtered = [];
    if (chartdata.length > 0) {
      const lastElement = chartdata[chartdata.length - 1];
      const startTime = moment(lastElement.date).startOf('day');
      const endTime = moment(lastElement.date).endOf('day');
      filtered = chartdata.filter((element) => {
        return moment(element.date).isSame(startTime, 'd');
      });
    }
    const tags = ['Temperature', 'Steam', 'Circulation', 'Nitrogen'];
    this.chart = new LineChart(this.widget.nativeElement, filtered);
    this.chart.addLine(filtered, lineStyle.continuous, 'Temperature');
    const startDate = moment(new Date()).startOf('day').toDate();
    const endDate = moment(startDate).endOf('day').toDate();
  }

  public generateRandomDataPoints(startDate: Date, endDate: Date) {
    const elementCount = 1 + Math.ceil(Math.random() * 4);
    const elements = [];
    for (let i = 0; i < elementCount; i++) {
      elements[i] = {
        date: this.randomDate(startDate, endDate),
        close: Math.random() * 50 + Math.random() * 50 + Math.random() * 50 + Math.random() * 50
      }
    }
    return elements.sort((left, right) => {
      return moment(left.date).diff(right.date);
    })
  }
  public randomDate(startDate: Date, endDate: Date) {
    return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
  }

  public get timestamp(): string {
    let formatedTime = '';
    if (this.tankUsage) {
      const createdAt = moment(this.tankUsage.createdDateUTC);
      formatedTime = createdAt.format('hh:mm  DD/MM/YYYY');
    }
    return formatedTime;
  }

  public toggleLine(tag: string) {
    this[`is${tag}On`] = this.chart.toggleLine(tag);
  }

  ngOnDestroy(): void {
    //if (this.timer) {
    //  clearInterval(this.timer);
    //}
  }
}

