import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetDmrReportService {

  constructor(private httpClient: HttpClient) { }

  async downloadNetDmr(params) {
    let response = {
      name: '',
      blob: undefined,
      status: 200
    }
    const file = await this.httpClient.get<Blob>
                            ('/api/Effluent/netdmrreport', 
                              {params: params, responseType: 'blob' as 'json', observe: 'response' })
                            .pipe(map(
                              result => {
                                response.name = result.headers.get('Content-Disposition').split("''")[1];
                                response.blob = result.body;
                                return response;
                              }
                            ), catchError(
                              (error, caugth)=>{
                                response.status = error.status;
                                response.blob = error.error;
                                return of(response);
                              }
                            )).toPromise();
    return file;
  }

  async downloadConcentDecree(params) {
    let response = {
      name: '',
      blob: undefined,
      status: 200
    }
    const file = await this.httpClient.get<Blob>
                            ('/api/Effluent/consentdecreeReport', 
                              {params: params, responseType: 'blob' as 'json', observe: 'response' })
                            .pipe(map(
                              result => {
                                response.name = result.headers.get('Content-Disposition').split("''")[1];
                                response.blob = result.body;
                                return response;
                              }
                            ), catchError(
                              (error, caugth)=>{
                                response.status = error.status;
                                response.blob = error.error;
                                return of(response);
                              }
                            )).toPromise();
    return file;
  }

  downloadFile(blob: Blob, name: string, downloadLink) {
    const url = window.URL.createObjectURL(blob);
    const link = downloadLink.nativeElement;
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
  }
}
