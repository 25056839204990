import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { HttpClient } from '@angular/common/http';

const URL = '/api/upload';

@Component({
  selector: 'app-pepi-upload',
  templateUrl: './pepi-upload.component.html',
  styleUrls: ['./pepi-upload.component.css']
})

export class PepiUploadComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({
    url: URL
  });
  public hasBaseDropZoneOver: boolean = false;
  public processing: boolean = false;
  public importFileStatus: FileStatus[] = [{
    importFileID: 1,
    fileName: 'Loading Data',
    uploadDateUTC: null,
    processedDateUTC: null,
    message: ''
  }];
  public token: any;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.token = (() => localStorage.getItem('access_token'));
    this.uploader.authToken = this.token;

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {


      this.refreshGrid();
    };

    this.refreshGrid();
  }

  refreshGrid() {
    this.processing = true;

    this.http.get('/api/compliance/filestatus')
      .subscribe((result: FileStatus[]) => {
        this.importFileStatus = result;
        this.processing = false;
      }, err => {
        this.processing = false;
      });
  }
}

export interface FileStatus {
  importFileID: number;
  fileName: string;
  uploadDateUTC: Date;
  processedDateUTC: Date;
  message: string;
}
