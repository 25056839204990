import { Component, OnInit } from '@angular/core';
import { KepMeter } from '../../objects/kep-meter';
import { Metric } from '../../metric';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { KepMeterDialogService } from './kep-meter-dialog.service';

@Component({
  selector: 'app-kep-meter',
  templateUrl: './kep-meter.component.html',
  styleUrls: ['./kep-meter.component.css']
})
export class KepMeterComponent implements OnInit {
  public kepmeters: KepMeter[];
  public kepMeter: KepMeter;

  private metrics: Metric[];

  public gridKepMeters: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }
  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: KepMeterDialogService) {

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    this.http.get('/api/kep')
      .subscribe((result : KepMeter[])=> {
        this.kepmeters = result;
        this.refresh();
      });

    this.http.get('/api/kep/metrics')
      .subscribe((result : Metric[])=> {
        this.metrics = result;
      });
  }

  onRowSelect(event) {
    this.kepMeter = this.cloneKepMeter(event);

    this.dialogService.kepMeterDetail(this.kepMeter, this.metrics)
      .subscribe(res => {
        if (res != undefined) {
          this.kepMeterUpdated(res);
        }
      })
  }

  kepMeterUpdated(kepMeter: KepMeter) {
    this.kepMeter = kepMeter;
    var found: boolean = false;

    for (var i = 0; i < this.kepmeters.length && !found; i++) {
      var k = this.kepmeters[i];

      if (this.kepMeter.kepMeterID = k.kepMeterID) {
        this.kepmeters[i] = this.kepMeter;
        found = true;
      }
    }

    if (!found) {
      this.kepmeters.push(this.cloneKepMeter(this.kepMeter));
    }

    this.kepmeters = [...this.kepmeters];
    this.refresh();
  }

  refresh() {
    this.gridKepMeters = process(this.kepmeters, this.state);
  }

  cloneKepMeter(k: KepMeter): KepMeter {
    let kepMeter = new KepMeter();

    for (let prop in k) {
      kepMeter[prop] = k[prop];
    }

    return kepMeter;
  }
}
