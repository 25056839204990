import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Guard } from '../interfaces/guard';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {

  constructor(private auth: AuthService, private router: Router, private injector: Injector) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    let roles = route.data["roles"] as Array<string>;
    let groups = route.data["groups"] as Array<string>;
    if (this.auth.isAuthenticated()) {
      var currentUserProfile = this.auth.getUserProfile();

      var userRoles: string[] = currentUserProfile["https://ums-api.uneelabs.com/roles"];
      var userGroups: string[] = currentUserProfile["https://ums-api.uneelabs.com/groups"];

      let allow = this.allowTransition(roles, userRoles, groups, userGroups);
      
      if (!allow) {
        if (this.canNavigate('/home')) {
          this.router.navigate(['/home']);
        } else if (this.canNavigate('/environmental')) {
          this.router.navigate(['/environmental']);
        } else if (this.canNavigate('/pepi-upload')) {
          this.router.navigate(['/pepi-upload']);
        } else {
          this.router.navigate[''];
        }
      }

      return allow;

    } else {

      localStorage.setItem('redirect_url', state.url);
      this.router.navigate(['/login']);
      return false;

    }
  }

  canNavigate(path: string) {  
    var currentUserProfile = this.auth.getUserProfile();
    var userRoles: string[] = currentUserProfile["https://ums-api.uneelabs.com/roles"];
    var userGroups: string[] = currentUserProfile["https://ums-api.uneelabs.com/groups"];


    for (let i = 0; i < this.router.config.length; i++) {
      let children = this.router.config[i].children;
      if (children != null) {
        let r = children.find(c => c.path == path.substring(1));
        if (r != null) {

          let canActivate = r.canActivate;

          let allowedRoles = r.data != null ? r.data['roles'] : null;
          let allowedGroups = r.data != null ? r.data['groups'] : null;

          for (let a = 0; a < canActivate.length; a++) {
            let canActivateInstance = this.injector.get(canActivate[a]) as Guard;
            if (!canActivateInstance.allowTransition(allowedRoles, userRoles, allowedGroups, userGroups)) {
              return false;
            } else {
              return true;
            }
          }




        }
      }

    }

    return false;
  }

  allowTransition(allowedRoles: string[], userRoles: string[], allowedGroups: string[], userGroups: string[]): boolean {

    let allowViaRole: boolean = (allowedRoles == null || allowedRoles.length == 0 || userRoles.filter(r => allowedRoles.indexOf(r) !== -1).length > 0);
    let allowViaGroup: boolean = (allowedGroups == null || allowedGroups.length == 0 || userGroups.filter(r => allowedGroups.indexOf(r) !== -1).length > 0);

    return allowViaRole && allowViaGroup;
  }
}
