import { FormGroup, FormArray, Validators, AbstractControl } from "@angular/forms";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { SubstationRate } from "../../objects/substation-rate";

export class SubstationRateValidator extends Validators {

  public static validDateRange(control: AbstractControl) {
    let valid = true;
    let rateControls = control.get("rates") as FormArray;

    const moment = extendMoment(Moment); //extendMoment(Moment);
    
    for (var i: number = 0; i < rateControls.length; i++) {
      for (var h: number = 1; h < rateControls.length; h++) {
        var rateA: SubstationRate = rateControls.value[i] as SubstationRate; 
        var rateB: SubstationRate = rateControls.value[h] as SubstationRate;

        if (rateA.substationRateID != rateB.substationRateID) {
          if (rateA.endDateUTC == null) {
            rateA.endDateUTC = new Date(2400, 1, 1);
          }

          if (rateB.endDateUTC == null) {
            rateB.endDateUTC = new Date(2400, 1, 1);
          }
           
          var range1 = moment.range(rateA.startDateUTC, rateA.endDateUTC);
          var range2 = moment.range(rateB.startDateUTC, rateB.endDateUTC);

          if (range1.overlaps(range2)) {
            valid = false;
          }
        }
      }
    }
    

    if (valid) {
      return null;
    }

    return {
      datesOverlap: "The rate date ranges overlap."
    };
  }

  private multipleDateRangeOverlaps(): boolean {



    return false;
}
}
