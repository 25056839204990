import { Component, OnInit } from '@angular/core';
import { WorkRequest } from '../../../objects/work-request';
import { WorkRequestType } from '../../../objects/work-request-type';
import { SortDescriptor, State, process, orderBy } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { WorkRequestDialogService } from '../work-request-dialog.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-work-request',
  templateUrl: './work-request.component.html',
  styleUrls: ['./work-request.component.css']
})
export class WorkRequestComponent implements OnInit {
  public workRequests: WorkRequest[];
  public workRequestGridData: GridDataResult;

  private workRequestTypes: WorkRequestType[];
  private showCompleted: boolean = false;
  public sort: SortDescriptor[] = [];

  public processing: boolean;
  public state: State = {
    sort: this.sort
  }


  constructor(
    private http: HttpClient,
    private dialogService: WorkRequestDialogService
  ) {

  }

  ngOnInit() {
    this.http.get('/api/workrequesttype').subscribe((result: WorkRequestType[]) => {
      this.workRequestTypes = result;
    })

    this.refreshData();
  }

  showDetail(workRequestID) {
    var workRequest = this.workRequests.find(wr => wr.workRequestID == workRequestID);

    this.dialogService
      .workRequestDetail(workRequest, this.workRequestTypes)
      .subscribe(res => {
        if (res != undefined) {
          workRequest = res;
          this.refreshData();
        }
      });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;

    this.loadData();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
  }

  private loadData() {
    this.workRequestGridData = process(orderBy(this.workRequests, this.sort), this.state);
  }

  private refreshData(): void {
    this.processing = true;


    let filter = {
      meterID: null,
      includeCompleted: this.showCompleted
    };

    this.http.post('/api/workrequest/search', filter).subscribe((result : WorkRequest[])=> {
      this.workRequests = result;
      this.loadData();
      this.processing = false;
    });
  }
}
