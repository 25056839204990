
export class TankUsageDetail {
  constructor(
    public tankID?: string,
    public tankReadingID?: string,
    public tankNumber?: string,
    public createdDateUTC?: Date,
    public productName?: string,
    public temperature: number = 0,
    public maxTemperature: number = 0,
    public level: number = 0,
    public minTemperature: number = 0,
    public circulationToggle?: boolean,
    public steamToggle?: boolean,
    public nitrogenToggle?: boolean,
    public comment?: string,
    public customerName?: string,
    public circulationToggleText?: string,
    public steamToggleText?: string,
    public nitrogenToggleText?: string,
    public hasAlert: boolean = false,
    public temperatureStatus: string = 'ok',
    public levelStatus: string = 'ok',
  ) {

  }

  public feet(): number {
    return Math.floor(this.level / 12);
  }
}
