import { Component, OnInit, Input } from '@angular/core';
import { Meter } from '../../../interfaces/Meter';
import { MeterReading } from '../../../objects/meter-reading';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { umsMeterType } from '../../../objects/ums-meter-type';
import { MeterProduct } from '../../../objects/meter-product';
import { Customer } from '../../../objects/customer';
import { MeterModel } from '../../../objects/meter-model';
import { KepMeter } from '../../../objects/kep-meter';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';
import { Substation } from '../../../objects/substation';

@Component({
  selector: 'app-meter-dialog',
  templateUrl: './meter-dialog.component.html',
  styleUrls: ['./meter-dialog.component.css']
})
export class MeterDialogComponent implements OnInit {
  @Input() public meter: Meter;
  @Input() public meterReading: MeterReading;
  @Input() public substations: Substation[];

  public meterForm: FormGroup;
  newMeter: boolean;
  displayDialog: boolean;

  @Input()
  public meterTypes: umsMeterType[];

  @Input()
  public products: MeterProduct[];

  @Input()
  public customers: Customer[];

  @Input()
  public meterModels: MeterModel[];

  @Input()
  public kepMeters: KepMeter[];

  submitting: boolean = false;
  deleting: boolean = false;
  private readingChanged = false;

  constructor(public http: HttpClient,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<MeterDialogComponent>) {

  }

  ngOnInit() {
    console.log("Initializing Meter Dialog");

    if (this.customers.findIndex(c => c.customerID == null) == -1) {
      var emptyCustomer: Customer = new Customer();
      emptyCustomer.customerName = 'No Customer';
      this.customers.unshift(emptyCustomer);
    }

    if (this.products.findIndex(p => p.meterProductID == null) == -1) {
      var emptyProduct: MeterProduct = new MeterProduct();
      emptyProduct.productName = 'No Product';
      this.products.unshift(emptyProduct);
    }

    if (this.meterReading == null) {
      this.meterReading = new MeterReading();
    }

    this.meterForm = this._fb.group({
      meterID: [this.meter.meterID],
      meterNumber: [this.meter.meterNumber],
      meterName: [this.meter.meterName, Validators.required],
      meterType: [this.meter.meterType],
      meterTypeID: [this.meter.meterTypeID, Validators.required],
      productName: [this.meter.productName],
      meterProductID: [this.meter.meterProductID, Validators.required],
      modelNumber: [this.meter.modelNumber],
      meterModelID: [this.meter.meterModelID],
      correctionFactor: [this.meter.correctionFactor, Validators.required],
      resetDateUTC: [this.meter.resetDateUTC],
      meterLimit: [this.meter.meterLimit, Validators.required],
      customerName: [this.meter.customerName],
      customerID: [this.meter.customerID],
      contractPrice: [this.meter.contractPrice],
      activeFlag: [this.meter.activeFlag],
      importCode: [this.meter.importCode],
      initialReading: [this.meterReading.reading],
      initialDateUTC: [this.meterReading.createdDateUTC],
      kepMeterID: [this.meter.kepMeterID],
      smartMeter: [this.meter.smartMeter],
      electricMeter: [this.meter.electricMeter],
      substationID: [this.meter.substationID],
      horsepower: [this.meter.horsepower],
      efficiency: [this.meter.efficiency]
    })
  }

  initialReadingChanged() {
    console.log("Reading Changed");
    this.readingChanged = true;
  }

  saveMeter() {
    this.submitting = true;
    //debugger;
    if (this.meterForm.value.kepMeterID == null) {
      this.meterForm.value.kepMeterID = [];
    }

    console.log(this.meterForm.value);
    this.http.post('/api/Meter', this.meterForm.value)
      .subscribe(
        (result: string) => {
          this.meter = this.meterForm.value;
          this.meter.meterID = result;


          this.meterReading.meterID = this.meter.meterID;

          if (this.meterReading.meterReadingID == null) {
            this.http.post('/api/MeterReading', this.meterReading)
              .subscribe(result => {
                console.log("Meter Reading Created");
              });
          } else {
            this.http.put('/api/MeterReading', this.meterReading)
              .subscribe(result => {
                console.log("Meter Reading Updated");
              });

          }


          if (this.meter.meterProductID != null) {
            this.meter.productName = this.products.filter(p => p.meterProductID == this.meter.meterProductID)[0].productName;
          } else {
            this.meter.productName = "";
          }

          if (this.meter.meterTypeID != null) {
            this.meter.meterType = this.meterTypes.filter(m => m.meterTypeID == this.meter.meterTypeID)[0].meterTypeName;
          } else {
            this.meter.meterType = "";
          }

          if (this.meter.customerID != null) {
            this.meter.customerName = this.customers.filter(c => c.customerID == this.meter.customerID)[0].customerName;
          } else {
            this.meter.customerName = "";
          }

          if (this.meter.meterModelID != null) {
            this.meter.modelNumber = this.meterModels.filter(m => m.meterModelID == this.meter.meterModelID)[0].modelNumber;
          }

          this.submitting = false;
          this.dialogRef.close(this.meter);
        },
        err => console.log("Error => " + err));
  }

  deleteMeter() {
    this.deleting = true;

    this.http.delete('/api/Meter/' + this.meter.meterID)
      .subscribe(

        result => {
          this.meter.activeFlag = false;

          this.deleting = false;
          this.dialogRef.close(this.meter);
        })
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
