export class KepMeter {
  constructor(
    public kepMeterID?: number,
    public kepID?: string,
    public facilityID?: string,
    public metricID?: number
  ) {

  }
}
