import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'getMonth'
})
export class GetMonthPipe implements PipeTransform {

  transform(month): string {
    return moment.months(month - 1);;
  }

}
