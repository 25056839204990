import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/home/home.component';
import { CarrierListComponent } from './compliance/compliance-lookup/Carrier/carrier-list/carrier-list.component';
import { LoginComponent } from './components/login/login.component';
import { CallbackComponent } from './components/callback/callback.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ImporterOfRecordListComponent } from './compliance/compliance-lookup/ImporterOfRecord/importer-of-record-list/importer-of-record-list.component';
import { PepiUploadComponent } from './compliance/pepi-upload/pepi-upload.component';
import { MeterProductUsageComponent } from './components/reports/meter-product-usage/meter-product-usage.component';
import { TankUsageComponent } from './components/reports/tank-usage/tank-usage.component';
import { IRSProductCodeListComponent } from './compliance/compliance-lookup/IRSProductCode/irsproduct-code-list/irsproduct-code-list.component';
import { JDEProductListComponent } from './compliance/compliance-lookup/JDEProduct/jdeproduct-list/jdeproduct-list.component';
import { TerminalListComponent } from './compliance/compliance-lookup/Terminal/terminal-list/terminal-list.component';
import { VesselListComponent } from './compliance/compliance-lookup/Vessel/vessel-list/vessel-list.component';
import { BookStockAuditReportComponent } from './compliance/compliance-reporting/book-stock-audit-report/book-stock-audit-report.component';
import { EomReconciliationComponent } from './compliance/compliance-reporting/eom-reconciliation/eom-reconciliation.component';
import { ErrorReportComponent } from './compliance/compliance-reporting/error-report/error-report.component';
import { IrsSummaryReportComponent } from './compliance/compliance-reporting/irs-summary-report/irs-summary-report.component';
import { IrsTransactionsReportComponent } from './compliance/compliance-reporting/irs-transactions-report/irs-transactions-report.component';
import { ProductBalanceReportComponent } from './compliance/compliance-reporting/product-balance-report/product-balance-report.component';
import { ServiceAuditReportComponent } from './compliance/compliance-reporting/service-audit-report/service-audit-report.component';
import { TankBalanceReportComponent } from './compliance/compliance-reporting/tank-balance-report/tank-balance-report.component';
import { KepMeterComponent } from './components/kep-meter/kep-meter.component';
import { MeterGroupComponent } from './components/meter-group/meter-group.component';
import { MeterGroupDetailComponent } from './components/meter-group/meter-group-detail/meter-group-detail.component';
import { MasterMeterListComponent } from './components/meters/master-meter-list/master-meter-list.component';
import { MeterProductComponent } from './components/meters/meter-product/meter-product.component';
import { MasterTankListComponent } from './components/tanks/master-tank-list/master-tank-list.component';
import { TankProductComponent } from './components/tanks/tank-product/tank-product.component';
import { UserComponent } from './components/users/user/user.component';
import { WalkingListComponent } from './components/walkinglist/walking-list/walking-list.component';
import { CustomerComponent } from './components/customer/customer.component';
import { WorkRequestComponent } from './components/workrequests/work-request/work-request.component';
import { RoleGuardService } from './services/role-guard.service';
import { KepUsageReportComponent } from './components/reports/kep-usage-report/kep-usage-report.component';
import { ConsentDecreeTransferComponent } from './modules/environmental/components/consent-decree-transfer/consent-decree-transfer.component';
import { MethanolAdditionComponent } from './modules/environmental/components/methanol-addition/methanol-addition.component';
import { AirEmissionsReportComponent } from './compliance/compliance-reporting/air-emissions-report/air-emissions-report.component';
import { SubstationListComponent } from './components/substation-list/substation-list.component';
import { ErrorDetailReportComponent } from './compliance/compliance-reporting/error-detail-report/error-detail-report.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin', 'Reviewer']
        }
      },
      {
        path: 'customers',
        component: CustomerComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'master-meter-list',
        component: MasterMeterListComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'metergroups',
        component: MeterGroupComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'meterproduct',
        component: MeterProductComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'tankproduct',
        component: TankProductComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'metergroup-detail/:id',
        component: MeterGroupDetailComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'metergroup-detail',
        component: MeterGroupDetailComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'master-tank-list',
        component: MasterTankListComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'workrequests',
        component: WorkRequestComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'callback',
        component: CallbackComponent
      },
      {
        path: 'users',
        component: UserComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: 'walkinglists',
        component: WalkingListComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'substation-list',
        component: SubstationListComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'meterproductusage',
        component: MeterProductUsageComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      //{
      //  path: 'customerinvoice',
      //  component: CustomerInvoiceComponent,
      //  canActivate: [RoleGuardService],
      //  data: {
      //    roles: ['Admin']
      //  }
      //},
      {
        path: 'tankusage',
        component: TankUsageComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'pepi-upload',
        component: PepiUploadComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'terminals',
        component: TerminalListComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'vessels',
        component: VesselListComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'irsproductcode',
        component: IRSProductCodeListComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'carriers',
        component: CarrierListComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'product',
        component: JDEProductListComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'importers',
        component: ImporterOfRecordListComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'comp-customer',
        component: CustomerComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'tank-balance-report',
        component: TankBalanceReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'eom-balance',
        component: EomReconciliationComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'product-balance-report',
        component: ProductBalanceReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'irs-transactions-report',
        component: IrsTransactionsReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'air-emissions-report',
        component: AirEmissionsReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'irs-summary-report',
        component: IrsSummaryReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'error-report',
        component: ErrorReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'error-detail-report',
        component: ErrorDetailReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'service-audit-report',
        component: ServiceAuditReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'bookstock-audit-report',
        component: BookStockAuditReportComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-us-compliance']
        }
      },
      {
        path: 'kepmeters',
        component: KepMeterComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'kepmeterusage',
        component: KepUsageReportComponent,
        canActivate: [RoleGuardService],
        data: {
          roles: ['Admin']
        }
      },
      {
        path: 'consent-decree-transfer',
        component: ConsentDecreeTransferComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-deerpark-env']
        }
      },
      {
        path: 'methanol-additions',
        component: MethanolAdditionComponent,
        canActivate: [RoleGuardService],
        data: {
          groups: ['vpk-deerpark-env']
        }
      },
    ]
  },
  {
    path: 'environmental',
    loadChildren: './modules/wastewater/wastewater.module#WastewaterModule',
    canActivate: [RoleGuardService],
    data: {
      groups: ['vpk-deerpark-env']
    }
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
