import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { ConfigurationService } from './configuration.service';
import { Auth0User } from '../objects/auth0/auth0-user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth0;

  constructor(public router: Router, public auth0ConfigService: ConfigurationService) {
    this.auth0 = new auth0.WebAuth(auth0ConfigService.auth0Config);
    
  }

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {    
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
          if (err) {
            return console.log(err);
          }
          localStorage.setItem('profile', JSON.stringify(user));
          //this.userProfile = user;
          this.setSession(authResult);
          if (user["https://ums-api.uneelabs.com/groups"].filter(g => (g == "vpk-deerpark-env")).length > 0){
            this.router.navigate(['/environmental']);
          } else {
            this.router.navigate(['/home']);
          }
        });
      } else if (err) {
        this.router.navigate(['/home']);
        console.log(err);
      }
    });
  }

  private setSession(authResult): void {
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('token', authResult.idToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');

    this.router.navigate(['/']);
  }

  public isAuthenticated(): boolean {    
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  public getUserProfile(): Auth0User {
    var profile: string = localStorage.getItem('profile');
    return JSON.parse(profile);
  }
}
