import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { TankUsageDetail } from '../../../objects/tank-usage-detail';
import { Customer } from '../../../objects/customer';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { TankProduct } from '../../../objects/tank-product';
import { SortDescriptor, State, orderBy, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { TankReadingDialogService } from './tank-reading-dialog.service';

@Component({
  selector: 'app-tank-usage',
  templateUrl: './tank-usage.component.html',
  styleUrls: ['./tank-usage.component.css']
})
export class TankUsageComponent implements OnInit {
  protected startDateFilter: Date = new Date();
  protected endDateFilter: Date = new Date();
  protected tankProductID: number = null;
  protected customerID: number = null;
  protected tankID: number = null;

  protected tankUsageItems: TankUsageDetail[] = new Array<TankUsageDetail>();

  protected products: TankProduct[] = new Array<TankProduct>();
  protected customers: Customer[] = new Array<Customer>();

  public gridView: GridDataResult;

  protected editDataItem: TankUsageDetail;
  protected processing: boolean = false;

  protected aggregates: any[] = [
    {
      field: 'minTemperature',
      aggregate: 'min'
    },
    {
      field: 'maxTemperature',
      aggregate: 'max'
    }
  ];

  protected sort: SortDescriptor[] = [{ dir: 'asc', field: 'createdDateUTC' }];
  public hiddenColumns: string[] = ["customerName", "tankNumber"];
  public state: State = {
    sort: this.sort,
    group: [{
      field: "customerName",
      aggregates: this.aggregates
    }, {
      field: "tankNumber",
      aggregates: this.aggregates
    }]
  }

  constructor(
    private http: HttpClient,
    private dialogService: TankReadingDialogService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    //this.startDateFilter.setDate(this.startDateFilter.getDate() -1);
  }

  ngOnInit() {
    this.processing = true;

    this.http.get('/api/product/tankproducts')
      .subscribe((result: TankProduct[]) => {
        this.products.push({
          tankProductID: null,
          productName: "All Products"
        });

        var products: TankProduct[] = result;

        products.forEach(mp => {
          this.products.push(mp);
        });

      });


    this.http.get('/api/customer')
      .subscribe((result: Customer[]) => {
        this.customers.push({
          customerID: null,
          customerName: "All Customers"
        });

        var customers: Customer[] = result;

        customers.forEach(c => {
          this.customers.push(c);
        });

      });

    this.refreshData();

    this.renderer.removeClass(this.el.nativeElement, 'k-button');
  }

  private refreshData(): void {
    this.processing = true;

    this.startDateFilter.setHours(0, 0, 0, 0);
    this.endDateFilter.setHours(24, 0, 0, 0);

    var filter = {
      startDateUTC: this.startDateFilter,
      endDateUTC: this.endDateFilter,
      tankProductID: this.tankProductID,
      tankID: null,
      customerID: this.customerID
    };

    this.http.post('api/reporting/tankusagedetail', filter)
      .subscribe((response : TankUsageDetail[])=> {
        //debugger;
        this.tankUsageItems = [...response];
        this.loadData();
        this.processing = false;
      });
  }

  private refreshReport(): void {
    this.processing = true;
    this.refreshData();
    this.processing = false;
  }

  private loadData(): void {
    this.gridView = process(orderBy(this.tankUsageItems, this.sort), this.state);
  }

  public dataStateChange(state: DataStateChangeEvent): void {

    this.hiddenColumns = state.group.map(g => g.field);

    state.group.map(
      group => group.aggregates = this.aggregates);
    this.state = state;
    this.loadData();
  }

  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  public editHandler({ dataItem }) {
    this.editDataItem = dataItem;

    this.dialogService
      .tankReadingDetail(this.editDataItem, true)
      .subscribe(res => {
        if (res != undefined) {
          this.editDataItem = res;
          this.refreshData();
        }
      });
  }

  public removeHandler({ dataItem }) {
    this.editDataItem = dataItem;

    this.dialogService
      .tankReadingDetail(this.editDataItem, false)
      .subscribe(res => {
        var index = this.tankUsageItems.findIndex(t => t.tankReadingID == dataItem.tankReadingID);
        this.tankUsageItems.splice(index, 1);
        this.refreshData();
      });
  }
}
