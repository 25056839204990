import { Component, OnInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { IRSProductCode } from '../../../../objects/compliance/irsproduct-code';
import { IRSProductCodeDialogService } from '../irsproduct-code-dialog.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-irsproduct-code-list',
  templateUrl: './irsproduct-code-list.component.html',
  styleUrls: ['./irsproduct-code-list.component.css']
})

export class IRSProductCodeListComponent implements OnInit {
  public irsproductcodes: IRSProductCode[];
  public irsproductcode: IRSProductCode;
  public gridIrsProductCodes: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }
  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: IRSProductCodeDialogService) {
    this.allData = this.allData.bind(this);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    this.http.get('/api/compliance/irsproductcode')
      .subscribe((result: IRSProductCode[]) => {
        this.irsproductcodes = result;
        this.refresh();
      });
  }

  showDialogToAdd() {
    this.irsproductcode = new IRSProductCode();
    this.dialogService
      .irsProductCodeDetail(this.irsproductcode)
      .subscribe(res => {
        if (res != undefined) {
          this.irsProductCodeUpdated(res);
        }
      })
  }

  onRowSelect(event) {
    this.irsproductcode = this.cloneIrsProductCode(event);

    this.dialogService
      .irsProductCodeDetail(this.irsproductcode)
      .subscribe(res => {
        this.irsProductCodeUpdated(res);
      });
  }

  irsProductCodeUpdated(irsProductCode: IRSProductCode) {
    this.irsproductcode = irsProductCode;
    var found: boolean = false;

    for (var i = 0; i < this.irsproductcodes.length && !found; i++) {
      var p = this.irsproductcodes[i];

      if (this.irsproductcode.irsProductCodeID == p.irsProductCodeID) {
        this.irsproductcodes[i] = this.irsproductcode;
        found = true;
        break;
      }
    }

    if (!found) {
      this.irsproductcodes.unshift(this.cloneIrsProductCode(this.irsproductcode));
    } else if (!this.irsproductcode.activeFlag) {
      this.irsproductcodes.splice(i, 1);
    }

    this.irsproductcodes = [...this.irsproductcodes];
    this.refresh();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.irsproductcodes, { sort: [{ field: 'productDescription', dir: 'asc' }] }).data
    };

    return result;
  }

  refresh() {
    this.gridIrsProductCodes = process(this.irsproductcodes, this.state);
  }

  cloneIrsProductCode(p: IRSProductCode): IRSProductCode {
    let ipc = new IRSProductCode();

    for (let prop in p) {
      ipc[prop] = p[prop];
    }

    return ipc;
  }
}
