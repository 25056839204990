import { Component, OnInit } from '@angular/core';
import { TankProduct } from '../../../objects/tank-product';
import { Customer } from '../../../objects/customer';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { TankProductDialogService } from '../tank-product-dialog.service';

@Component({
  selector: 'app-tank-product',
  templateUrl: './tank-product.component.html',
  styleUrls: ['./tank-product.component.css']
})

export class TankProductComponent implements OnInit {
  public products: TankProduct[];
  public product: TankProduct;
  private customers: Customer[];


  public gridProducts: GridDataResult;

  public state: State = {
    skip: 0,
    take: 25
  }

  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(private http: HttpClient, private dialogService: TankProductDialogService) {

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    this.http.get('/api/product/tankproducts')
      .subscribe((result: TankProduct[]) => {
        this.products = result;
        this.refresh();
      });

    this.http.get('/api/customer')
      .subscribe((result : Customer[])=> {
        this.customers = result;
      });
  }

  productUpdated(product: TankProduct) {
    this.product = product;
    var found: boolean = false;

    for (var i = 0; i < this.products.length && !found; i++) {
      var p = this.products[i];

      if (this.product.tankProductID == p.tankProductID) {
        this.products[i] = this.product;
        found = true;
        break;
      }
    }

    if (!found) {
      this.products.push(this.cloneProduct(this.product));
    } else if (!this.product.activeFlag) {
      this.products.splice(i, 1);
    }

    this.products = [...this.products];
    this.refresh();
  }

  onRowSelect(event) {
    this.product = this.cloneProduct(event);
    this.dialogService
      .productDetail(this.product, this.customers)
      .subscribe(res => {
        this.productUpdated(res);
      });
  }

  refresh() {
    this.gridProducts = process(this.products, this.state);
  }

  showDialogToAdd() {
    this.product = new TankProduct();
    this.dialogService
      .productDetail(this.product, this.customers)
      .subscribe(res => {
        this.productUpdated(res);
      });
  }

  cloneProduct(p: TankProduct): TankProduct {

    let product = new TankProduct();

    for (let prop in p) {
      product[prop] = p[prop];
    }

    return product;
  }

}

