import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private urlBase = '/api/reporting';
  public canSubmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public parameters: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(new Array());
  public styleReport: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) { }

  getReportPage(page: number, report) {
    return this.http.post(this.urlBase + '/getreportpage?page=' + page, report);
  }

  executeReport(params) {
    return this.http.post(this.urlBase + '/executereport', params);
  }

  async downloadReport(params, nameReport: string) {
    const options = {
      responseType: 'blob' as 'text'
    };
    const exportFilename = nameReport;
    const file = await this.http.post(this.urlBase + '/export',
      {
        params: params
      }, options).pipe(map(result=>{
        saveAs(result, exportFilename);
        return true;     
      })).toPromise();
    return file;
  }

  getReportParameters(params) {
    return this.http.post(this.urlBase + '/reportparameters', params);
  }

  executeReportAction(params) {
    return this.http.post('/api/reporting/executereportaction', params);
  }

  public get canSubmit$() {
    return this.canSubmit.asObservable();
  }

  public get parameters$() {
    return this.parameters.asObservable();
  }

  public get styleReport$() {
    return this.styleReport.asObservable();
  }

  updateParameters(value: Array<any>) {
    this.parameters.next(value);
  }

  updateCanSubmit(value: boolean){
    this.canSubmit.next(value);
  }

  updateStyleReport(value) {
    this.styleReport.next(value);
  }

}
