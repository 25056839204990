import { Component, OnInit } from '@angular/core';
import { SubstationService } from '../substation.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { SubstationDialogService } from './substation-dialog.service';
import { Substation } from '../../objects/substation';


@Component({
  selector: 'app-substation-list',
  templateUrl: './substation-list.component.html',
  styleUrls: ['./substation-list.component.scss']
})
export class SubstationListComponent implements OnInit {

  public substations: Substation[];
  public substation: Substation;

  public state: State = {
    skip: 0,
    take: 25
  };
  public gridSubstations: GridDataResult;
  constructor(private substationService: SubstationService, private dialogService: SubstationDialogService) { }

  ngOnInit() {
    this.substationService.getSubstations()
      .subscribe((result: Substation[]) => {
        this.substations = result;
        this.refresh();
      });
  }

  refresh() {
    this.gridSubstations = process(this.substations, this.state);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  onRowSelect(event) {
    this.substationService.getSubstationDetail(event.substationID)
      .subscribe((result: Substation) => {
        var substation = result;
        this.dialogService
          .substationDetail(
            substation).subscribe(res => {
              this.substationUpdated(res);
            });
      })
  }

  showDialogToAdd() {
    this.substation = new Substation();
    this.dialogService.substationDetail(this.substation)
      .subscribe(res => {
        if (res != undefined) {
          this.substationUpdated(res);
        }
      });
  }

  substationUpdated(substation: Substation) {   
    this.substation = substation;

    var found: boolean = false;

    for (var i = 0; i < this.substations.length && !found; i++) {
      var s = this.substations[i];

      if (this.substation.substationID == s.substationID) {
        this.substations[i] = s;
        found = true;
        break;
      }
    }

    if (!found) {
      this.substations.push(this.cloneSubstation(this.substation));
    }

    this.substations = [...this.substations];
    this.refresh();
  }

  cloneSubstation(s: Substation): Substation {
    return (JSON.parse(JSON.stringify(s)));
  }
}
