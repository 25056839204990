import { Component, OnInit, OnDestroy } from '@angular/core';
import { TankService } from '../../services/tank.service';
import { MeterService } from '../../services/meter.service';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';
import { MeterProductUsage } from '../../../../objects/meter-product-usage';
import { WalkingListStatus } from '../../../../objects/walking-list-status';
import { WatchlistDialogService } from '../../services/watchlist-dialog.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, OnDestroy {
  public tankUsageItems: TankUsageDetail[] = [];
  public realTimeSubscribe;
  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public tankService: TankService,
    public meterService: MeterService,
    public watchlistDialogService: WatchlistDialogService
  ) {

  }

  public meterProducts: MeterProductUsage[];
  public walkingListItems: WalkingListStatus[] = new Array<WalkingListStatus>();

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.tankService.getTanks().subscribe((tanks) => { });
      this.meterService.getMeters().subscribe((meters) => { });
      this.http.get('/api/dashboard/walkingliststatus')
        .subscribe((result: Array<WalkingListStatus>) => {
          this.walkingListItems = result;
        });
      this.tankService.dailyTankUsageDetails().subscribe((usageData) => {
        this.tankUsageItems = usageData;
      });
      this.realTime();
    }
  }

  public realTime() {
    this.realTimeSubscribe = Observable.interval(300000).timeInterval().subscribe(() => {
      // console.log('realTime', new Date());
      this.tankService.getTanks().subscribe((tanks) => { });
      this.meterService.getMeters().subscribe((meters) => { });

      this.http.get('/api/dashboard/walkingliststatus')
        .subscribe((result: Array<WalkingListStatus>) => {
          this.walkingListItems = result;
        });
      this.tankService.dailyTankUsageDetails().subscribe((usageData) => {
        this.tankUsageItems = usageData;
      });
    });
  }

  ngOnDestroy() {
    this.realTimeSubscribe.unsubscribe();
  }

  public addTank() {
    this.watchlistDialogService.openWatchlist().subscribe(data => { });
  }
}

