import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MeterProduct } from '../../objects/meter-product';
import { MeterProductDialogComponent } from './meter-product-dialog/meter-product-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MeterProductDialogService {
  constructor(private dialog: MatDialog) {

  }

  public productDetail(product: MeterProduct) {
    let dialogRef: MatDialogRef<MeterProductDialogComponent>;

    dialogRef = this.dialog.open(MeterProductDialogComponent);
    dialogRef.componentInstance.product = product;

    return dialogRef.afterClosed();
  }
}
