import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'watchlist-tag',
  templateUrl: './watchlist-tag.component.html',
  styleUrls: ['./watchlist-tag.component.css']
})
export class WatchlistTagComponent {
  @Input()
  public label: string = "";

  @Output()
  public onClose = new EventEmitter();
  public selected: boolean = false;

  constructor() {
  }

  close() {
    this.onClose.emit();
  }

  public toggleState() {
    this.selected = !this.selected;
  }

  public getClass() {
    return {
      selected: this.selected
    };
  }
}

