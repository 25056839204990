import { Injectable } from '@angular/core';
import { Auth0Configuration } from '../objects/auth0/auth0-configuration';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private auth0ConfigData: Auth0Configuration;

  constructor(
    private http: Http) { }

  loadConfigurationData(): Promise<Auth0Configuration> {
    return this.http.get(`/api/clientconfiguration`)
      .toPromise()
      .then((response: Response) => {
        this.auth0ConfigData = response.json();
        return this.auth0ConfigData;
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  get auth0Config(): Auth0Configuration {
    return this.auth0ConfigData;
  }
}
