export class JDEProduct {
  constructor(
    public jdeProductID?: number,
    public irsProductCodeID?: number,
    public irsCode?: string,
    public hpProductNumber?: string,
    public productNumber?: string,
    public productDescription?: string,
    public eiaWeeklyCode?: string,
    public eiaMonthlyCode?: string,
    public txTORCode?: string,
    public comment?: string,
    public activeFlag?: boolean
  ) { }
}
