import { Component, OnInit, Input } from '@angular/core';
import { ConsentDecreeTank } from '../../../objects/consent-decree-tank';
import { ConsentDecreeTransfer } from '../../../objects/consent-decree-transfer';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-consent-decree-transfer-dialog',
  templateUrl: './consent-decree-transfer-dialog.component.html',
  styleUrls: ['./consent-decree-transfer-dialog.component.scss']
})
export class ConsentDecreeTransferDialogComponent implements OnInit {
  @Input()
  public tanks: ConsentDecreeTank[];

  @Input()
  public transfer: ConsentDecreeTransfer;

  public transferForm: FormGroup;

  submitting: boolean = false;
  deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<ConsentDecreeTransferDialogComponent> ) { }

  ngOnInit() {

    this.transferForm = this._fb.group({
      consentDecreeTransferid: [this.transfer.consentDecreeTransferID],
      transferDate: [this.transfer.transferDate],
      consentDecreeTankID: [this.transfer.consentDecreeTankID],
      volume: [this.transfer.volume]
    });
    
  }

  saveTransfer() {
    this.submitting = true;

    this.http.post('/api/consentdecreetransfer', this.transferForm.value)
      .subscribe((result: number) => {
        this.transfer = this.transferForm.value;
        this.transfer.consentDecreeTank = this.tanks.filter(t => t.consentDecreeTankID == this.transferForm.value.consentDecreeTankID)[0].tank;
        this.transfer.consentDecreeTransferID = result;

        this.dialogRef.close(this.transfer);
        this.submitting = false;
      },
        err => console.log(err),
        () => {
          console.log('Request Complete');
          this.dialogRef.close(this.transfer);
          this.submitting = false;
        });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }



}
