import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TankUsageDetail } from '../../../../objects/tank-usage-detail';
import { Subscription } from 'rxjs';
import { WatchlistDialogService } from '../../services/watchlist-dialog.service';
import { FavoriteTankService } from '../../services/favorite-tank.service';
import { TankService } from '../../services/tank.service';
import * as moment from 'moment';

@Component({
  selector: 'tank-grid',
  templateUrl: './tank-grid.component.html',
  styleUrls: ['./tank-grid.component.css']
})
export class TankGridComponent implements OnDestroy {
  public _tankUsageItems: TankUsageDetail[] = [];
  public tankUsageById: { [tankId: string]: TankUsageDetail[] } = {};
  public tankIds: string[] = [];
  private favoriteTankSubscription: Subscription;
  private tanksByIdSubscription: Subscription;
  @Input()
  public set tankUsageItems(items: TankUsageDetail[]) {
    this._tankUsageItems = items;
    this.mapTankUsage();
  };

  public get tankUsageItems() {
    return this._tankUsageItems;
  }

  constructor(
    private watchlistDialogService: WatchlistDialogService,
    private favoriteTankService: FavoriteTankService,
    private tankService: TankService,
  ) {
    this.tankIds = this.favoriteTankService.favoriteTanks;
    this.favoriteTankSubscription = this.favoriteTankService
      .favoriteTanksChange
      .subscribe((newFavoriteIds) => {
        this.tankIds = newFavoriteIds;
        this.mapTankUsage();
      });
    this.tanksByIdSubscription = this.tankService
      .tanksById$
      .subscribe(tanksById => {
        this.mapTankUsage();
      });
  }

  public addTank() {
    this.watchlistDialogService.openWatchlist().subscribe(data => { });
  }

  public mapTankUsage() {
    const favoriteIds = this.tankIds;
    const filteredTankUsage = this._tankUsageItems.filter((tank) => {
      return favoriteIds.indexOf(tank.tankID) > -1
    });
    const tankUsageById = filteredTankUsage.reduce((acc, item): any => {
      const tankGroup = acc[item.tankID] || [];
      tankGroup.push(item);
      acc[item.tankID] = tankGroup;
      return acc;
    }, {});

    for (let id in tankUsageById) {
      tankUsageById[id].sort((prev: TankUsageDetail, curr: TankUsageDetail) => {
        let result = 0;
        if (moment(prev.createdDateUTC).isBefore(curr.createdDateUTC)) {
          result = 1;
        }
        if (moment(prev.createdDateUTC).isAfter(curr.createdDateUTC)) {
          result = -1;
        }
        return result;
      })
    }
    favoriteIds.forEach(tankId => {
      if (!(tankId in tankUsageById)) {
        tankUsageById[tankId] = [this.createDefaultTank(tankId)];
      }
    })
    this.tankUsageById = tankUsageById;
  }

  private createDefaultTank(tankId) {
    const tank = this.tankService.tanksById[tankId] || {};

    const tankUsage = new TankUsageDetail();
    tankUsage.tankID = tank.tankID;
    tankUsage.tankNumber = tank.tankNumber;
    tankUsage.minTemperature = 0;
    tankUsage.maxTemperature = 0;
    tankUsage.temperature = 0;
    tankUsage.level = 0;
    tankUsage.createdDateUTC = moment.utc('2000-01-01').toDate();
    return tankUsage;
  }

  ngOnDestroy(): void {
    this.favoriteTankSubscription.unsubscribe();
    this.tanksByIdSubscription.unsubscribe();
  }
}

