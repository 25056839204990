import { Component, OnInit } from '@angular/core';
import { JDEProduct } from '../../../../objects/compliance/jdeproduct';
import { IRSProductCode } from '../../../../objects/compliance/irsproduct-code';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { JDEProductDialogService } from '../jdeproduct-dialog.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-jdeproduct-list',
  templateUrl: './jdeproduct-list.component.html',
  styleUrls: ['./jdeproduct-list.component.css']
})
export class JDEProductListComponent implements OnInit {
  public jdeProducts: JDEProduct[];
  public jdeProduct: JDEProduct;

  private irsProductCodes: IRSProductCode[];

  public gridJDEProducts: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }
  private pageSizes: boolean = true;
  private previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: JDEProductDialogService) {
    this.allData = this.allData.bind(this);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    this.http.get('/api/compliance/irsproductcode')
      .subscribe((result: IRSProductCode[]) => {
        this.irsProductCodes = result;
      });

    this.http.get('/api/compliance/jdeproduct')
      .subscribe((result: JDEProduct[]) => {
        this.jdeProducts = result;
        this.refresh();
      });
  }

  showDialogToAdd() {
    this.jdeProduct = new JDEProduct();
    this.dialogService
      .jdeProductDetail(this.jdeProduct, this.irsProductCodes)
      .subscribe(res => {
        if (res != undefined) {
          this.jdeProductUpdated(res);
        }
      })
  }

  onRowSelect(event) {
    this.jdeProduct = this.cloneJDEProduct(event);

    this.dialogService
      .jdeProductDetail(this.jdeProduct, this.irsProductCodes)
      .subscribe(res => {
        if (res != undefined) {
          this.jdeProductUpdated(res);
        }
      })
  }

  jdeProductUpdated(jdeProduct: JDEProduct) {
    this.jdeProduct = jdeProduct;
    var found: boolean = false;

    for (var i = 0; i < this.jdeProducts.length && !found; i++) {
      var j = this.jdeProducts[i];

      if (this.jdeProduct.jdeProductID == j.jdeProductID) {
        this.jdeProducts[i] = this.jdeProduct;
        found = true;
        break;
      }
    }

    if (!found) {
      this.jdeProducts.unshift(this.cloneJDEProduct(this.jdeProduct));
    } else if (!this.jdeProduct.activeFlag) {
      this.jdeProducts.splice(i, 1);
    }

    this.jdeProducts = [...this.jdeProducts];
    this.refresh();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.jdeProducts, { sort: [{ field: 'productDescription', dir: 'asc' }] }).data
    };

    return result;
  }

  refresh() {
    this.gridJDEProducts = process(this.jdeProducts, this.state);
  }

  cloneJDEProduct(j: JDEProduct): JDEProduct {
    let jdeProduct = new JDEProduct();

    for (let prop in j) {
      jdeProduct[prop] = j[prop];
    }

    return jdeProduct;
  }

}
