import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MethanolAddition } from '../../../objects/methanol-addition';

@Component({
  selector: 'app-methanol-addition-dialog',
  templateUrl: './methanol-addition-dialog.component.html',
  styleUrls: ['./methanol-addition-dialog.component.scss']
})
export class MethanolAdditionDialogComponent implements OnInit {

  @Input()
  public methanolAddition: MethanolAddition;
  public methanolAdditionForm: FormGroup;

  submitting: boolean = false;
  deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<MethanolAdditionDialogComponent>) { }

  ngOnInit() {
    this.methanolAdditionForm = this._fb.group({
      methanolAdditionID: [this.methanolAddition.methanolAdditionID],
      recordDate: [this.methanolAddition.recordDate],
      facilityID: [this.methanolAddition.facilityID],
      amRate: [this.methanolAddition.amRate],
      pmRate: [this.methanolAddition.pmRate]
    });
  }

  saveMethanolAddition() {
    this.submitting = true;
    this.http.post('/api/methanoladdition', this.methanolAdditionForm.value)
      .subscribe((result: number) => {
        this.methanolAddition = new MethanolAddition(
          result,
          this.methanolAdditionForm.value.recordDate,
          this.methanolAdditionForm.value.amRate,
          this.methanolAdditionForm.value.pmRate,
          this.methanolAdditionForm.value.facilityID);

        this.dialogRef.close(this.methanolAddition);
        this.submitting = false;

      },
        err => console.log(err),
        () => {
          console.log('Request Complete');
          this.dialogRef.close(this.methanolAddition);
          this.submitting = false;
        });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
