import { Component, OnInit } from '@angular/core';
import { TankUsageDetail } from '../../objects/tank-usage-detail';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { MeterProductUsage } from '../../objects/meter-product-usage';
import { WalkingListStatus } from '../../objects/walking-list-status';
import { groupBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  public tankUsage: TankUsageDetail;

  constructor(private http: HttpClient, private auth: AuthService) {
  }


  public meterProducts: MeterProductUsage[];
  public walkingListItems: WalkingListStatus[] = new Array<WalkingListStatus>();

  private series: any;



  ngOnInit() {
    if (this.auth.isAuthenticated()) {

      this.http.get('api/dashboard/meterproductusage')
        .subscribe((result: MeterProductUsage[]) => {
          this.meterProducts = result;

          this.series = groupBy(this.meterProducts, [
            { field: "productName" }
          ]);
        });

      this.http.get('/api/dashboard/walkingliststatus')
        .subscribe((result: WalkingListStatus[]) => {
          this.walkingListItems = result;
        });
    }
  }

}
