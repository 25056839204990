import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MeterReadingDialogComponent } from './meter-reading-dialog/meter-reading-dialog.component';
import { MeterProductUsage } from '../../../objects/meter-product-usage';


@Injectable({
  providedIn: 'root'
})
export class MeterReadingDialogService {

  constructor(private dialog: MatDialog) { }

  public meterReadingDetail(meterReading: MeterProductUsage, isUpdating: boolean) {
    let dialogRef: MatDialogRef<MeterReadingDialogComponent>;


    dialogRef = this.dialog.open(MeterReadingDialogComponent);
    dialogRef.componentInstance.meterReading = meterReading;
    dialogRef.componentInstance.isUpdating = isUpdating;

    return dialogRef.afterClosed();
  }
}
