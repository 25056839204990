export class MeterProduct {
  constructor(
    public meterProductID?: number,
    public productName?: string,
    public facilityID?: string,
    public cost?: number,
    public unitOfMeasure?: string,
    public description?: string,
    public activeFlag?: boolean
  ) { }
}
