import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportService } from '../../../../modules/core-services/services/report.service';

@Component({
  selector: 'app-parameter-container',
  templateUrl: './parameter-container.component.html',
  styleUrls: ['./parameter-container.component.scss']
})
export class ParameterContainerComponent implements OnInit {
  public parameters: any[];
  @Input() public reportName: string;
  @Output() public runReport: EventEmitter<any[]> = new EventEmitter<any[]>();
  public initializing: boolean = true;
  public refreshingParameters: boolean = false;
  public canSubmit: boolean = false;

  constructor(private reportService: ReportService) { }

  ngOnInit() {
    this.refreshParameters();
  }

  public validateParameters() {
    this.canSubmit = true;
    if (this.parameters != undefined && this.parameters != null) {
      for (let i = 0; i < this.parameters.length; i++) {
        let param = this.parameters[i];
        if ((!param.allowNull && param.value == null) || (param.value == '' && !param.allowBlank)) {
          this.canSubmit = false;
          break;
        }
      }
    } else {
      this.canSubmit = false;
    }

    this.initializing = false;
    this.refreshingParameters = false;
    this.reportService.updateCanSubmit(this.canSubmit);
    this.reportService.updateParameters(this.parameters);
  }

  submitReport() {    
    this.runReport.emit(this.parameters);
  }

  checkboxChanged(checkboxValue) {
    let parameterID = checkboxValue.source.id;
    let parameter = this.parameters.find(p => p.id == parameterID);
    parameter.value = checkboxValue.checked;
  }

  parameterSelected(parameterValue) {
    this.refreshingParameters = true;
    let parameterID = parameterValue.source.id;
    let parameter = this.parameters.find(p => p.id == parameterID);
    parameter.value = parameterValue.value;
    if (parameter.hasChildParameters) {
      this.refreshParameters();
    } else {
      this.validateParameters();
    }
  }

  refreshParameters() {
    this.refreshingParameters = true;
    var result = {};
    if (this.parameters == undefined || this.parameters == null) {
      result = null;
    } else {
      for (var i = 0; i < this.parameters.length; i++) {
        if (this.parameters[i].type == 'System.Boolean') {
          result[this.parameters[i].id] = this.parameters[i].value == "true";
        } else {
          result[this.parameters[i].id] = this.parameters[i].value;
        }
      }
    }
    const params = { ReportName: this.reportName, Parameters: JSON.stringify(result) };
    this.reportService.getReportParameters(params)
      .subscribe((result: any[]) => {
        this.parameters = result;
        for (var i = 0; i < this.parameters.length; i++) {
          if (this.parameters[i].type == 'System.Boolean') {
            this.parameters[i].value = this.parameters[i].value == "true";
          } 
        }
        this.refreshingParameters = false;
        this.validateParameters();
      });
  }
}
