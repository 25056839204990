import { Meter } from '../interfaces/Meter';

export class MeterGroup {
  constructor(
    public meterGroupID?: number,
    public meterGroupName?: string,
    public meterCount?: number,
    public meters?: Meter[],
    public activeFlag?: boolean,
    public isSelected?: boolean,
    public createdDateUTC?: Date
  ) {
    meters = new Array<Meter>();
  }
}

