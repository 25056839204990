import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CarrierDialogComponent } from './carrier-dialog/carrier-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CarrierDialogService {

  constructor(private dialog: MatDialog) { }

  public carrierDetail(Carrier: any) {
    let dialogRef: MatDialogRef<CarrierDialogComponent>;

    dialogRef = this.dialog.open(CarrierDialogComponent);
    dialogRef.componentInstance.carrier = Carrier;

    return dialogRef.afterClosed();
  }
}
