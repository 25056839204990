import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { TopBarComponent } from '../../components/top-bar/top-bar.component';
import { MessagingService } from '../../services/messaging.service';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: [
    './main.component.css'
  ]
})
export class MainComponent {
  public navMenuVisible: boolean = true;
  public isDashboard: boolean = false;

  message;


  constructor(
    topbar: TopBarComponent,
    private http: HttpClient,
    public auth: AuthService,
    private router: Router,
    private messagingService: MessagingService) {


  }

  toggleNavMenu() {
    this.navMenuVisible = !this.navMenuVisible;
  }

  getNavWidth() {
    if (this.navMenuVisible) {
      return "235px";
    } else {
      return "0px";
    }
  }

  ngOnInit() {



    var userProfile = this.auth.getUserProfile();

    this.http.post("/api/clientconfiguration/updateuser", userProfile).subscribe(result => {

    });

    var groups = this.auth.getUserProfile()["https://ums-api.uneelabs.com/groups"];
    var roles = this.auth.getUserProfile()["https://ums-api.uneelabs.com/roles"];

    const userId = userProfile.sub;
    this.messagingService.requestPermission(userId);

    const messaging = firebase.messaging();

    
    messaging.getToken().then(currentToken => {      
      var firebaseTopics: any = {
        registrationToken: currentToken,
        topics: [...groups, ...roles]
      };

      this.http.post("/api/clientconfiguration/settopics", firebaseTopics).subscribe(result => {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.isDashboard = event.url === '/home';
          }
        });
      });

    });

    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    
  }
}