import { Injectable } from '@angular/core';
import { Customer } from '../../objects/customer';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TankProduct } from '../../objects/tank-product';
import { TankProductDialogComponent } from './tank-product-dialog/tank-product-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TankProductDialogService {
  customers: Customer[];

  constructor(private dialog: MatDialog) {

  }

  public productDetail(product: TankProduct, customers: Customer[]) {
    let dialogRef: MatDialogRef<TankProductDialogComponent>;

    dialogRef = this.dialog.open(TankProductDialogComponent);
    dialogRef.componentInstance.product = product;
    dialogRef.componentInstance.customers = customers;

    return dialogRef.afterClosed();
  }
}
