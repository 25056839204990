import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ImporterOfRecord } from '../../../../objects/compliance/importer-of-record';


@Component({
  selector: 'app-importer-of-record-dialog',
  templateUrl: './importer-of-record-dialog.component.html',
  styleUrls: ['./importer-of-record-dialog.component.css']
})
export class ImporterOfRecordDialogComponent implements OnInit {

  @Input()
  public importerOfRecord: ImporterOfRecord;

  public importerForm: FormGroup;
  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<ImporterOfRecordDialogComponent>) { }

  ngOnInit() {
    this.importerForm = this._fb.group({
      importerID: [this.importerOfRecord.importerID],
      importerName: [this.importerOfRecord.importerName],
      importerFEIN: [this.importerOfRecord.importerFEIN],
      importerNameControl: [this.importerOfRecord.importerNameControl]
    })
  }

  saveImporter() {
    this.submitting = true;

    this.http.post('/api/compliance/importer/', this.importerForm.value)
      .subscribe(result => {
        this.importerOfRecord = this.importerForm.value;
        this.dialogRef.close(this.importerOfRecord);
        this.submitting = false;
      },
        err => console.log("Error => " + err));
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }

}
