import { Component, OnInit } from '@angular/core';
import { Auth0User } from '../../../objects/auth0/auth0-user';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Auth0Group } from '../../../objects/auth0/auth0-group';
import { Auth0Role } from '../../../objects/auth0/auth0-role';
import { HttpClient } from '@angular/common/http';
import { UserDetailService } from '../user-detail.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  users: Auth0User[];
  gridUsers: GridDataResult;
  public state: State = {
    skip: 0,
    take: 15,
    sort: [{
      'field': 'userName',
      'dir': 'asc'
    }]
  };

  selectedUser: Auth0User;
  currentAction: string = "Edit User";

  private groups: Auth0Group[];
  private roles: Auth0Role[];

  constructor(private http: HttpClient, private dialogService: UserDetailService) {

  }

  newUser() {
    this.selectedUser = new Auth0User();
    this.currentAction = "Add User";

    this.dialogService
      .userDetail(this.selectedUser, this.groups, this.roles)
      .subscribe(res => {
        this.userUpdated(res);
      });
  }

  ngOnInit() {
    this.http.get('/api/usermanagement/teams').subscribe((result : Auth0Group[])=> {
      this.groups = result;
    });

    this.http.get('/api/usermanagement/roles').subscribe((result : Auth0Role[])=> {
      this.roles = result;
    });

    this.http.get('/api/usermanagement').subscribe((result : Auth0User[]) => {
      this.users = result;
      this.refresh();
    });
  }

  onRowSelect(event) {
    this.selectedUser = this.cloneUser(event.dataItem);
    this.currentAction = "Edit User";

    this.http.get('/api/usermanagement/' + event.dataItem.user_id)
      .subscribe((result : Auth0User) => {
        this.selectedUser = result;

        this.dialogService
          .userDetail(result, this.groups, this.roles)
          .subscribe(res => {
            this.userUpdated(res);
          });
      });


  }

  public editMe(userID) {

    this.selectedUser = this.cloneUser(this.users.find(u => u.user_id == userID));
    this.currentAction = "Edit User";

    this.http.get('/api/usermanagement/' + userID)
      .subscribe((result: Auth0User)=> {
        this.selectedUser = result;

        this.dialogService
          .userDetail(result, this.groups, this.roles)
          .subscribe(res => {
            this.userUpdated(res);
          });
      });

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  refresh() {
    this.gridUsers = process(this.users, this.state);
  }

  userUpdated(userData: Auth0User) {

    var userIndex: number;

    if (userData.user_id != null) {
      userIndex = this.users.findIndex(u => u.user_id == userData.user_id);
    } else {
      userIndex = this.users.findIndex(u => u.user_id == this.selectedUser.user_id);
    }

    if (userIndex > -1) {
      if (userData.user_id != null) {
        this.users[userIndex] = userData;
      } else {
        this.users.splice(userIndex, 1);
      }
    } else {
      this.users.push(userData);
    }

    this.users = [...this.users];

    this.refresh();
  }

  cloneUser(u: Auth0User): Auth0User {
    let user = new Auth0User();

    for (let prop in u) {
      user[prop] = u[prop];
    }

    return user;
  }
} 
