import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { WorkRequest } from '../../objects/work-request';
import { WorkRequestType } from '../../objects/work-request-type';
import { WorkRequestDialogComponent } from './work-request-dialog/work-request-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class WorkRequestDialogService {
  constructor(private dialog: MatDialog) {

  }

  public workRequestDetail(
    workRequest: WorkRequest,
    workRequestTypes: WorkRequestType[]) {

    let dialogRef: MatDialogRef<WorkRequestDialogComponent>;

    dialogRef = this.dialog.open(WorkRequestDialogComponent, {
      width: '600px'
    });

    dialogRef.componentInstance.workRequest = workRequest;
    dialogRef.componentInstance.workRequestTypes = workRequestTypes;

    return dialogRef.afterClosed();
  }
}
