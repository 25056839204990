import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataModalService {
  public dataModal: any;
  public isOpenDialog$: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  public setStatus(value){
    this.isOpenDialog$.next(value);
  }

  public getStatus$(): Observable<boolean>  {
    return this.isOpenDialog$.asObservable();
  }

  public getDataModal() {
    return this.dataModal;
  }

  public setDataModal(data) {
    this.dataModal = data;
  }

  public resetDataModal() {
    this.dataModal = undefined;
  }
}
