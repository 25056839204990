import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { VocReportsComponent } from "../../core/components/voc-reports/voc-reports.component";
import { VarianceComponent } from "../../core/components/variance/variance.component";

@Injectable()
export class DialogService {
    constructor(private dialog: MatDialog) {
    }

    public openVocReportDialog() {
        let dialogRef: MatDialogRef<VocReportsComponent>;
        dialogRef = this.dialog.open(VocReportsComponent, {
            width: '400px',
            height: '484px',
            panelClass: 'voc-reports',
            disableClose: true,
            autoFocus: false
        });
        //this.setStatus(true);
        return dialogRef.afterClosed();
    }

    public openVarianceDialog(){
        let dialogRef: MatDialogRef<VarianceComponent>;
        dialogRef = this.dialog.open(VarianceComponent, {
            panelClass: 'variance',
            disableClose: true
        });
        return dialogRef.afterClosed();
    }    

}
