import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { Tank } from '../../../objects/Tank';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { TankUsageDetail } from '../../../objects/tank-usage-detail';

interface TankMap { [s: string]: Tank; }

const Status = {
  ok: 'IN RANGE',
  warning: 'CAUTION',
  danger: 'OUT OF RANGE'
};

@Injectable({
  providedIn: 'root'
})
export class TankService {
  private devMode = false;
  public tanksById: TankMap = {};
  public tanksById$: BehaviorSubject<TankMap> = new BehaviorSubject<TankMap>({});
  public dailyTankUsage: TankUsageDetail[];
  constructor(private http: HttpClient) {
  }

  public dailyTankUsageDetails(): Observable<TankUsageDetail[]> {
    let startDateFilter: string = moment().startOf('day').add(-1, 'month').toISOString();
        
    const filter = {
      "startDateUTC": startDateFilter,// use startDateFilter in production   
      "tankProductID": null,
      "tankID": null,
      "customerID": null,
      "displayRecent": true
    };

    return new Observable((observer) => {
      let extraData = []
      //if (this.devMode) {
      //  extraData = tankusageDevData;
      //}
      this.dailyTankUsage = this.dailyTankUsage || [...extraData];
      observer.next(this.dailyTankUsage);
      this.requestTankUsageDetails(filter).subscribe(tankUsage => {
        const usage = [...tankUsage, ...extraData];
        this.dailyTankUsage = usage;
        observer.next(usage);
      })
    });
  }

  private requestTankUsageDetails(filter): Observable<TankUsageDetail[]> {
    return this.http.post('api/reporting/tankusagedetail', filter)
      .map((response: TankUsageDetail[]) => {
        const tankUsage = [...response];
        return tankUsage || [];
      });
  }

  public getTanks(): Observable<Tank[]> {
    //const extraData = this.devMode ? devtanks : [];
    return this.http.get('api/tank')
      .map(response => {
        //const data = [...(<Tank[]>response.json()), ...extraData];
        const data = [...(<Tank[]>response)];
        data.map(tank => {
          this.tanksById[tank.tankID] = tank;
        });
        this.tanksById$.next(this.tanksById);
        return data;
      });
  }

  public getTankById(id: string): Observable<Tank> {
    return new Observable((observer) => {
      if (this.tanksById[id]) {
        observer.next(this.tanksById[id]);
      }
      this.http
        .get(`api/tank/${id}`)
        .map((response: Tank) => {
          const tank = response;
          if (tank) {
            this.tanksById[tank.tankID] = tank;
            this.tanksById$.next(this.tanksById);
            observer.next(tank);
          }
        });
    });
  }

  public humanReadableStatus(tankUsage: TankUsageDetail): string {
    const temperatureDanger = this.isTankTemperatureStatusDanger(tankUsage);
    const temperatureWarning = this.isTankTemperatureStatusWarning(tankUsage);
    let status = Status.ok;
    if (temperatureWarning) {
      status = Status.warning;
    }
    if (temperatureDanger) {
      status = Status.danger;
    }
    return status;
  }

  public isTankTemperatureStatusDanger(tankUsage: TankUsageDetail): boolean {
    if (!tankUsage) return false;
    return this.isDangerRange(tankUsage.minTemperature, tankUsage.maxTemperature, tankUsage.temperature);
  }

  public isTankLevelStatusDanger(tankUsage: TankUsageDetail): boolean {
    if (!tankUsage) return false;
    const tank = this.tanksById[tankUsage.tankID] || {};
    return this.isDangerRange(tank.minLevel, 10000, tankUsage.level); // We do not want to alert on Top Off level as it measures from top of tank to start of product
  }

  public isTankTemperatureStatusWarning(tankUsage: TankUsageDetail): boolean {
    if (!tankUsage) return false;
    return this.isWarningRange(tankUsage.minTemperature, tankUsage.maxTemperature, tankUsage.temperature);
  }

  public isTankLevelStatusWarning(tankUsage: TankUsageDetail): boolean {
    if (!tankUsage) return false;
    const tank = this.tanksById[tankUsage.tankID] || {};
    return this.isWarningRange(tank.minLevel, tank.maxLevel, tankUsage.level);
  }

  isDangerRange(min = 0, max = 0, value = 0): boolean {
    if (min === max) return false;
    const tooLow = value <= min;
    const tooHigh = value >= max;
    return tooLow || tooHigh;
  }

  isWarningRange(min = 0, max = 0, value = 0): boolean {
    if (min === max) return false;
    const range = max - min;
    const minTemperatureWarning = min + range * 0.20;
    const maxTemperatureWarning = min + range * 0.8;
    const tooLow = value <= minTemperatureWarning && value > min;
    const tooHigh = value >= maxTemperatureWarning && value < max;
    return tooLow || tooHigh;
  }

}
