import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '../../../objects/customer';
import { TankProduct } from '../../../objects/tank-product';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-tank-product-dialog',
  templateUrl: './tank-product-dialog.component.html',
  styleUrls: ['./tank-product-dialog.component.css']
})
export class TankProductDialogComponent implements OnInit {
  @Input()
  public product: TankProduct;

  @Input()
  public customers: Customer[];

  public productForm: FormGroup;

  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<TankProductDialogComponent>) {

  }

  ngOnInit() {
    this.productForm = this._fb.group({
      tankProductID: [this.product.tankProductID],
      productName: [this.product.productName],
      facilityID: [this.product.facilityID],
      description: [this.product.description],
      customerID: [this.product.customerID],
      customerName: [this.product.customerName],
      activeFlag: [this.product.activeFlag]
    })
  }

  saveProduct() {
    this.submitting = true;
    this.product.activeFlag = true;
    this.productForm.value.activeFlag = true;

    this.http.post('/api/product/tankproduct', this.productForm.value)
      .subscribe((result : number)=> {

        this.product = this.productForm.value;
        this.product.tankProductID = result;

        if (this.product.customerID != null) {
          this.product.customerName = this.customers.filter(c => c.customerID == this.product.customerID)[0].customerName;
        } else {
          this.product.customerName = "";
        }


        this.dialogRef.close(this.product);
        this.submitting = false;
      },
        err => console.log("ERROR => " + err));
  }

  deleteProduct() {
    this.deleting = true;

    this.http.delete('/api/product/tankproduct/' + this.product.tankProductID)
      .subscribe(result => {
        this.product.activeFlag = false;

        this.dialogRef.close(this.product);
        this.submitting = false;
        this.deleting = false;
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
