import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Tank } from '../../objects/tank';
import { TankProduct } from '../../objects/tank-product';
import { Customer } from '../../objects/customer';
import { umsMeter } from '../../objects/ums-meter';
import { KepMeter } from '../../objects/kep-meter';
import { Observable } from 'rxjs';
import { TankDialogComponent } from './tank-dialog/tank-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TankDialogService {

  constructor(private dialog: MatDialog) { }

  public tankDetail(
    tank: Tank,
    products: TankProduct[],
    customers: Customer[],
    meters: umsMeter[],
    kepMeters: KepMeter[]): Observable<Tank> {
    let dialogRef: MatDialogRef<TankDialogComponent>;

    dialogRef = this.dialog.open(TankDialogComponent);
    // set tank stuff here
    dialogRef.componentInstance.tank = tank;
    dialogRef.componentInstance.products = products;
    dialogRef.componentInstance.customers = customers;
    dialogRef.componentInstance.meters = meters;
    dialogRef.componentInstance.kepMeters = kepMeters;
    return dialogRef.afterClosed();
  }
}
