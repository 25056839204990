export class Vessel {
  constructor(
    public vesselID?: number,
    public vesselName?: string,
    public ffV_FLAG?: boolean,
    public uscG_VON?: string,
    public imO_VON?: string,
    public activeFlag?: boolean
  ) { }
}
