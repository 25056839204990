import { Component, OnInit, Input } from '@angular/core';
import { Substation } from '../../objects/substation';
import { SubstationRate } from '../../objects/substation-rate';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { SubstationService } from '../substation.service';
import { SubstationRateValidator } from './substation-rate-validator';

@Component({
  selector: 'app-substation-dialog',
  templateUrl: './substation-dialog.component.html',
  styleUrls: ['./substation-dialog.component.scss']
})
export class SubstationDialogComponent implements OnInit {
  @Input()
  public substation: Substation;

  public substationForm: FormGroup

  public substationRates: FormArray;

  submitting: boolean = false;
  deleting: boolean = false;
  constructor(
    public http: HttpClient,
    private fb: FormBuilder,
    private substationService: SubstationService,
    public dialogRef: MatDialogRef<SubstationDialogComponent>) { }

  ngOnInit() {

    this.substationForm =

      this.fb.group({
        substationID: [this.substation.substationID],
        substationName: [this.substation.substationName],
        facilityID: [this.substation.facilityID],
        rates: this.fb.array([])
      }, {
          validator: SubstationRateValidator.validDateRange
        });

    this.initRates();



    //this._fb.group({
    //substationID: [this.substation.substationID],
    //substationName: [this.substation.substationName, Validators.required],
    //facilityID: [this.substation.facilityID],
    //rates: this._fb.array([this.initRates()])    
  }

  get rates() {
    return this.substationForm.get('rates') as FormArray;
  }

  initRates() {



    for (var i: number = 0; i < this.substation.rates.length; i++) {
      let rate: SubstationRate = this.substation.rates[i];

      this.rates.push(this.fb.group({
        rate: [rate.rate, Validators.required],
        startDateUTC: [rate.startDateUTC, Validators.required],
        endDateUTC: rate.endDateUTC,
        substationID: rate.substationID,
        substationRateID: rate.substationRateID
      }));
    }
  }


  createRate(): FormGroup {
    return this.fb.group({
      substationRateID: null,
      substationID: null,
      rate: [null, Validators.required],
      startDateUTC: [null, Validators.required],
      endDateUTC: ''
    });
  }

  addNewRate() {
    this.substationRates = this.substationForm.get('rates') as FormArray;
    this.substationRates.push(this.createRate());
  }

  saveSubstation() {
    console.log(this.substationForm.value);

    this.substationService.saveSubstation(this.substationForm.value).subscribe(result => {
      this.dialogRef.close(result);

    });
  }

}



