import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '../../../objects/customer';
import { Facility } from '../../../objects/facility';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';
import { UserService } from '../../../services/user.service';
import { umsMeter } from '../../../objects/ums-meter';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.css']
})
export class CustomerDialogComponent implements OnInit {
  @Input()
  public customer: Customer;

  @Input()
  public facilities: Facility[];

  public customerForm: FormGroup;

  submitting: boolean = false;
  deleting: boolean = false;

  public isInComplianceGroup: boolean;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<CustomerDialogComponent>, private userService: UserService) {
    this.isInComplianceGroup = this.userService.isInGroup(['vpk-us-compliance']);
  }

  ngOnInit() {


    this.customerForm = this._fb.group({
      customerID: [this.customer.customerID],
      facilityID: [this.customer.facilityID],
      customerName: [this.customer.customerName],
      address: [this.customer.address],
      accountNumber: [this.customer.accountNumber],
      activeFlag: [this.customer.activeFlag],
      customerFEIN: [this.customer.customerFEIN],
      importerOfRecord: [this.customer.importerOfRecord],
      customer637Number: [this.customer.customer637Number]
    });

    if (this.customer.customerID != null) {
      
      this.http.get('/api/customer/' + this.customer.customerID)
        .subscribe((result: Customer) => {
          var c: Customer = result;
          this.customer.customerMeters = c.customerMeters;
          this.customer.customerTanks = c.customerTanks;
        });
    } else {
      this.customer.customerMeters = new Array<umsMeter>();
    }
  }

  saveCustomer() {
    this.submitting = true;

    this.http.post('/api/customer', this.customerForm.value)
      .subscribe((result: number) => {
        this.customer = this.customerForm.value;
        this.customer.customerID = result;//.json();
        this.dialogRef.close(this.customer);
        this.submitting = false;
      },
        err => console.log(err),
        () => {
          console.log('Request Complete');
          this.submitting = false;
        });
  }

  deleteCustomer() {
    this.deleting = true;
    this.http.delete('/api/customer/' + this.customer.customerID)
      .subscribe((result: number) => {
        this.customer = this.customerForm.value;
        this.customer.activeFlag = false;
        this.customer.customerID = result;//.json();
        this.submitting = false;
        this.deleting = false;
        this.dialogRef.close(this.customer);
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
