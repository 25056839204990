 import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-irs-summary-report',
  templateUrl: './irs-summary-report.component.html',
  styleUrls: ['./irs-summary-report.component.css']
})

export class IrsSummaryReportComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
    
  }

}
