import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  private userProfile: Object;

  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.userProfile = this.auth.getUserProfile();
  }

}   
