import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messaging = firebase.messaging()
  currentMessage = new BehaviorSubject(null)

  constructor(
    private afDB: AngularFireDatabase,
    private afAuth: AngularFireAuth) { }
  /**
   * Update token in firebase database
   * 
   * @param userId
   * @param token
   */
  updateToken(userId, token) {
    this.afAuth.authState.pipe(take(1)).subscribe(() => {
      const data = new Object;
      data[userId] = token
      this.afDB.object('fcmTokens/').update(data);
    })
  }

  /**
   * request permissions for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.messaging.requestPermission()
      .then(() => {
        console.log('notification permission granted.');
        return firebase.messaging().getToken()
      })
      .then(token => {
        console.log(token)
        this.updateToken(userId, token)
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
  }

  /**
   * hook method when new notification received
   */
  receiveMessage() {
    this.messaging.onMessage((payload) => {
      console.log("new message received. ", payload);
      this.currentMessage.next(payload)
    });
  }




}
