import { Injectable } from '@angular/core';
import { WalkingList } from '../../objects/walking-list';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MeterGroup } from '../../objects/meter-group';
import { Observable } from 'rxjs';
import { WalkingListDialogComponent } from './walking-list-dialog/walking-list-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class WalkingListDialogService {
  constructor(private dialog: MatDialog) { }

  public walkingListDetail(walkingList: WalkingList, meterGroups: MeterGroup[]): Observable<WalkingList> {
    let dialogRef: MatDialogRef<WalkingListDialogComponent>;

    dialogRef = this.dialog.open(WalkingListDialogComponent);

    dialogRef.componentInstance.walkingList = walkingList;
    dialogRef.componentInstance.meterGroups = meterGroups;

    return dialogRef.afterClosed();
  }
}
