import { Component, OnInit, Input } from '@angular/core';
import { Carrier } from '../../../../objects/compliance/carrier';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-carrier-dialog',
  templateUrl: './carrier-dialog.component.html',
  styleUrls: ['./carrier-dialog.component.css']
})
export class CarrierDialogComponent implements OnInit {
  @Input()
  public carrier: Carrier;

  public carrierForm: FormGroup;
  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<CarrierDialogComponent>) {
  }

  ngOnInit() {
    this.carrierForm = this._fb.group({
      carrierID: [this.carrier.carrierID],
      carrierNumber: [this.carrier.carrierNumber],
      carrierName: [this.carrier.carrierName],
      carrierFEIN: [this.carrier.carrierFEIN],
      //carrierNameControl: [this.carrier.carrierNameControl],
      activeFlag: [this.carrier.activeFlag]
    })
  }

  saveCarrier() {
    this.submitting = true;
    this.carrierForm.value.activeFlag = true;

    this.http.post('/api/compliance/carrier', this.carrierForm.value)
      .subscribe((result: number) => {
        this.carrier = this.carrierForm.value;
        this.carrier.carrierID = result;

        this.dialogRef.close(this.carrier);
        this.submitting = false;
      },
        err => console.log("Error => " + err));
  }

  deleteCarrier() {
    this.deleting = true;

    this.http.delete('/api/compliance/carrier/' + this.carrier.carrierID)
      .subscribe(result => {
        this.carrier.activeFlag = false;
        this.dialogRef.close(this.carrier);
        this.submitting = false;
        this.deleting = false;
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
