import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Variance } from '../../../objects/variance';

@Injectable({
  providedIn: 'root'
})
export class VarianceService {
  public baseUrl: string = '/api/effluent/variances/';
  public variances: Array<any>;
  constructor(private httpClient: HttpClient) { }
  
  getVariances(params){
    return this.httpClient.get(this.baseUrl, {params: params}).pipe(map(result => {
      return <Variance[]> result;
    }));
  }
}
