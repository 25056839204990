import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConsentDecreeTransferDialogService } from './consent-decree-transfer-dialog.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { ConsentDecreeTransfer } from '../../objects/consent-decree-transfer';
import { ConsentDecreeTank } from '../../objects/consent-decree-tank';


@Component({
  selector: 'app-consent-decree-transfer',
  templateUrl: './consent-decree-transfer.component.html',
  styleUrls: ['./consent-decree-transfer.component.scss']
})
export class ConsentDecreeTransferComponent implements OnInit {
  @Input()
  public facilityID: string;

  public consentDecreeTanks: ConsentDecreeTank[];

  public consentDecreeTransfers: ConsentDecreeTransfer[];
  public consentDecreeTransfer: ConsentDecreeTransfer;

  public gridTankTransfers: GridDataResult;
  public state: State = {
    skip: 0,
    take: 25
  }

  public pageSizes: boolean = true;
  public previousNext: boolean = true;

  constructor(public http: HttpClient, private dialogService: ConsentDecreeTransferDialogService) { }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  ngOnInit() {
    if (this.facilityID == null || this.facilityID == "") {
      //TODO: ensure that the facilityID is passed in to the component. This is hard coded currently for the dev ID of deer-park
      this.facilityID = "3A0CF74A-5522-49C6-9977-28DE248FB8BB";
    }

    this.http.get('/api/consentdecreetransfer?facilityID=' + this.facilityID + '&startDate=1/1/2001&endDate=1/1/2019')
      .subscribe((result: ConsentDecreeTransfer[]) => {
        this.consentDecreeTransfers = result;
        this.refresh();
      });

    this.http.get('/api/consentdecreetank/' + this.facilityID)
      .subscribe((result: ConsentDecreeTank[]) => {
        this.consentDecreeTanks = result;
      });
  }

  onRowSelect(event) {
    this.consentDecreeTransfer = this.cloneConsentDecreeTransfer(event);
    this.dialogService.consentDecreeTransfer(this.consentDecreeTransfer, this.consentDecreeTanks)
      .subscribe(res => {
        if (res != undefined) {
          this.consentDecreeTransferUpdated(res);
        }
      })
  }

  showDialogToAdd() {
    var transfer: ConsentDecreeTransfer = new ConsentDecreeTransfer();
    this.dialogService
      .consentDecreeTransfer(transfer, this.consentDecreeTanks)
      .subscribe(res => {
        this.consentDecreeTransferUpdated(res);
      });
  }

  consentDecreeTransferUpdated(consentDecreeTransfer: ConsentDecreeTransfer) {
    this.consentDecreeTransfer = consentDecreeTransfer;
    var found: boolean = false;

    for (var i = 0; i < this.consentDecreeTransfers.length && !found; i++) {
      var c = this.consentDecreeTransfers[i];

      if (c.consentDecreeTransferID == this.consentDecreeTransfer.consentDecreeTransferID) {
        this.consentDecreeTransfers[i] = this.consentDecreeTransfer;
        found = true;
      }
    }

    if (!found) {
      this.consentDecreeTransfers.push(this.cloneConsentDecreeTransfer(consentDecreeTransfer));
    }

    this.consentDecreeTransfers = [...this.consentDecreeTransfers];
    this.refresh();
  }

  refresh() {
    this.gridTankTransfers = process(this.consentDecreeTransfers, this.state);
  }

  cloneConsentDecreeTransfer(c: ConsentDecreeTransfer) {
    let cdt = new ConsentDecreeTransfer();

    for (let prop in c) {
      cdt[prop] = c[prop];
    }

    return cdt;
  }

}
