import { Component, OnInit, Input } from '@angular/core';
import { IRSProductCode } from '../../../../objects/compliance/irsproduct-code';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-irsproduct-code-dialog',
  templateUrl: './irsproduct-code-dialog.component.html',
  styleUrls: ['./irsproduct-code-dialog.component.css']
})

export class IRSProductCodeDialogComponent implements OnInit {
  @Input()
  public irsproductcode: IRSProductCode;

  public productForm: FormGroup;
  public submitting: boolean = false;
  public deleting: boolean = false;

  constructor(private http: HttpClient, private _fb: FormBuilder, public dialogRef: MatDialogRef<IRSProductCodeDialogComponent>) {

  }

  ngOnInit() {
    this.productForm = this._fb.group({
      irsProductCodeID: [this.irsproductcode.irsProductCodeID],
      irsCode: [this.irsproductcode.irsCode],
      productDescription: [this.irsproductcode.productDescription],
      activeFlag: [this.irsproductcode.activeFlag]
    })
  }

  saveIRSProductCode() {
    this.submitting = true;
    this.productForm.value.activeFlag = true;

    this.http.post('/api/compliance/irsproductcode/', this.productForm.value)
      .subscribe((result: number) => {
        this.irsproductcode = this.productForm.value;
        this.irsproductcode.irsProductCodeID = result;

        this.dialogRef.close(this.irsproductcode);
        this.submitting = false;
      },
        err => console.log("Error => " + err));
  }

  deleteIRSProductCode() {
    this.deleting = true;

    this.http.delete('/api/compliance/irsproductcode/' + this.irsproductcode.irsProductCodeID)
      .subscribe(result => {
        this.irsproductcode.activeFlag = false;
        this.dialogRef.close(this.irsproductcode);
        this.submitting = false;
        this.deleting = false;
      });
  }

  closeModal() {
    this.submitting = false;
    this.dialogRef.close();
  }
}
